import { render, staticRenderFns } from "./StepPertussisPregnancy1.vue?vue&type=template&id=d4019104&"
import script from "./StepPertussisPregnancy1.vue?vue&type=script&lang=js&"
export * from "./StepPertussisPregnancy1.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports