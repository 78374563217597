<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <v-row>
          <v-col cols="12" lg="6">
            <h3 class="mb-8 home-header-3">
              Choose an arrival time
            </h3>
            <v-radio-group
              v-model="selectedSlot"
              v-on:change="onRadioGroupClicked"
              class="mb-4"
            >
              <v-radio
                v-for="slot in slotsWithinHour"
                :key="slot.slot_start"
                :label="slot.slot_start"
                :value="slot"
                :disabled="schedulingAppointment"
                v-on:keyup.enter="
                  selectedSlot = slot;
                  onRadioGroupClicked();
                "
                color="#006078"
                class="ml-4 px-8 py-4 black--text"
                :ripple="false"
              >
                <template slot="label">
                  <span class="mr-2">{{ getSlotTime(slot) }}</span>
                  <span class="notranslate" v-if="$vuetify.breakpoint.lgAndUp">{{
                    getSlotAM_PM(slot)
                  }}</span>
                </template></v-radio
              >
            </v-radio-group>
            <v-alert v-if="showError" color="#CD1041" class="mb-14">
              <div class="px-12 py-10 white--text" v-html="errorMessage"></div>
            </v-alert>
            <v-btn
              @click="onAfterContinueClicked"
              class="v-btn--primary"
              :loading="schedulingAppointment"
              :disabled="!getSlot"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<style scoped></style>

<script>
import auth4Patients from '@/helpers/auth4Patients';
import config from '@/config';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import moment from 'moment/moment';
import BackButton from '@/components/BackButton';
import { getSesKey } from '@/helpers/session.ts';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'BookingSlots',
  data: () => ({
    selectedSlot: null,
    showError: false,
    errorMessage: '',
    schedulingAppointment: false
  }),
  computed: {
    ...mapGetters([
      'getBookingApiURLs',
      'getAvailability',
      'getAvailabilitySlot',
      'getSlot',
      'getInvalidSlots',
      'getPatient',
      'getFlowName'
    ]),
    accessToken4Patients() {
      if (!auth4Patients.verifySavedToken()) {
        return false;
      }
      return localStorage.getItem(config.patientAuthTokenName);
    },
    getScheduleAppointmentUrl() {
      return (
        this.getBookingApiURLs.scheduleAppointmentUrl +
        '&clinicScanRoomId=' +
        this.getSlot.clinic_scan_room_id +
        '&slotDate=' +
        this.getSlot.availability_date +
        '&slotTime=' +
        this.getSlot.slot_start +
        '&slotDuration=' +
        this.getSlot.procedure_interval_generic
      );
    },
    slotsWithinHour() {
      const availabilitySlotDateKey = this.getAvailabilitySlot.date.format(
        'YYYY-MM-DD'
      );
      const slotsPerDay = this.getAvailability[availabilitySlotDateKey];
      const slotsWithinHour = Object.values(slotsPerDay).filter((slot) => {
        //check if slot is not in the invalid slots
        if (
          this.getInvalidSlots.filter(
            (s) =>
              s.slot_start === slot.slot_start &&
              s.availability_date === slot.availability_date
          ).length
        ) {
          return false;
        }

        //check if slot is within the selected hour
        const slotHour = moment(slot.slot_start, 'hh:mm:ss').format('H');
        const availabilitySlotHourString = this.getAvailabilitySlot.hour;
        if (Number(slotHour) === Number(availabilitySlotHourString)) {
          return true;
        } else {
          return false;
        }
      });
      return slotsWithinHour;
    }
  },
  mounted: function() {
    if (this.getSlot) {
      const ifSlotOnTheList = this.slotsWithinHour.filter(
        (s) =>
          s.slot_start === this.getSlot.slot_start &&
          s.availability_date === this.getSlot.availability_date
      ).length;
      if (ifSlotOnTheList) {
        this.selectedSlot = this.getSlot;
      }
    }
  },
  methods: {
    getSlotTime(slot) {
      return moment(slot.slot_start, 'HH:mm:ss')
        .format('HH:mm')
        .toUpperCase();
    },
    getSlotAM_PM(slot) {
      return moment(slot.slot_start, 'HH:mm:ss')
        .format('a')
        .toUpperCase();
    },
    onRadioGroupClicked() {
      this.$store.commit('setSlot', this.selectedSlot);
      this.scheduleAppointment();
    },
    onAfterContinueClicked() {
      this.$gtag.event(`Slot within hour selected` + `${this.getFlowName}`, {})
      if (this.accessToken4Patients && this.getPatient.patientDetails) {
        this.$router.push('/booking/patient-summary').catch(() => {});
      }
      else {
        this.$router.push('/booking/patient-details-1').catch(() => {});
      }
    },
    scheduleAppointment() {
      this.schedulingAppointment = true;
      this.showError = false;
      this.errorMessage = '';
      fetch(this.getScheduleAppointmentUrl, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          'Key': getSesKey()
        },
        redirect: 'follow',
        body: ''
      })
        .then((response) => response.json())
        .then((response) => {
          this.schedulingAppointment = false;
          if (response.status === 'S' && response.response.status) {
            this.onScheduleSuccess();
          } else {
            this.onScheduleError();
          }
        })
        .catch((e) => {
          this.schedulingAppointment = false;
          this.onScheduleError();
        });
    },
    onScheduleSuccess() {
      this.slotScheduled = true;
    },
    onScheduleError() {
      const invalidSlots = this.getInvalidSlots;
      invalidSlots.push(this.selectedSlot);
      this.$store.commit('setInvalidSlots', invalidSlots);
      this.$store.commit('setSlot', null);

      this.selectedSlot = null;
      this.showError = true;
      this.errorMessage = 'We are sorry, but the selected slot is no longer available. The list of available slots will now refresh. Please select an alternative time for your appointment.';
    }
  }
});
</script>
