export default [
  {
    label: 'Admin & Clerical',
    value: 'Admin & Clerical',
    onlyForFrontline: true
  },
  {
    label: 'Medical & Dental',
    value: 'Medical & Dental',
    onlyForFrontline: true
  },
  {
    label: 'Nursing & Midwifery',
    value: 'Nursing & Midwifery',
    onlyForFrontline: true
  },
  {
    label: 'Professional & Tech',
    value: 'Professional & Tech',
    onlyForFrontline: true
  },
  {
    label: 'Social Services',
    value: 'Social Services',
    onlyForFrontline: true
  },
  {
    label: 'Support Service & User Experience',
    value: 'Support Service & User Experience',
    onlyForFrontline: true
  },
  {
    label: 'Care Home Staff',
    value: 'Care Home Staff',
    onlyForFrontline: true
  },
  {
    label: 'Domicilary Care',
    value: 'Domicilary Care',
    onlyForFrontline: true
  },
  {
    label: 'Admin & Clerical',
    value: 'Admin & Clerical',
    onlyForFrontline: false
  },
  {
    label: 'Estates',
    value: 'Estates',
    onlyForFrontline: false
  },
  {
    label: 'Professional & Tech',
    value: 'Professional & Tech',
    onlyForFrontline: false
  }
];
