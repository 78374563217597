<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button
          v-if="!standalone"
          @click.native="$router.go(-1)"
        ></back-button>
        <h3 class="mb-8 home-header-3">
          Which vaccination location<br />
          would you like to visit?
        </h3>
        <v-card :loading="clinicsLoading" class="mx-auto">
          <template slot="progress">
            <v-progress-linear
              color="#006078"
              indeterminate
            ></v-progress-linear>
          </template>
        </v-card>
        <v-container v-if="clinics && clinics.length" class="mb-8 px-0">
          <v-row>
            <v-col cols="12" lg="4">
              <v-card class="mx-auto" ref="filtersColumn">
                <v-card-title class="px-5">
                  <span style="color: #006078;">Where are you today?</span>
                </v-card-title>
                <v-card-text>
                  <div class="px-1 py-3">
                    <label
                      class="d-inline-flex mb-2 home-input-label font-weight-bold"
                      >Postcode or town</label
                    >
                    <v-row>
                      <v-col cols="12" lg="7">
                        <div style="position: relative;">
                          <vue-google-autocomplete
                            v-if="googleMapsApiKeyLoaded"
                            id="filter-google-autocomplete"
                            ref="fga"
                            classname="google-autocomplete"
                            placeholder=""
                            v-on:placechanged="onGoogleAutocompletePlaceChanged"
                            v-on:inputChange="onGoogleAutocompleteInputChanged"
                            country="uk"
                            types="(regions)"
                          >
                          </vue-google-autocomplete>
                          <v-btn
                            v-if="showIconCurrentLocation"
                            @click="onIconCurrentLocationClicked"
                            color="primary"
                            class="icon__current-location"
                            fab
                            x-small
                            icon
                            outlined
                          >
                            <v-icon>mdi-map-marker-radius</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="showIconClearLocation"
                            @click="onIconClearLocationClicked"
                            color="primary"
                            class="icon__clear-location"
                            fab
                            x-small
                            icon
                            outlined
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="5">
                        <v-select
                          v-model="distance"
                          :items="distances"
                          item-text="label"
                          item-value="value"
                          dense
                          solo
                          hide-details
                        >
                          <template #item="{item}">
                            <span class="notranslate">{{ item.label }}</span>
                          </template></v-select
                        >
                      </v-col>
                    </v-row>
                  </div>
                  <div class="px-1 py-3">
                    <label
                      for="filter-clinic-names"
                      class="d-inline-flex mb-2 home-input-label font-weight-bold"
                      >Find location by name</label
                    >
                    <v-autocomplete
                      v-model="filteredClinicNames"
                      :items="clinicNames"
                      id="filter-clinic-names"
                      multiple
                      outlined
                      dense
                      chips
                      small-chips
                      hide-details
                    >
                      <template v-slot:no-data>
                        <p class="ma-0 py-3 px-2">
                          No location found.
                        </p>
                      </template>
                      <template v-slot:selection="data">
                        <v-chip
                          v-if="filteredClinicNames.length == 1"
                          v-bind="data.attrs"
                          :input-value="data.item"
                          @click="data.select"
                          @click:close="removeClinicName(data.item)"
                          close
                        >
                          {{ data.item }}
                        </v-chip>
                        <span
                          v-if="
                            filteredClinicNames.length > 1 && data.index === 0
                          "
                        >
                          {{ filteredClinicNames.length }} selected
                        </span>
                      </template></v-autocomplete
                    >
                  </div>
                  <div class="px-1 py-3">
                    <label
                      for="filter-clinic-towns"
                      class="d-inline-flex mb-2 home-input-label font-weight-bold"
                      >Select location type</label
                    >
                    <div
                      v-for="locationType in locationTypes"
                      :key="locationType"
                    >
                      <v-checkbox
                        v-model="filteredLocationTypes"
                        :label="locationType"
                        :value="locationType"
                        :ripple="false"
                        color="#006078"
                        class="my-0 black--text"
                        hide-details
                      ></v-checkbox>
                    </div>
                  </div>
                  <div class="px-1 py-3" v-if="standalone">
                    <label
                      for="filter-clinic-towns"
                      class="d-inline-flex mb-2 home-input-label font-weight-bold"
                      >Select vaccine type</label
                    >
                    <div v-for="vaccineType in vaccineTypes" :key="vaccineType">
                      <v-checkbox
                        v-model="filteredVaccineTypes"
                        :label="vaccineType"
                        :value="vaccineType"
                        :ripple="false"
                        color="#006078"
                        class="my-0 black--text"
                        hide-details
                      ></v-checkbox>
                    </div>
                  </div>
                  <div class="px-1 py-3">
                    <label
                      for="filter-clinic-towns"
                      class="d-inline-flex mb-2 home-input-label font-weight-bold"
                      >Select county</label
                    >
                    <v-autocomplete
                      v-model="filteredClinicRegions"
                      :items="clinicRegions"
                      id="filter-clinic-regions"
                      multiple
                      outlined
                      dense
                      chips
                      small-chips
                      hide-details
                    >
                      <template v-slot:no-data>
                        <p class="ma-0 py-3 px-2">
                          No clinic regions found.
                        </p>
                      </template>
                      <template v-slot:selection="data">
                        <v-chip
                          v-if="filteredClinicRegions.length == 1"
                          v-bind="data.attrs"
                          :input-value="data.item"
                          @click="data.select"
                          @click:close="removeClinicRegion(data.item)"
                          close
                        >
                          {{ data.item }}
                        </v-chip>
                        <span
                          v-if="
                            filteredClinicRegions.length > 1 && data.index === 0
                          "
                        >
                          {{ filteredClinicRegions.length }} selected
                        </span>
                      </template></v-autocomplete
                    >
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="8">
              <v-card class="mx-auto mb-16" ref="clinicsColumn">
                <v-card-title class="px-5">
                  <span style="color: #006078;">Select location</span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      lg="6"
                      :style="{
                        maxHeight: clinicsHeight + 'px',
                        overflow: 'auto'
                      }"
                    >
                      <div
                        v-if="
                          clinicsFiltered &&
                            clinicsFiltered.length &&
                            clinicsHeight
                        "
                      >
                        <v-radio-group
                          v-if="!standalone"
                          v-model="selectedClinic"
                          v-on:change="onRadioGroupClicked"
                          class="ma-0"
                        >
                          <v-radio
                            v-for="clinic in clinicsFiltered"
                            :key="clinic.clinic_id"
                            :value="clinic"
                            v-on:keyup.enter="
                              selectedClinic = clinic;
                              onRadioGroupClicked();
                            "
                            color="#006078"
                            class="ml-2 mb-4 py-2 px-8 black--text"
                            :ripple="false"
                          >
                            <template slot="label">
                              <div class="flex-row">
                                <p class="ma-0 mb-2 font-weight-bold">
                                  {{ clinic.clinic_name }}
                                </p>
                                <p class="ma-0 mb-2 home-text-2">
                                  {{ clinic.clinic_address }}
                                </p>
                                <v-btn
                                  v-if="clinic && !isEmptyOrWhiteSpaces(clinic.popup_info)"
                                  @click.prevent.stop="
                                    showClinicInfoDialog(clinic)
                                  "
                                  class="pa-2 black--text"
                                  x-small
                                  color="primary"
                                >
                                  View details
                                </v-btn>
                              </div>
                            </template></v-radio
                          >
                        </v-radio-group>
                        <div v-else>
                          <div
                            v-for="clinic in clinicsFiltered"
                            :key="clinic.clinic_id"
                          >
                            <v-row align="center" class="mb-2">
                              <v-col lg="8">
                                <p
                                  class="ml-1 mb-2 home-text-2 font-weight-bold"
                                >
                                  {{ clinic.clinic_name }}
                                </p>
                                <p class="ml-1 mb-2 home-text-3">
                                  {{ clinic.clinic_address }}
                                </p>
                              </v-col>
                              <v-col lg="4" class="text-right"
                                ><v-btn
                                  v-if="clinic && !isEmptyOrWhiteSpaces(clinic.popup_info)"
                                  @click="showClinicInfoDialog(clinic)"
                                  class="pa-2 black--text"
                                  x-small
                                  color="primary"
                                >
                                  Details
                                </v-btn>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <p class="py-2 px-4 text-h6">
                          No clinics found. Please change selected filters.
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="6" class="d-none d-lg-flex">
                      <div
                        class="d-flex static-map__container"
                        v-if="clinicsFiltered && clinicsFiltered.length"
                        v-bind:style="{
                          'background-size': 'contain',
                          'background-position': 'center center',
                          'background-image': 'url(' + clinicsMapSrc + ')'
                        }"
                        @click="openGoogleMaps"
                        aria-label="Vaccination clinics map. Click here to open interactive map within the modal window."
                      ></div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <div class="text-right">
                <recall-survey v-if="accessToken4Patients()"></recall-survey>
                <v-btn
                  @click="onAfterContinueClicked"
                  v-if="clinics && !standalone"
                  ref="continue"
                  color="#006078"
                  class="mb-4 mb-lg-0 ml-4 v-btn--primary"
                  :disabled="!selectedClinic"
                >
                  Continue
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <div class="mb-8" v-else>
          <v-row v-if="!clinicsLoading">
            <v-col cols="12" lg="5">
              <h3 class="mt-8 mb-8 home-header-3 font-weight-regular">
                Unfortunately there are no slots available at the moment.
              </h3>
            </v-col>
          </v-row>
        </div>
      </v-container>
      <v-dialog v-model="googleMapsDialog" max-width="800" max-height="600">
        <v-card>
          <v-container id="google-map__container" class="fill-height">
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#006078" text @click="googleMapsDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="clinicInfoDialog" max-width="800" max-height="600">
        <v-card class="pa-4">
          <p v-if="clinicInfoName(clinicInfo)">
            {{ clinicInfoName(clinicInfo) }}
          </p>
          <a
            v-if="clinicInfoLink(clinicInfo)"
            :href="clinicInfoLink(clinicInfo)"
            target="_blank"
            >{{ clinicInfoLink(clinicInfo) }}</a
          >
          <p
            v-if="clinicInfoText(clinicInfo)"
            v-html="clinicInfoText(clinicInfo)"
            class="fill-height"
          ></p>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#006078" text @click="clinicInfoDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<style>
.ps > .ps__rail-x,
.ps > .ps__rail-y {
  opacity: 0.6;
}
</style>

<style scoped>
.google-autocomplete {
  width: 100% !important;
  height: 48px !important;
  padding: 3px 0 !important;
  background: #f5f5f5 !important;
  box-shadow: none !important;
  border: 1px solid #d0d4d4 !important;
  border-radius: 0 !important;
  text-indent: 10px;
}

.google-autocomplete:hover,
.google-autocomplete:focus-visible {
  padding: 3px 0 !important;
  background-color: #f5f5f5 !important;
  box-shadow: 0 1px 5px rgb(0 0 0 / 0.2) !important;
  outline: none;
}

.icon__current-location,
.icon__clear-location {
  position: absolute;
  top: 8px;
  right: 10px;
  z-index: 2;
}

.static-map__container {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-position: center top !important;
}

#google-map__container {
  max-width: 900px;
  height: 600px;
}
</style>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import BackButton from '@/components/BackButton';
import RecallSurvey from '@/components/RecallSurvey';
import config from '@/config';
import auth4Patients from '@/helpers/auth4Patients';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { calculateAge } from '@/helpers/ageCalculation.ts';
import { calculateDistance } from '@/helpers/distanceCalculation.ts';
import { getSesKey } from '@/helpers/session.ts';

const distances = [
  { label: '5 miles', value: 5 },
  { label: '10 miles', value: 10 },
  { label: '15 miles', value: 15 },
  { label: '20 miles', value: 20 },
  { label: '25 miles', value: 25 },
  { label: '30 miles', value: 30 },
  { label: '50+ miles', value: 0 }
];

const locationTypes = ['Trust', 'Pharmacy'];
const vaccineTypes = [
  'Seasonal Flu',
  'COVID Booster',
  'COVID Dose 1',
  'COVID Dose 2',
  'COVID 3rd primary dose'
];

export default Vue.extend({
  components: {
    BackButton,
    RecallSurvey,
    VueGoogleAutocomplete
  },
  props: ['standalone', 'getClinicsUrl', 'clinicsMapUrl'],
  name: 'BookingClinics',
  data: () => ({
    googleMapsApiKeyLoaded: false,
    clinicsLoading: true,
    clinicsHeight: null,
    clinics: null,
    clinicsMapImageName: null,
    filteredClinicNames: [],
    filteredClinicRegions: [],
    filteredLocationTypes: [],
    filteredVaccineTypes: [],
    selectedPlace: null,
    selectedClinic: null,
    distances: distances,
    locationTypes: locationTypes,
    vaccineTypes: vaccineTypes,
    distance: 10,
    showIconCurrentLocation: true,
    showIconClearLocation: false,
    googleMapsDialog: false,
    googleMaps: null,
    googleMapsMarkers: [],
    clinicInfo: null,
    clinicInfoDialog: false
  }),
  mounted: function() {
    this.getClinics();
    this.loadGoogleMapsScript();
  },
  computed: {
    ...mapGetters([
      'getBookingApiURLs',
      'getAge',
      'getQuickSchedule',
      'getPatient',
      'getFlowName'
    ]),
    clinicsMapSrc() {
      if (this.clinicsMapImageName) {
        return (
          'https://storage.googleapis.com/aptvision-cloud-hscni-maps/' +
          this.clinicsMapImageName
        );
      } else {
        return '';
      }
    },
    clinicsFiltered() {
      if (!this.clinics) {
        return null;
      }

      let clinicsFiltered = [...this.clinics];

      if (this.filteredClinicNames.length) {
        clinicsFiltered = clinicsFiltered.filter((c) =>
          this.filteredClinicNames.includes(c.clinic_name)
        );
      }
      if (this.filteredLocationTypes.length) {
        clinicsFiltered = clinicsFiltered.filter((c) =>
          this.filteredLocationTypes.includes(c.clinic_type)
        );
      }
      if (this.filteredVaccineTypes.length) {
        if (this.filteredVaccineTypes.includes('Seasonal Flu')) {
          clinicsFiltered = clinicsFiltered.filter((c) => c.flu === '1');
        }
        if (this.filteredVaccineTypes.includes('COVID Booster')) {
          clinicsFiltered = clinicsFiltered.filter((c) => c.booster === '1');
        }
        if (this.filteredVaccineTypes.includes('COVID Dose 1')) {
          clinicsFiltered = clinicsFiltered.filter((c) => c.dose1 === '1');
        }
        if (this.filteredVaccineTypes.includes('COVID Dose 2')) {
          clinicsFiltered = clinicsFiltered.filter((c) => c.dose2 === '1');
        }
        if (this.filteredVaccineTypes.includes('COVID 3rd primary dose')) {
          clinicsFiltered = clinicsFiltered.filter((c) => c.dose3 === '1');
        }
      }
      if (this.filteredClinicRegions.length) {
        clinicsFiltered = clinicsFiltered.filter((c) =>
          this.filteredClinicRegions.includes(c.clinic_region)
        );
      }
      if (this.selectedPlace) {
        clinicsFiltered = clinicsFiltered.filter((c) => {
          const distance = calculateDistance(
            c.clinic_lat,
            c.clinic_lng,
            this.selectedPlace.latitude,
            this.selectedPlace.longitude,
            'M'
          );
          if (this.distance === 0 || distance < this.distance) {
            return true;
          } else {
            return false;
          }
        });
      }

      this.getClinicsGStaticGMapImageName(clinicsFiltered);

      return clinicsFiltered;
    },
    clinicNames() {
      if (!this.clinics) {
        return null;
      }

      let clinicNames = this.clinics.map((c) => c.clinic_name);

      clinicNames = [...new Set(clinicNames)];
      clinicNames = clinicNames.sort();

      return clinicNames;
    },
    clinicRegions() {
      if (!this.clinics) {
        return null;
      }

      let clinicRegions = this.clinics
        .filter((c) => c.clinic_region)
        .map((c) => c.clinic_region);

      clinicRegions = [...new Set(clinicRegions)];
      clinicRegions = clinicRegions.sort();

      return clinicRegions;
    }
  },
  methods: {
    accessToken4Patients() {
      if (!auth4Patients.verifySavedToken()) {
        return false;
      }
      return localStorage.getItem(config.patientAuthTokenName);
    },
    isEmptyOrWhiteSpaces(str) {
        return str === null || str.match(/^ *$/) !== null;
    },
    clinicInfoName(clinic) {
      if (!clinic) {
        return null;
      }
      return clinic.clinic_name;
    },
    clinicInfoLink(clinic) {
      if (!clinic) {
        return null;
      }
      if (clinic.popup_info && clinic.popup_info.startsWith('http')) {
        const spaceSplitted = clinic.popup_info.split(' ');
        return spaceSplitted[0];
      }
      return null;
    },
    clinicInfoText(clinic) {
      if (!clinic) {
        return null;
      }
      if (clinic.popup_info && clinic.popup_info.startsWith('http')) {
        const spaceSplitted = clinic.popup_info.split(' ');
        return spaceSplitted[1];
      }<recall-survey v-if="accessToken4Patients()"></recall-survey>
      return clinic.popup_info;
    },
    removeClinicName(clinicName) {
      const index = this.filteredClinicNames.indexOf(clinicName);
      if (index >= 0) this.filteredClinicNames.splice(index, 1);
    },
    removeClinicRegion(clinicRegion) {
      const index = this.filteredClinicRegions.indexOf(clinicRegion);
      if (index >= 0) this.filteredClinicRegions.splice(index, 1);
    },
    initGoogleMaps() {
      if (this.googleMaps) {
        return;
      }
      const google = window.google;
      this.googleMaps = new google.maps.Map(
        document.getElementById('google-map__container'),
        {
          center: { lat: 54.6626011, lng: -7.9231074 },
          zoom: 8
        }
      );
    },
    openGoogleMaps() {
      this.googleMapsDialog = true;
      this.$nextTick(() => {
        window.setTimeout(() => {
          this.initGoogleMaps();
          this.clearClinicsMarkers();
          this.addClinicsToGoogleMaps();
        }, 200); //wait until dialog is fully opened
      });
    },
    addClinicsToGoogleMaps() {
      const clinicsArr = this.clinicsFiltered;
      for (let i = 0; i < clinicsArr.length; i++) {
        const google = window.google;
        const clinic = clinicsArr[i];
        const coordinates = this.getClinicLocation(clinic);

        if (coordinates) {
          const marker = new google.maps.Marker({
            position: coordinates.center,
            map: this.googleMaps
          });

          let content = '';
          if (this.clinicInfoName(clinic)) {
            content += `<strong>${this.clinicInfoName(
              clinic
            )}</strong><br><br>`;
          }
          if (this.clinicInfoLink(clinic)) {
            content += `<a href="${this.clinicInfoLink(
              clinic
            )}" target="_blank">${this.clinicInfoLink(clinic)}</a><br>`;
          }
          if (this.clinicInfoText(clinic)) {
            content += this.clinicInfoText(clinic);
          }
          const infoWindow = new google.maps.InfoWindow({
            content: content
          });

          (function(marker, infoWindow) {
            google.maps.event.addListener(marker, 'click', function() {
              infoWindow.open(this.googleMaps, marker);
            });
          })(marker, infoWindow);

          this.googleMapsMarkers.push(marker);
        }
      }
      this.fitClinicsInGoogleMaps();
    },
    clearClinicsMarkers() {
      if (this.googleMapsMarkers) {
        for (let i = 0; i < this.googleMapsMarkers.length; i++) {
          this.googleMapsMarkers[i].setMap(null);
        }
      }
    },
    getClinicLocation(clinic) {
      const latitude = clinic.clinic_lat;
      const longitude = clinic.clinic_lng;
      if (!latitude || !longitude) {
        return null;
      }
      const google = window.google;
      const latLng = new google.maps.LatLng(latitude, longitude);

      return { center: latLng, zoom: 15 };
    },
    fitClinicsInGoogleMaps() {
      const google = window.google;
      const bounds = new google.maps.LatLngBounds();
      for (let i = 0; i < this.googleMapsMarkers.length; i++) {
        bounds.extend(this.googleMapsMarkers[i].getPosition());
      }
      this.googleMaps.fitBounds(bounds);
    },
    loadGoogleMapsScript() {
      this.$loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${config.googleMapsApiKey}&libraries=places`
      ).then(() => {
        this.googleMapsApiKeyLoaded = true;
      });
    },
    showClinicInfoDialog(clinic) {
      this.clinicInfo = clinic;
      this.clinicInfoDialog = true;
    },
    onGoogleAutocompletePlaceChanged(place) {
      this.selectedPlace = place;
    },
    onGoogleAutocompleteInputChanged(input) {
      if (!input.newVal) {
        this.selectedPlace = null;
        this.showIconCurrentLocation = true;
        this.showIconClearLocation = false;
      } else {
        this.showIconCurrentLocation = false;
        this.showIconClearLocation = true;
      }
    },
    onIconCurrentLocationClicked() {
      navigator.geolocation.getCurrentPosition(
        (s) => {
          this.$refs.fga.update('Your current location');
          this.selectedPlace = s.coords;
        },
        (e) => {
          console.log(e);
        }
      );
    },
    onIconClearLocationClicked() {
      this.$refs.fga.clear();
    },
    onRadioGroupClicked() {
      this.$store.commit('setClinic', this.selectedClinic);
      if (!this.standalone) {
        this.$vuetify.goTo(this.$refs['continue']);
      }
    },
    onAfterContinueClicked() {
      this.$gtag.event(`Clinic selected ` + `${this.getFlowName}`, {})
      this.$router.push('/booking/slots').catch(() => {});
    },
    getClinics() {
      const url = this.getBookingApiURLs
        ? this.getBookingApiURLs.getClinicsUrl
        : this.getClinicsUrl;
      const age = this.getAge || (this.getPatient.patientDetails && this.getPatient.patientDetails.dateOfBirth ? calculateAge(this.getPatient.patientDetails.dateOfBirth) : 0);
      const accessCode = localStorage.getItem('accessCode');
      const patientAccessCode = localStorage.getItem('patientAccessCode');
      fetch(`${url}&age=${age}`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          'Scope': this.accessToken4Patients() ? 'PATIENT' : '',
          'Key': getSesKey()
        },
        redirect: 'follow',
        body: JSON.stringify({
          accessCode: accessCode,
          patientAccessCode: patientAccessCode
        })
      })
        .then((response) => response.json())
        .then((response) => {
          this.clinicsLoading = false;
          this.clinics = Object.values(response.response.viewData.clinics);

          this.$nextTick(() => {
            if (this.$refs['filtersColumn']) {
              this.clinicsHeight =
                this.$refs['filtersColumn'].$el.clientHeight - 56;
                //56 so that filters and clinics columns are equal, covers margins and paddings
            }
          });
        })
        .catch((e) => {});
    },
    getClinicsGStaticGMapImageName(clinics) {
      const url = this.getBookingApiURLs
        ? this.getBookingApiURLs.getClinicsUrl
        : this.getClinicsUrl;
      const age = this.getAge || (this.getPatient.patientDetails && this.getPatient.patientDetails.dateOfBirth ? calculateAge(this.getPatient.patientDetails.dateOfBirth) : 0);
      const clinicIdsCSV = clinics.map((c) => c.clinic_id).join(',');
      this.clinicsLoading = true;
      this.clinicsMapImageName = null;
      fetch(`${url}&age=${age}`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          'Scope': this.accessToken4Patients() ? 'PATIENT' : '',
          'Key': getSesKey()
        },
        redirect: 'follow',
        body: JSON.stringify({ clinicIdsCSV })
      })
        .then((response) => response.json())
        .then((response) => {
          this.clinicsLoading = false;
          this.clinicsMapImageName =
            response.response.viewData.clinicsGStaticGMapImageName;
        })
        .catch((e) => {});
    }
  }
});
</script>
