<template>
  <v-container class="mb-6 py-6 header">
    <v-row>
      <v-col>
        <p class="mb-4 text-center font-weight-bold header--year">
          {{ getTitle }}
        </p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col
        class="text-center header__date header__prev"
        @click="prevDate()"
        :disabled="!allowPrevDates"
      >
        <button
          :aria-label="
            'Show available slots starting from: ' + firstDatePrevPage
          "
          :disabled="!allowPrevDates"
        >
          <img
            v-if="!allowPrevDates"
            src="/img/icons/arrow-left-disabled.svg"
          />
          <img v-if="allowPrevDates" src="/img/icons/arrow-left.svg" />
        </button>
      </v-col>
      <v-col v-for="(date, index) in dates" :key="index">
        <div class="ma-2 pa-lg-4 header__date">
          <p class="mb-8 text-center header--day">
            {{ getDayName(date) }}
          </p>
          <p class="text-center header--day-index">
            {{ getDayIndex(date) }}
          </p>
        </div>
      </v-col>
      <v-col class="text-center header__date header__next" @click="nextDate()">
        <button
          :aria-label="
            'Show available slots starting from: ' + firstDateNextPage
          "
        >
          <img src="/img/icons/arrow-right.svg" />
        </button>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.header {
  border-radius: 20px;
  background: #f9f9f9;
}

.header__prev,
.header__next {
  cursor: pointer;
}

.header__prev:not([DISABLED]):hover,
.header__prev:not([DISABLED]):focus,
.header__next:not([DISABLED]):hover,
.header__next:not([DISABLED]):focus {
  opacity: 0.5;
}

.header__prev button:focus-visible,
.header__next button:focus-visible {
  opacity: 0.5;
  outline: none;
}

.header--year,
.header--day {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.1em;
}

.header--day-index {
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
}
</style>

<script>
import Vue from 'vue';
import moment from 'moment/moment';

export default Vue.extend({
  components: {},
  name: 'SlotsHeader',
  props: ['dates'],
  data: () => ({}),
  computed: {
    getTitle() {
      const minDate = this.dates[0];
      const maxDate = this.dates[this.dates.length - 1];
      const minMonth = moment(minDate).format('MMMM');
      const maxMonth = moment(maxDate).format('MMMM');

      if (minMonth === maxMonth) {
        return minMonth + ' ' + moment(maxDate).format('YYYY');
      } else {
        return (
          minMonth + ' - ' + maxMonth + ' ' + moment(maxDate).format('YYYY')
        );
      }
    },
    allowPrevDates() {
      const minDate = moment(this.dates[0]);
      const today = moment();
      if (today.diff(minDate) < 0) {
        return true;
      } else {
        return false;
      }
    },
    firstDatePrevPage() {
      const date = moment(this.dates[0]);
      date.subtract(this.dates.length, 'days');

      return date.format('dddd, DD MMMM YYYY');
    },
    firstDateNextPage() {
      const date = moment(this.dates[this.dates.length - 1]);
      date.add(1, 'days');

      return date.format('dddd, DD MMMM YYYY');
    }
  },
  methods: {
    getDayName(date) {
      return moment(date)
        .format('ddd')
        .toUpperCase();
    },
    getDayIndex(date) {
      return moment(date).format('DD');
    },
    prevDate() {
      if (this.allowPrevDates) {
        this.$emit('prevDate', true);
      }
    },
    nextDate() {
      this.$emit('nextDate', true);
    }
  }
});
</script>
