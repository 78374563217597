export default [
  {
    value: 2,
    text: '80 years of age and over',
    textAt: 'at 31st March 2024',
    minDate: '1900-01-01',
    maxDate: '1944-03-31',
    minDateAsDate: new Date(1900, 0, 1),
    maxDateAsDate: new Date(1944, 2, 31),
    ifDate: true
  },
  {
    value: 3,
    text: '75 - 79 years of age',
    textAt: 'at 31st March 2024',
    minDate: '1944-04-01',
    maxDate: '1949-03-31',
    minDateAsDate: new Date(1944, 3, 1),
    maxDateAsDate: new Date(1949, 2, 31),
    ifDate: true
  },
  {
    value: 4,
    text: '70 - 74 years of age',
    textAt: 'at 31st March 2024',
    minDate: '1949-04-01',
    maxDate: '1954-03-31',
    minDateAsDate: new Date(1949, 3, 1),
    maxDateAsDate: new Date(1954, 2, 31),
    ifDate: true
  },
  {
    value: 6,
    text: '65 - 69 years of age',
    textAt: 'at 31st March 2024',
    minDate: '1954-04-01',
    maxDate: '1959-03-31',
    minDateAsDate: new Date(1954, 3, 1),
    maxDateAsDate: new Date(1959, 2, 31),
    ifDate: true
  },
  {
    value: 9,
    text: '60 - 64 years of age',
    textAt: 'at 31st March 2024',
    minDate: '1959-04-01',
    maxDate: '1964-03-31',
    minDateAsDate: new Date(1959, 3, 1),
    maxDateAsDate: new Date(1964, 2, 31),
    ifDate: true
  },
  {
    value: 10,
    text: '55 - 59 years of age',
    textAt: 'at 31st March 2024',
    minDate: '1964-04-01',
    maxDate: '1969-03-31',
    minDateAsDate: new Date(1964, 3, 1),
    maxDateAsDate: new Date(1969, 2, 31),
    ifDate: true
  },
  {
    value: 11,
    text: '50 - 54 years of age',
    textAt: 'at 31st March 2024',
    minDate: '1969-04-01',
    maxDate: '1974-03-31',
    minDateAsDate: new Date(1969, 3, 1),
    maxDateAsDate: new Date(1974, 2, 31),
    ifDate: true
  },
  {
    value: 12,
    text: '45 - 49 years of age',
    textAt: 'at 31st March 2024',
    minDate: '1974-04-01',
    maxDate: '1979-03-31',
    minDateAsDate: new Date(1974, 3, 1),
    maxDateAsDate: new Date(1979, 2, 31),
    ifDate: true
  },
  {
    value: 13,
    text: '40 - 44 years of age',
    textAt: 'at 31st March 2024',
    minDate: '1979-04-01',
    maxDate: '1984-03-31',
    minDateAsDate: new Date(1979, 3, 1),
    maxDateAsDate: new Date(1984, 2, 31),
    ifDate: true
  },
  {
    value: 14,
    text: '35 - 39 years of age',
    textAt: 'at 30th April 2024',
    minDate: '1984-04-01',
    maxDate: '1989-04-30',
    minDateAsDate: new Date(1984, 3, 1),
    maxDateAsDate: new Date(1989, 3, 30),
    ifDate: true
  },
  {
    value: 15,
    text: '30 - 34 years of age',
    textAt: 'at 30th April 2024',
    minDate: '1989-05-01',
    maxDate: '1994-04-30',
    minDateAsDate: new Date(1989, 4, 1),
    maxDateAsDate: new Date(1994, 3, 30),
    ifDate: true
  },
  {
    value: 16,
    text: '25 - 29 years of age',
    textAt: 'at 31st July 2024',
    minDate: '1994-05-01',
    maxDate: '1999-07-31',
    minDateAsDate: new Date(1994, 4, 1),
    maxDateAsDate: new Date(1999, 6, 31),
    ifDate: true,
  },
  {
    value: 17,
    text: '18 - 24 years of age',
    textAt: 'at 31st October 2024',
    minDate: '1999-08-01',
    maxDate: '2006-10-31',
    minDateAsDate: new Date(1999, 7, 1),
    maxDateAsDate: new Date(2006, 9, 31),
    ifDate: true,
  },
  {
    value: 18,
    text: '16 - 17 years of age',
    textAt: 'at 31st July 2024',
    minDate: '2006-11-01',
    maxDate: '2008-07-31',
    minDateAsDate: new Date(2006, 10, 1),
    maxDateAsDate: new Date(2008, 6, 31),
    ifDate: true,
    ifImmunocompromised: true
  },
  {
    value: 19,
    text: '12 - 15 years of age',
    textAt: 'at 31st July 2024',
    minDate: '2008-08-01',
    maxDate: '2012-07-31',
    minDateAsDate: new Date(2008, 7, 1),
    maxDateAsDate: new Date(2012, 6, 31),
    ifDate: true,
    ifImmunocompromised: true
  },
  {
    value: 20,
    text: '5 - 11 years of age',
    textAt: 'at 31st July 2024',
    minDate: '2012-08-01',
    maxDate: '2019-07-31',
    minDateAsDate: new Date(2012, 7, 1),
    maxDateAsDate: new Date(2019, 6, 31),
    ifDate: true,
    ifImmunocompromised: true
  },
  {
    value: 21,
    text: 'None of the above',
    minDate: '1900-01-01',
    maxDate: '2012-07-31',
    minDateAsDate: new Date(1900, 0, 1),
    maxDateAsDate: new Date(2012, 6, 31),
    ifNone: true
  },
]
