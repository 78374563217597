<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" class="pt-16">
        <v-container>
          <back-button @click.native="$router.go(-1)"></back-button>
          <v-row>
            <v-col cols="12" lg="6">
              <h3 class="mb-8 home-header-3">
                Which vaccines have you had?
              </h3>
              <p class="mb-8 home-text-2">
                Select all that apply.
              </p>
              <div class="mb-8">
                <v-checkbox
                  v-model="preSelectedBookingTypes"
                  v-on:change="
                    onCheckboxGroupClicked();
                  "
                  v-on:keyup.native.enter="
                    addRemovePreSelectedBookingTypes(FLU_CODE);
                    onCheckboxGroupClicked();
                  "
                  label="Seasonal Flu"
                  color="#006078"
                  class="ml-4 px-8 black--text"
                  :value="FLU_CODE"
                  :ripple="false"
                ></v-checkbox>
                <v-checkbox
                  v-model="preSelectedBookingTypes"
                  v-on:change="
                    onCheckboxGroupClicked();
                  "
                  v-on:keyup.native.enter="
                    addRemovePreSelectedBookingTypes(COVID_CODE);
                    onCheckboxGroupClicked();
                  "
                  label="COVID Vaccine"
                  color="#006078"
                  class="ml-4 px-8 black--text"
                  :value="COVID_CODE"
                  :ripple="false"
                ></v-checkbox>
              </div>
              <v-btn
                @click="onAfterContinueClicked"
                class="v-btn--primary"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';
import BackButton from '@/components/BackButton';

const COVID_CODE = 'COVID Vaccine';
const FLU_CODE = 'Seasonal Flu';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepBookingTypes',
  data: () => ({
    preSelectedBookingTypes: [],
    COVID_CODE: COVID_CODE,
    FLU_CODE: FLU_CODE
  }),
  methods: {
    addRemovePreSelectedBookingTypes(value) {
      const index = this.preSelectedBookingTypes.indexOf(value);
      if (index > -1) {
        this.preSelectedBookingTypes.splice(index, 1);
      } else {
        this.preSelectedBookingTypes.push(value);
      }
    },
    savePreSelectedBookingTypes() {
      this.$store.commit(
        'setPreSelectedBookingTypes',
        this.preSelectedBookingTypes
      );
    },
    onCheckboxGroupClicked() {
      this.savePreSelectedBookingTypes();
    },
    onAfterContinueClicked() {
      this.$gtag.event('Pre selection booking type completed', {})
      if (this.preSelectedBookingTypes.includes(COVID_CODE)) {
        this.$router.push('/booking/received-last-dose').catch(() => {});
       }
       else {
        this.$router.push('/booking/demographics').catch(() => {});
       }
    }
  }
});
</script>
