<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <booking-details></booking-details>
        <h3 class="mb-6 home-header-3">
          Address
        </h3>
        <v-row>
          <v-col cols="12" lg="5">
            <label
              for="address-input"
              class="d-inline-flex mb-8 home-input-label font-weight-bold"
              >Please use the search box below to find your address</label
            >
            <div style="position: relative;">
              <vue-google-autocomplete
                v-if="googleMapsApiKeyLoaded"
                id="filter-google-autocomplete"
                ref="fga"
                types=""
                classname="google-autocomplete"
                placeholder=""
                v-on:placechanged="onGoogleAutocompletePlaceChanged"
                v-on:inputChange="onGoogleAutocompleteInputChanged"
                country="uk"
              >
              </vue-google-autocomplete>
              <v-btn
                v-if="showIconCurrentLocation"
                @click="onIconCurrentLocationClicked"
                color="primary"
                class="icon__current-location"
                fab
                x-small
                icon
                outlined
              >
                <v-icon>mdi-map-marker-radius</v-icon>
              </v-btn>
              <v-btn
                v-if="showIconClearLocation"
                @click="onIconClearLocationClicked"
                color="primary"
                class="icon__clear-location"
                fab
                x-small
                icon
                outlined
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-btn
              v-if="!showFullAddressForm"
              @click="showFullAddressForm = true"
              class="mt-2 pa-0 home-text-2 font-weight-normal"
              style="text-transform: unset !important"
              color="#006078"
              text
              >Alternatively, click here to enter manually</v-btn
            >
          </v-col>
        </v-row>
        <div v-if="showFullAddressForm" class="mt-8">
          <p class="mb-8 home-text-2">
            Fields marked with * are mandatory
          </p>
          <v-row>
            <v-col cols="12" lg="5">
              <label
                for="address1-input"
                class="d-inline-flex mb-2 home-input-label font-weight-bold"
                >Address line 1 *</label
              >
              <v-text-field
                v-model="address1"
                id="address1-input"
                ref="address1"
                name="address1"
                light
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="5">
              <label
                for="address2-input"
                class="d-inline-flex mb-2 home-input-label font-weight-bold"
                >Address line 2</label
              >
              <v-text-field
                v-model="address2"
                id="address2-input"
                ref="address2"
                name="address2"
                light
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="5">
              <label
                for="town-input"
                class="d-inline-flex mb-2 home-input-label font-weight-bold"
                >Town / City *</label
              >
              <v-text-field
                v-model="town"
                id="town-input"
                ref="town"
                name="town"
                light
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="5">
              <label
                for="postcode-input"
                class="d-inline-flex mb-2 home-input-label font-weight-bold"
                >Postcode *</label
              >
              <v-text-field
                v-model="postcode"
                id="postcode-input"
                ref="postcode"
                name="postcode"
                light
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="showMobilePhone">
            <v-col cols="12" lg="5">
              <label
                for="mobile-phone-input"
                class="d-inline-flex mb-2 home-input-label font-weight-bold"
                >Mobile Number</label
              >
              <vue-tel-input
                v-model="mobilePhone"
                v-bind:style="{
                  'border-color':
                    mobileValid || !mobilePhone ? 'rgba(0,0,0,.42)' : '#ff5252',
                  'border-width': mobileValid || !mobilePhone ? '1px' : '2px'
                }"
                @validate="onValidateMobilePhone"
                class="mb-8 phone-margin-fix v-input__slot"
                ref="mobilePhone"
                name="mobilePhone"
                mode="international"
                defaultCountry="GB"
                :inputOptions="{
                  id: 'mobile-phone-input',
                  placeholder: 'Mobile number'
                }"
              ></vue-tel-input>
            </v-col>
          </v-row>
          <v-row v-if="showEmailAddress">
            <v-col cols="12" lg="5">
              <label
                for="email-address-input"
                class="d-inline-flex mb-2 home-input-label font-weight-bold"
                >Email Address</label
              >
              <v-text-field
                v-model="emailAddress"
                for="email-address-input"
                ref="emailAddress"
                name="emailAddress"
                light
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-alert v-if="showError" color="#CD1041" class="mt-6 mb-6">
          <div class="px-12 py-10 white--text" v-html="errorMessage"></div>
        </v-alert>
        <v-btn
          ref="goToConfirm"
          @click="onAfterContinueClicked"
          class="mt-8 v-btn--primary"
          :loading="checkingPatientExists || updatingPatient"
        >
          Continue
        </v-btn>
      </v-container>
    </v-col>
  </v-row>
</template>

<style scoped>
.google-autocomplete {
  width: 100% !important;
  height: 48px !important;
  padding: 3px 0 !important;
  background: #f5f5f5 !important;
  box-shadow: none !important;
  border: 1px solid #d0d4d4 !important;
  border-radius: 0 !important;
  text-indent: 10px;
}

.google-autocomplete:hover,
.google-autocomplete:focus-visible {
  padding: 3px 0 !important;
  background-color: #f5f5f5 !important;
  box-shadow: 0 1px 5px rgb(0 0 0 / 0.2) !important;
  outline: none;
}

.icon__current-location,
.icon__clear-location {
  position: absolute;
  top: 8px;
  right: 10px;
  z-index: 2;
}
</style>

<script>
import auth4Patients from '@/helpers/auth4Patients';
import config from '@/config';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import BackButton from '@/components/BackButton';
import BookingDetails from './_BookingDetails';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { VueTelInput } from 'vue-tel-input';
import { getSesKey } from '@/helpers/session.ts';

export default Vue.extend({
  components: {
    BackButton,
    BookingDetails,
    VueGoogleAutocomplete,
    VueTelInput
  },
  name: 'BookingPatientAddress',
  data: () => ({
    google: window.google,
    googleMapsApiKeyLoaded: false,
    showFullAddressForm: false,
    showIconCurrentLocation: true,
    showIconClearLocation: false,
    showError: false,
    errorMessage: '',
    checkingPatientExists: false,
    autocomplete: null,
    updatingPatient: false,
    addressField: null,
    address: null,
    address1: null,
    address2: null,
    town: null,
    postcode: null,
    mobilePhone: null,
    mobileValid: false,
    emailAddress: null,
    emailValid: true
  }),
  mounted: function() {
    this.$store.commit('setPatientMatched', null);
    this.loadGoogleMapsScript();
    this.setPatientAddress();
  },
  computed: {
    ...mapGetters([
      'getBookingApiURLs',
      'getAppointmentProcedure',
      'getClinic',
      'getSlot',
      'getPatient',
      'getPatientJSON',
      'getFlowName'
    ]),
    accessToken4Patients() {
      if (!auth4Patients.verifySavedToken()) {
        return false;
      }
      return localStorage.getItem(config.patientAuthTokenName);
    },
    getCheckPatientExistsJSON() {
      return JSON.stringify({
        patient_number_1: this.getPatient.patientDetails.healthAndCareNumber,
        first_name: this.getPatient.patientDetails.firstName,
        last_name: this.getPatient.patientDetails.familyName,
        date_of_birth: this.dateOfBirthFormatted(
          this.getPatient.patientDetails.dateOfBirth
        ),
        mobile_number_1: this.mobilePhone
          ? this.mobilePhone.replace(/\s/g, '')
          : null,
        email_address_1: this.emailAddress
      });
    },
    showMobilePhone() {
      return (
        !this.accessToken4Patients &&
        (this.getPatient.patientAddress === null ||
        (!this.getPatient.patientAddress.stepsEmailAddress &&
          !this.getPatient.patientAddress.stepsMobilePhone))
      );
    },
    showEmailAddress() {
      return (
        !this.accessToken4Patients &&
        (this.getPatient.patientAddress === null ||
        (!this.getPatient.patientAddress.stepsMobilePhone &&
          !this.getPatient.patientAddress.stepsEmailAddress))
      );
    }
  },
  watch: {
    address(value) {}
  },
  methods: {
    loadGoogleMapsScript() {
      this.$loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${config.googleMapsApiKey}&libraries=places`
      ).then(() => {
        this.googleMapsApiKeyLoaded = true;
      });
    },
    onGoogleAutocompletePlaceChanged(data, place) {
      console.log(place);
      this.fillInAddress(place);
    },
    onGoogleAutocompleteInputChanged(input) {
      if (!input.newVal) {
        this.showIconCurrentLocation = true;
        this.showIconClearLocation = false;
      } else {
        this.showIconCurrentLocation = false;
        this.showIconClearLocation = true;
      }
    },
    async onIconCurrentLocationClicked() {
      navigator.geolocation.getCurrentPosition(
        async (s) => {
          this.$refs.fga.update('Your current location');

          const latlng = {
            lat: s.coords.latitude,
            lng: s.coords.longitude
          };

          const geocoder = new this.google.maps.Geocoder();
          const places = await geocoder.geocode({ location: latlng });

          if (places && places.results && places.results.length) {
            this.fillInAddress(places.results[0]);
          }
        },
        (e) => {
          console.log(e);
        }
      );
    },
    onIconClearLocationClicked() {
      this.$refs.fga.clear();
      this.address1 = '';
      this.address2 = '';
      this.postcode = '';
      this.town = '';
    },
    fillInAddress(place) {
      this.address1 = '';
      this.address2 = '';
      this.postcode = '';
      this.town = '';
      let address1 = '',
        address2 = '',
        postcode = '',
        town = '';

      for (const component of place.address_components) {
        for (const componentType of component.types) {
          switch (componentType) {
            case 'street_number': {
              address1 = `${component.long_name} ${address1}`;
              break;
            }

            case 'route': {
              address1 += component.short_name;
              break;
            }

            case 'postal_code': {
              postcode = `${component.long_name}${postcode}`;
              break;
            }

            case 'postal_town': {
              town += component.long_name;
              break;
            }

            case 'locality': {
              if (!town) {
                town += component.long_name;
              }
              break;
            }

            case 'administrative_area_level_2': {
              address2 += component.long_name;
              break;
            }
          }
        }
      }

      //otherwise Google autocomplete overrides this
      Vue.nextTick(() => {
        this.address1 = address1;
        this.address2 = address2;
        this.postcode = postcode;
        this.town = town;
        this.showFullAddressForm = true;
      });
    },
    dateOfBirthFormatted(dateOfBirth) {
      const year = dateOfBirth.getFullYear();
      let month = dateOfBirth.getMonth() + 1;
      let day = dateOfBirth.getDate();
      if (month < 10) {
        month = '0' + (month + '');
      }
      if (day < 10) {
        day = '0' + (day + '');
      }
      return day + '/' + month + '/' + year;
    },
    setPatientAddress() {
      if (this.getPatient.patientAddress) {
        this.address1 = this.getPatient.patientAddress.address1;
        this.address2 = this.getPatient.patientAddress.address2;
        this.town = this.getPatient.patientAddress.town;
        this.postcode = this.getPatient.patientAddress.postcode;

        if (this.getPatient.patientAddress.mobilePhone) {
          this.mobilePhone = this.getPatient.patientAddress.mobilePhone;
        } else if (this.getPatient.patientAddress.stepsMobilePhone) {
          this.mobilePhone = this.getPatient.patientAddress.stepsMobilePhone;
        }

        if (this.getPatient.patientAddress.emailAddress) {
          this.emailAddress = this.getPatient.patientAddress.emailAddress;
        } else if (this.getPatient.patientAddress.stepsEmailAddress) {
          this.emailAddress = this.getPatient.patientAddress.stepsEmailAddress;
        }
      }
    },
    savePatientAddress() {
      const patientAddress = this.getPatient.patientAddress || {};
      patientAddress.address1 = this.address1;
      patientAddress.address2 = this.address2;
      patientAddress.town = this.town;
      patientAddress.postcode = this.postcode;
      patientAddress.mobilePhone = this.mobilePhone;
      patientAddress.emailAddress = this.emailAddress;
      this.$store.commit('setPatientAddress', patientAddress);
    },
    validatePatientAddress() {
      let showError = false;
      let errorMessage = '';
      if (!this.address1 || !this.address1.trim()) {
        showError = true;
        errorMessage += 'Please enter address 1.<br>';
      }
      if (!this.town || !this.town.trim()) {
        showError = true;
        errorMessage += 'Please enter town.<br>';
      }
      if (!this.postcode || !this.postcode.trim()) {
        showError = true;
        errorMessage += 'Please enter post code.<br>';
      }
      if (
        (this.showMobilePhone || this.showEmailAddress) &&
        !this.mobilePhone &&
        !this.emailAddress
      ) {
        showError = true;
        errorMessage +=
          'Please enter either mobile phone or email address.<br>';
      }
      this.showError = showError;
      this.errorMessage = errorMessage;

      if (this.showError) {
        this.showFullAddressForm = true;
      }
    },
    onValidateMobilePhone({ valid }) {
      this.mobileValid = valid;
    },
    onAfterContinueClicked() {
      this.validatePatientAddress();
      if (this.showError) {
        return;
      }
      this.checkPatientExists();
    },
    checkPatientExists() {
      this.checkingPatientExists = true;
      fetch(this.getBookingApiURLs.checkPatientExistsUrl, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          'Key': getSesKey()
        },
        redirect: 'follow',
        body: this.getCheckPatientExistsJSON
      })
        .then((response) => response.json())
        .then((response) => {
          this.checkingPatientExists = false;
          if (response.status === 'S') {
            if (response.response.responseCode == 0) {
              this.showError = false;
              this.errorMessage = '';
              this.savePatientAddress();
              this.$gtag.event(
                `Patient adress filled`
                 + `${this.getFlowName}`,
                  {})
              this.$router.push('/booking/patient-details-2').catch(() => {});
            } else if (response.response.responseCode > 0) {
              this.$gtag.event(`Patient adress filled, vaccination was already scheduled`
               + `${this.getFlowName}`,
                {})
              this.showError = true;
              this.errorMessage =
                'Our records indicate you have already scheduled a vaccination. If you wish to re-schedule, please <a class="white--text" href="/portal" target="_blank">cancel your booking</a> first.';
              this.$refs.goToConfirm.$el.scrollIntoView({
                behavior: 'smooth'
              });
            }
          } else {
            this.showError = true;
            this.errorMessage =
              'Could not update patient data. Please try again.';
            this.$refs.goToConfirm.$el.scrollIntoView({
              behavior: 'smooth'
            });
          }
        })
        .catch((e) => {});
    }
  }
});
</script>
