<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <v-row v-if="!showDose2Message">
          <v-col cols="12" lg="6">
            <h3 class="mb-8 home-header-3">
              Have you had an MMR vaccine in the last 30 days?
            </h3>
            <v-radio-group
              v-model="receivedLastDoseLast30Days"
              v-on:change="onRadioGroupClicked"
              class="mb-4"
            >
              <v-radio
                v-on:keyup.enter="
                  receivedLastDoseLast30Days = 'Yes';
                  onRadioGroupClicked();
                "
                label="Yes"
                value="Yes"
                color="#006078"
                class="ml-4 px-8 py-4 black--text"
                :ripple="false"
              ></v-radio>
              <v-radio
                v-on:keyup.enter="
                  receivedLastDoseLast30Days = 'No';
                  onRadioGroupClicked();
                "
                label="No"
                value="No"
                color="#006078"
                class="ml-4 px-8 py-4 black--text"
                :ripple="false"
              ></v-radio>
            </v-radio-group>
            <v-btn
              @click="onAfterContinueClicked"
              class="v-btn--primary"
              :disabled="!receivedLastDoseLast30Days"
            >
              Continue
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" lg="6">
            <h3 class="mb-12 home-header-3">
              You are not eligible to receive dose 2 of MMR until a month has elapsed since you received dose 1, as per clinical requirements.<br /><br />
              Please select an appointment 30 days or more after your dose 1 appointment.
            </h3>
            <v-btn
              @click="onAfterContinueDose2MessageClicked"
              class="v-btn--primary"
              :disabled="!receivedLastDoseLast30Days"
            >
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import BackButton from '@/components/BackButton';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepReceivedLastDoseMMR',
  data: () => ({
    receivedLastDoseLast30Days: null,
    showDose2Message: false
  }),
  mounted: function() {
  },
  methods: {
    onRadioGroupClicked() {
      this.$store.commit('setIfCovid3DoseLetter', this.ifCovid3DoseLetter === 'Yes');
    },
    onAfterContinueClicked() {
      if (this.receivedLastDoseLast30Days === 'Yes') {
      this.$gtag.event('MMR received less than 30 days ago')
        this.showDose2Message = true;
      } 
      else {
      this.$gtag.event('MMR was received more than 30 days ago')
        this.$router.push('/booking/eligible').catch(() => {});
      }
    },
    onAfterContinueDose2MessageClicked() {
      this.$gtag.event('MMR dose <30 days ago, proceeding')
      this.$router.push('/booking/contact-details').catch(() => {});
    }
  }
});
</script>
