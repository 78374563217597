export default [
  {
    label: 'I am in a clinical risk group',
    label2: 'At Risk',
    value: 'At Risk',
    onlyFor3Dose: false
  },
  {
    label: 'I am immunosuppressed',
    label2: 'Immunosuppressed',
    value: 'Immunosuppressed',
    onlyFor3Dose: false
  },
  {
    label: 'I am a carer',
    label2: 'Carer',
    value: 'Carer',
    only3Dose: false
  },
  {
    label: 'I am a care home resident',
    label2: 'Care Home Resident',
    value: 'Care Home Resident',
    onlyFor3Dose: false
  },
  {
    label: 'I am pregnant',
    label2: 'Pregnant',
    value: 'Pregnant',
    onlyFor3Dose: false
  },
  {
    label: 'I am a health and social care worker',
    label2: 'HSC Worker',
    value: 'HSC Worker',
    onlyFor3Dose: false
  },
  {
    label: 'I am a bird culler',
    label2: 'Bird Culler',
    value: 'Bird Culler',
    onlyFor3Dose: false
  },
  {
    label: 'I am a close contact of an immunosupressed person',
    label2: 'Close Contact',
    value: 'Close Contact',
    onlyFor3Dose: false
  },
  {
    label: 'None of the above',
    label2: 'None of the above',
    value: '',
    onlyFor3Dose: false
  }
];
