<template>
    <v-row justify="center">
      <v-col cols="12" class="pt-16">
        <v-container>
          <back-button @click.native="$router.go(-1)"></back-button>
          <h3 class="mb-8 home-header-3">
            Have you already had a Pertussis vaccine earlier in your current pregnancy?
          </h3>
          <v-radio-group
            v-model="pertussisPreviouslyReceived"
            class="mb-4"
          >
            <v-radio
              v-on:keyup.enter="
                pertussisPreviouslyReceived = 'Yes';
              "
              label="Yes"
              value="Yes"
              color="#006078"
              class="ml-4 px-8 py-4 black--text"
              :ripple="false"
            ></v-radio>
            <v-radio
              v-on:keyup.enter="
                pertussisPreviouslyReceived = 'No';
              "
              label="No"
              value="No"
              color="#006078"
              class="ml-4 px-8 py-4 black--text"
              :ripple="false"
            ></v-radio>
          </v-radio-group>
          <v-btn
            @click="onAfterContinueClicked"
            class="v-btn--primary"
            :disabled="!pertussisPreviouslyReceived"
          >
            Continue
          </v-btn>
        </v-container>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import Vue from 'vue';
  import BackButton from '@/components/BackButton';
  const codes = {
    PERTUSSIS: 'PERTUSSIS'
  }
  export default Vue.extend({
    components: { BackButton },
    name: 'StepPertussisPregnancy1',
    data: () => ({
      pertussisPreviouslyReceived: null,
      ...codes
    }),
    methods: {
      onAfterContinueClicked() {
        this.$gtag.event('Pertussis previously received question answered', {})
        if (this.pertussisPreviouslyReceived === 'Yes') {
          this.$router.push('/booking/pertussis-not-eligible').catch(() => {});
        } else {
            this.$store.commit(
                'setBookingTypes',
                [this.PERTUSSIS]
            );
            this.$router.push('/booking/eligible').catch(() => {});
        }
      }
    }
  });
  </script>
  