<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <v-row>
          <v-col cols="12" lg="6">
            <h3 class="mb-8 home-header-3">
              Have you had a COVID-19 vaccine in the last 3 months?
            </h3>
            <v-radio-group
              v-model="hadAVaccineInLast3Months"
              class="mb-4"
            >
              <v-radio
                v-on:keyup.enter="
                  hadAVaccineInLast3Months = 'Yes';
                  onRadioGroupClicked();
                "
                label="Yes"
                value="Yes"
                color="#006078"
                class="ml-4 px-8 py-4 black--text"
                :ripple="false"
              ></v-radio>
              <v-radio
                v-on:keyup.enter="
                  hadAVaccineInLast3Months = 'No';
                  onRadioGroupClicked();
                "
                label="No"
                value="No"
                color="#006078"
                class="ml-4 px-8 py-4 black--text"
                :ripple="false"
              ></v-radio>
            </v-radio-group>
            <v-btn
              @click="onAfterContinueClicked"
              class="v-btn--primary"
              :disabled="!hadAVaccineInLast3Months"
            >
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import BackButton from '@/components/BackButton';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepReceivedLastDose',
  data: () => ({
    hadAVaccineInLast3Months: null
  }),
  computed: {
    ...mapGetters([
    'getAge',
    'getDemographics'
    ])
  },
  methods: {
    onAfterContinueClicked() {
      if (this.hadAVaccineInLast3Months === 'Yes') {
        this.$router.push('/booking/booking-covid-disclaimer').catch(() => {});
      } else {
        this.$router.push('/booking/demographics').catch(() => {});
      }
    }
  },
  mounted(){
    this.$gtag.event('Patient previously vaccinated', {})
  }
});
</script>