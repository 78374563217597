<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" class="pt-16">
        <v-container>
          <back-button @click.native="$router.go(-1)"></back-button>
          <v-row>
            <v-col cols="12" lg="6">
              <h3 v-if="getAge<1" class="mb-8 home-header-3">
                Unfortunately, you are not eligible to receive an MMR vaccination yet.
              </h3>
              <h3 v-if="getAge>=1 && getAge<6" class="mb-8 home-header-3">
                Unfortunately, you are not eligible to book an MMR vaccination online, please contact your GP practice to arrange a vaccine.
              </h3>
              <h3 v-if="getAge>=26" class="mb-8 home-header-3">
                Unfortunately, you are not eligible to get your vaccine on this MMR catch-up programme.<br /><br />
                If you require any further information please contact
                <a style="color: #007e93;" href="mailto:pha.immunisation@hscni.net">pha.immunisation@hscni.net</a>.
              </h3>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import BackButton from '@/components/BackButton';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepBookingTypesGroups',
  data: () => ({
  }),
    computed: {
    ...mapGetters([
    'getAge'
    ])
  },
  methods: {
  },
  mounted(){
    this.$gtag.event('MMR flow not eligible due to patient age')
  }
});
</script>
