<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <booking-details></booking-details>
        <v-row v-if="patientVerified && getPatient.patientMatched.status === 'S'" justify="center" class="mt-16 mb-8">
          <v-col
            rows="12"
            lg="7"
            class="pa-12"
            style="background-color: #007e93; color: #ffffff;"
          >
            <h3 class="mb-8 home-header-3">
              We could not find you in the Health and Care Index.
            </h3>
            <p class="mb-8 home-text-2">
              Don't worry - you'll still be able to book an appointment.
            </p>
            <p class="mb-8 home-text-2">
              Please check the details entered below. You can find your HCN on
              your medical card or any letter from the hospital.
            </p>
            <p class=" mb-0 home-text-2">
              Press continue to confirm your appointment.
            </p>
          </v-col>
        </v-row>
        <v-row v-if="patientVerified && getPatient.patientMatched.status === 'E'" justify="center" class="mt-16 mb-8">
          <v-col
            rows="12"
            lg="7"
            class="pa-12"
            style="background-color: #007e93; color: #ffffff;"
          >
            <p class="mb-8 home-text-2">
              We have been unable to validate your Health and Care number at this time.
            </p>
            <p class="mb-8 home-text-2">
              Please ensure you inform the person booking you in for your vaccination.
            </p>
            <p class=" mb-0 home-text-2">
              Thank you.
            </p>
          </v-col>
        </v-row>
        <v-row justify="center" class="mb-8">
          <v-col rows="12" lg="6">
            <v-row align="center" style="border-bottom: 1px solid #004651">
              <v-col><span class="font-weight-bold">Name</span></v-col>
              <v-col>{{ getPatient.patientDetails.firstName }} {{ getPatient.patientDetails.familyName }}</v-col>
              <v-col class="text-right"
                ><v-btn @click="goToPatientDetails1" color="#004651" text>Change</v-btn></v-col
              >
            </v-row>
            <v-row align="center" style="border-bottom: 1px solid #004651">
              <v-col><span class="font-weight-bold">Date of birth</span></v-col>
              <v-col>{{ patientDateOfBirth }}</v-col>
              <v-col class="text-right"
                ></v-col
              >
            </v-row>
            <v-row align="center" style="border-bottom: 1px solid #004651">
              <v-col><span class="font-weight-bold">Address</span></v-col>
              <v-col>{{ patientAddress }}</v-col>
              <v-col class="text-right"
                ><v-btn @click="goToPatientAddress" color="#004651" text>Change</v-btn></v-col
              >
            </v-row>
            <v-row align="center" style="border-bottom: 1px solid #004651">
              <v-col><span class="font-weight-bold">House name/number</span></v-col>
              <v-col>{{ this.getPatient.patientAddress.town }}</v-col>
              <v-col class="text-right"
                ><v-btn @click="goToPatientAddress" color="#004651" text>Change</v-btn></v-col
              >
            </v-row>
            <v-row align="center" style="border-bottom: 1px solid #004651">
              <v-col><span class="font-weight-bold">Postcode</span></v-col>
              <v-col>{{ this.getPatient.patientAddress.postcode }}</v-col>
              <v-col class="text-right"
                ><v-btn @click="goToPatientAddress" color="#004651" text>Change</v-btn></v-col
              >
            </v-row>
            <v-row align="center" style="border-bottom: 1px solid #004651">
              <v-col><span class="font-weight-bold">Sex</span></v-col>
              <v-col>{{ patientGender }}</v-col>
              <v-col class="text-right"
                ><v-btn @click="goToPatientDetails2" color="#004651" text>Change</v-btn></v-col
              >
            </v-row>
            <v-row align="center" style="border-bottom: 1px solid #004651">
              <v-col><span class="font-weight-bold">Ethnic Background</span></v-col>
              <v-col>{{ getPatient.patientDetails.ethnicBackground }}</v-col>
              <v-col class="text-right"
                ><v-btn @click="goToPatientDetails2" color="#004651" text>Change</v-btn></v-col
              >
            </v-row>
            <v-row align="center" :style="getIfTrustPrimaryEmployer ? 'border-bottom: 1px solid #004651' : ''">
              <v-col><span class="font-weight-bold">Health & Care Number</span></v-col>
              <v-col>{{ getPatient.patientDetails.healthAndCareNumber || 'Not provided' }}</v-col>
              <v-col class="text-right"
                ><v-btn @click="goToPatientDetails2" color="#004651" text>Change</v-btn></v-col
              >
            </v-row>
            <v-row v-if="getIfTrustPrimaryEmployer" align="center">
              <v-col><span class="font-weight-bold">Staff Number</span></v-col>
              <v-col>{{ getPatient.patientDetails.staffNumber || 'Not provided' }}</v-col>
              <v-col class="text-right"
                ><v-btn @click="goToPatientDetails2" color="#004651" text>Change</v-btn></v-col
              >
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center" class="mb-8">
          <v-col
            rows="12"
            lg="7"
          >
            <v-btn @click="onAfterContinueClicked" class="v-btn--primary">
              I confirm my details
            </v-btn>
          </v-col>
        </v-row>
        <v-dialog v-model="verifyingPatient" width="500" persistent>
          <v-card :loading="true" class="mx-auto">
            <template slot="progress">
              <v-progress-linear
                color="#006078"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-card-text class="py-8 text-subtitle-1 text-center">
              Please wait while we try to match your details.
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
    </v-col>
  </v-row>
</template>

<style scoped></style>

<script>
import auth from '@/helpers/auth';
import auth4Patients from '@/helpers/auth4Patients';
import config from '@/config';
import Vue from 'vue';
import moment from 'moment/moment';
import { mapGetters } from 'vuex';
import { patientToFormJSON } from '@/helpers/patient.ts';
import { getSesKey } from '@/helpers/session.ts';
import BackButton from '@/components/BackButton';
import BookingDetails from './_BookingDetails';

export default Vue.extend({
  components: { BackButton, BookingDetails },
  name: 'BookingPatientDetails',
  data: () => ({
    verifyingPatient: false,
    patientVerified: null,
    showError: false,
    errorMessage: ''
  }),
  mounted: async function() {
    this.setPatientDetails();
    
    if (!this.getPatient.patientMatched) {
      await this.verifyPatient();
    }
  },
  computed: {
    ...mapGetters([
      'getIfTrustPrimaryEmployer',
      'getDateOfBirth',
      'getBookingApiURLs',
      'getAppointmentProcedure',
      'getClinic',
      'getSlot',
      'getPatient',
      'getFlowName'
    ]),
    accessToken4Admin() {
      if (!auth.verifySavedToken()) {
        return false;
      }
      return localStorage.getItem(config.authTokenName);
    },
    accessToken4Patients() {
      if (!auth4Patients.verifySavedToken()) {
        return false;
      }
      return localStorage.getItem(config.patientAuthTokenName);
    },
    patientDateOfBirth() {
      return moment(this.getPatient.patientDetails.dateOfBirth).format(
        'DD / MM / YYYY'
      );
    },
    patientGender() {
      return this.getPatient.patientDetails.sex === 'Male' ? 'Male' : 'Female';
    },
    patientAddress() {
      let address = '';
      if (this.getPatient.patientAddress.address1) {
        address += this.getPatient.patientAddress.address1;
      }
      if (this.getPatient.patientAddress.address2) {
        address += ', ' + this.getPatient.patientAddress.address2;
      }
      return address;
    },
    slotDate() {
      return moment(this.getSlot.availability_date).format('D MMMM YYYY');
    },
    slotTime() {
      return moment(this.getSlot.slot_start, 'hh:mm:ss')
        .format('HH:mm a')
        .toUpperCase();
    }
  },
  methods: {
    setPatientDetails() {
      if (this.getPatient.patientDetails) {
        this.firstName = this.getPatient.patientDetails.firstName;
        this.familyName = this.getPatient.patientDetails.familyName;
        this.dateOfBirth = this.getPatient.patientDetails.dateOfBirth;
      }
      if (!this.dateOfBirth) {
        this.dateOfBirth = this.getDateOfBirth;
      }
    },
    savePatientDetails() {
      const patientDetails = this.getPatient.patientDetails || {};
      patientDetails.firstName = this.firstName;
      patientDetails.familyName = this.familyName;
      patientDetails.dateOfBirth = this.dateOfBirth;
      this.$store.commit('setPatientDetails', patientDetails);
    },
    validatePatientDetails() {
      let showError = false;
      let errorMessage = '';
      if (!this.firstName || !this.firstName.trim()) {
        showError = true;
        errorMessage += 'Please enter first name.<br>';
      }
      if (!this.familyName || !this.familyName.trim()) {
        showError = true;
        errorMessage += 'Please enter family name.<br>';
      }
      if (!this.dateOfBirth) {
        showError = true;
        errorMessage += 'Please enter correct date of birth.<br>';
      }
      this.showError = showError;
      this.errorMessage = errorMessage;
    },
    onDateOfBirthChanged(date) {
      this.dateOfBirth = date;
    },
    onAfterContinueClicked() {
      this.validatePatientDetails();
      if (this.showError) {
        return;
      }
      this.savePatientDetails();
      this.$gtag.event(`Patient summary viewed` + `${this.getFlowName}`, {})
      this.$router.push('/booking/confirm').catch(() => {});
    },
    goToPatientDetails1() {
      this.$router.push('/booking/patient-details-1').catch(() => {});
    },
    goToPatientDetails2() {
      this.$router.push('/booking/patient-details-2').catch(() => {});
    },
    goToPatientAddress() {
      this.$router.push('/booking/patient-address').catch(() => {});
    },
    async verifyPatient() {
      this.verifyingPatient = true;
      let result = null;
      try {
        const resultFetch = await fetch(this.getBookingApiURLs.verifyPatientUrl, {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'Key': getSesKey()
          },
          redirect: 'follow',
          body: patientToFormJSON(this.getPatient, this.accessToken4Admin || this.accessToken4Patients)
        });
        result = await resultFetch.json();
      }
      catch {
        this.verifyingPatient = false;
        this.$store.commit('setPatientMatched', { status: 'E', matched: 'E' });
      }

      this.verifyingPatient = false;

      if (result && result.status === 'S') {
        if (result.response.patientVerifyResult && result.response.patientVerifyResult.match) {
          this.$store.commit('setPatientMatched', { status: result.response.patientVerifyStatus, matched: result.response.patientVerifyResult.match || 'E' });
          this.patientVerified = true;
          this.$router.push('/booking/confirm').catch(() => {});
        } else {
          this.$store.commit('setPatientMatched', { status: result.response.patientVerifyStatus, matched: 'E' });
          this.patientVerified = true;
        }
      }
    }
  }
});
</script>
