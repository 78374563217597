<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16 max-w">
      <vue-recaptcha
        ref="invisibleRecaptcha"
        @verify="handleRecaptcha"
        @error="showCaptchaError"
        @expired="onExpired"
        size="invisible"
        :sitekey="recaptchaSiteKey"
      >
      </vue-recaptcha>
      <v-container>
        <back-button 
          @click.native="$router.go(-1)">
        </back-button>
        <v-alert v-if="alertCaptcha" color="#CD1041" class="mb-12">
          <div class="px-12 py-10 white--text">
            {{ alertCaptchaMessage }}
          </div>
        </v-alert>
        <v-alert v-if="alertEmailPhone" color="#CD1041" class="mb-12">
          <div class="px-12 py-10 white--text">
            {{ alertEmailPhoneMessage }}
          </div>
        </v-alert>
        <v-alert v-if="alertAccessCode" color="#CD1041" class="mb-12">
          <div
            class="px-12 py-10 white--text"
            v-html="alertAccessCodeMessage"
          ></div>
        </v-alert>
        <v-form
          @submit.prevent="handleCreate"
          data-vv-scope="patientIdentityForm"
          v-if="!verifyToken"
          class="ma-0 pa-0"
        >
          <v-container class="ma-0 pa-0">
            <v-row>
              <v-col
                v-if="
                  contactDetails !== 'mobilePhone' &&
                    contactDetails !== 'emailAddress'
                "
                cols="12"
                lg="6"
                class="mb-8"
              >
                <h3 class="mb-8 home-header-3">
                  Please wait... Loading locations and availability.
                </h3>
              </v-col>
              <v-col
                v-if="contactDetails === 'mobilePhone'"
                cols="12"
                lg="6"
                class="mb-8"
              >
                <h3 class="mb-8 home-header-3">
                  What is your mobile phone number?
                </h3>
                <v-row>
                  <v-col cols="12" lg="8">
                    <label
                      for="mobile-number-input"
                      class="d-inline-flex mb-2 home-input-label font-weight-bold"
                      >Mobile number</label
                    >
                    <vue-tel-input
                      v-model="mobilePhone"
                      v-bind:style="{
                        'border-color':
                          mobileValid || !mobilePhone
                            ? 'rgba(0,0,0,.42)'
                            : '#ff5252',
                        'border-width':
                          mobileValid || !mobilePhone ? '1px' : '2px'
                      }"
                      @validate="onValidateMobile"
                      @blur="onBlurMobile"
                      class="mb-0 phone-margin-fix v-input__slot"
                      ref="mobilePhone"
                      name="mobilePhone"
                      mode="international"
                      defaultCountry="GB"
                      :disabled="creating"
                      :inputOptions="{
                        id: 'mobile-number-input',
                        placeholder: 'Mobile number'
                      }"
                    ></vue-tel-input>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-if="contactDetails === 'emailAddress'"
                cols="12"
                lg="6"
                class="mb-2"
              >
                <h3 class="mb-8 home-header-3">
                  What is your email address?
                </h3>
                <v-row>
                  <v-col cols="12" lg="8">
                    <label
                      for="email-address-input"
                      class="d-inline-flex mb-2 home-input-label font-weight-bold"
                      >Email address
                    </label>
                    <v-text-field
                      v-model="emailAddress"
                      data-vv-name="emailAddress"
                      id="email-address-input"
                      ref="emailAddress"
                      name="emailAddress"
                      :disabled="creating"
                      light
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="!verifyToken">
              <v-col cols="12">
                <v-btn
                  type="submit"
                  color="#006078"
                  class="v-btn--primary"
                  :loading="creating"
                  large
                  light
                >
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-form
          @submit.prevent="handleVerify('tf-form')"
          data-vv-scope="tf-form"
          v-if="verifyToken"
          class="mb-10"
        >
          <v-container class="ma-0 pa-0">
            <v-row>
              <v-col cols="12" lg="6">
                <h3 class="mb-8 home-header-3">
                  Enter the 5 digit access code
                </h3>
                <p class="home-text-2">
                  We have sent the access code to your preferred communication
                  method.
                </p>
                <p class="mb-8 home-text-2">
                  It may take a few minutes to arrive.
                </p>
                <v-row>
                  <v-col cols="12" lg="6">
                    <label
                      for="verify-code-input"
                      class="d-inline-flex mb-2 home-input-label font-weight-bold"
                      >Access Code</label
                    >
                    <v-text-field
                      v-model="verifyCode"
                      @input="handleVerify"
                      @focus="$event.target.select()"
                      class="mb-8"
                      data-vv-name="code"
                      id="verify-code-input"
                      ref="verifyCode"
                      name="verifyCode"
                      type="number"
                      append-icon="mdi-secret"
                      autocomplete="off"
                      :disabled="verifying"
                      light
                      outlined
                    ></v-text-field>

                    <v-btn
                      color="#006078"
                      class="v-btn--primary"
                      :loading="creating || verifying || checking"
                      :disabled="true"
                      large
                      light
                    >
                      Continue
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-dialog
          v-model="dialogQueueError"
          width="500"
          persistent
          aria-label="You have been placed in a queue and you will be redirected to the
              booking page as soon as it becomes available."
        >
          <v-card :loading="true" class="mx-auto">
            <template slot="progress">
              <v-progress-linear
                color="#006078"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-card-text class="text-subtitle-1 pt-4">
              You have been placed in a queue and you will be redirected to the
              booking page as soon as it becomes available.<br /><br />
              Please do not refresh the page as this will re-start the booking
              process and you will be placed at the end of the queue.<br /><br />
              We would like to thank you for your patience and understanding.<br /><br />
              <span> Estimated waiting time: {{ queueTimeText }} </span>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
    </v-col>
  </v-row>
</template>

<style>
.vue-tel-input {
  box-shadow: none !important;
  line-height: 2.5em;
}

.vue-tel-input:hover {
  border-color: #000 !important;
}

.vti__dropdown {
  outline: none !important;
}

.vti__dropdown-list {
  padding: 0 !important;
}
</style>

<script>
import config from '@/config';
import auth from '@/helpers/auth';
import { mapGetters } from 'vuex';
import { VueTelInput } from 'vue-tel-input';
import { VueRecaptcha } from 'vue-recaptcha';
import BackButton from '@/components/BackButton';

export default {
  name: 'TwoStageVerify',
  components: { VueTelInput, VueRecaptcha, BackButton },
  props: {
    value: {
      type: String,
      default: ''
    },
    contactDetails: {
      type: String,
      default: ''
    },
    dateOfBirth: {
      type: Date,
      default: ''
    },
    groupName: {
      type: String,
      default: ''
    },
    groupNameAs: {
      type: String,
      default: ''
    }
  },
  mounted() {
    //admin flow, we skip auth step, already authed
    if (this.accessToken4Admin()) {
      this.dialogForAdmin = true;
      this.$refs.invisibleRecaptcha.execute();
      return;
    }

    if (this.contactDetails === 'mobilePhone') {
      this.$refs.mobilePhone.$el.querySelector('input').focus();
    } else if (this.contactDetails === 'emailAddress') {
      this.$refs.emailAddress.$el.querySelector('input').focus();
    }
  },
  computed: {
    ...mapGetters([
      'getDateOfBirth',
      'getAge',
      'getBehalfOfChild',
      'getPreSelectedBookingTypes',
      'getIfCovid',
      'getDemographics',
      'getIfCovid3DoseLetter',
      'getIfCovidBoosterLetter',
      'getIfCovidDoctorClinicianLetter',
      'getHealthAndSocialCareWorker',
      'getFrontlineHealthAndSocialCareWorker',
      'getTrust',
      'getIfTrustPrimaryEmployer',
      'getJobFamily',
      'getIfResident',
      'getBookingTypes',
      'getIfReceived1Dose',
      'getIfReceived2Dose',
      'getIfReceived3Dose',
      'getBookingApiURLs',
      'getSlot',
      'getClinic',
      'getPatient',
      'getAppointment',
      'getBookingDetailsJSON'
    ]),
    recaptchaSiteKey() {
      return config.keyRecaptchaSite;
    },
    classEmail() {
      return this.$vuetify.breakpoint.mdAndUp
        ? 'ma-0 pa-0 pl-2'
        : 'ma-0 pa-0 pt-2';
    },
    queueTimeText() {
      if (!this.queueTime) {
        return 'estimating waiting time ...';
      } else if (this.queueTime <= 60) {
        return 'less than a minute';
      } else if (this.queueTime > 60) {
        return (
          'about ' + Math.ceil(this.queueTime / 60).toString() + ' minutes'
        );
      } else {
        return '';
      }
    },
  },
  data() {
    return {
      contact: '',
      code: '',
      lastName: null,
      mobilePhone: '',
      emailAddress: '',
      creating: false,
      mobileValid: true,
      mobileValidTemp: null,
      autofilled: false,
      config: config,
      verifyToken: null,
      verifyCode: null,
      verifying: false,
      checking: false,
      alertCaptcha: false,
      alertEmailPhone: false,
      emailPhoneMessage: '',
      alertAccessCode: false,
      alertAccessCodeMessage: '',
      recaptchaToken: '',
      dialogQueueError: false,
      queueTime: null
    };
  },
  watch: {
    emailAddress() {
      this.verifyToken = '';
    },
    mobilePhone() {
      this.verifyToken = '';
    }
  },
  methods: {
    accessToken4Admin() {
      if (!auth.verifySavedToken()) {
        return false;
      }
      return localStorage.getItem(config.authTokenName);
    },
    async handleRecaptcha(token) {
      this.recaptchaToken = token;
      //admin flow, we skip auth step, already authed
      if (this.accessToken4Admin()) {
        this.$router.push('/booking/clinics').catch(() => {});
        return;
      }
      if (!this.verifyToken) {
        await this.create();
      } else {
        this.verify();
      }
    },
    handleCreate() {
      this.alertEmailPhone = false;

      if (this.contactDetails === 'mobilePhone' && !this.mobilePhone.trim()) {
        this.showEmailPhoneError('Enter a mobile number.');
        return;
      }

      if (this.contactDetails === 'emailAddress' && !this.emailAddress.trim()) {
        this.showEmailPhoneError('Enter an email address.');
        return;
      }

      if (
        this.mobilePhone &&
        (!/^\+(?:[0-9] ?){6,14}[0-9]$/.test(this.mobilePhone) ||
          !this.mobileValid)
      ) {
        this.showEmailPhoneError('The mobile number is invalid.');
        return;
      }

      if (
        this.emailAddress &&
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.emailAddress
        )
      ) {
        this.showEmailPhoneError('The email address is invalid.');
        return;
      }

      if (this.verifyToken) {
        this.resetRecaptcha();
      }

      this.$refs.invisibleRecaptcha.execute();
    },
    handleVerify() {
      if (!this.verifyCode.trim() || this.verifyCode.length !== 5) {
        return;
      }
      this.$refs.invisibleRecaptcha.execute();
    },
    resetRecaptcha() {
      this.recaptchaToken = '';
      this.$refs.invisibleRecaptcha.reset();
    },
    onValidateMobile({ valid }) {
      if (this.mobileValidTemp !== null) {
        this.mobileValid = valid;
      }
      this.mobileValidTemp = valid;
    },
    onBlurMobile() {
      this.mobileValid = this.mobileValidTemp;
    },
    onExpired() {
      window.location.reload(true);
    },
    showCaptchaError(message) {
      if (!message) return;
      this.alertCaptchaMessage =
        typeof message !== 'undefined'
          ? message
          : 'Sorry, the verification was not successful. Reload the page and try again.';
      this.alertCaptcha = true;
    },
    showEmailPhoneError(message) {
      this.alertEmailPhoneMessage =
        typeof message !== 'undefined'
          ? message
          : 'Sorry, the verification was not successful. Reload the page and try again.';
      this.alertEmailPhone = true;
    },
    showAccessCodeError(message) {
      this.alertAccessCodeMessage =
        typeof message !== 'undefined'
          ? message
          : 'Sorry, the verification was not successful. Reload the page and try again.';
      this.alertAccessCode = true;
    },
    dateOfBirthFormatted(dateOfBirth) {
      const year = dateOfBirth.getFullYear();
      let month = dateOfBirth.getMonth() + 1;
      let day = dateOfBirth.getDate();
      if (month < 10) {
        month = '0' + (month + '');
      }
      if (day < 10) {
        day = '0' + (day + '');
      }
      return day + '/' + month + '/' + year;
    },
    async create() {
      this.creating = true;
      let result = null;
      try {
        const resultFetch = await fetch(config.apiUrl + '/create', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'follow',
          body: JSON.stringify({
            action: 'create',
            mobile: this.mobilePhone,
            email: this.emailAddress,
            recaptchaToken: this.recaptchaToken,
            data: {
              booking_details_json: this.getBookingDetailsJSON,
              booking_types: this.getBookingTypes
                ? this.getBookingTypes.join(',')
                : null,
              date_of_birth: this.dateOfBirth
                ? this.dateOfBirthFormatted(this.dateOfBirth)
                : null,
              custom_1: this.getDemographics
                ? this.getDemographics.join(',')
                : null,
              custom_8: this.getJobFamily,
              custom_19: this.getHealthAndSocialCareWorker ? 1 : 0,
              custom_25: this.getTrust,
              custom_31: this.groupName,
              custom_32: this.groupNameAs,
              if_trust_primary_employer: this.getIfTrustPrimaryEmployer ? 1 : 0
            }
          })
        });
        result = await resultFetch.json();
      }
      catch {
        this.verifyToken = null;
        this.creating = false;
        this.showAccessCodeError();
        this.resetRecaptcha();
        return;
      }
      if (result.status !== 'S') {
        this.showAccessCodeError();
        this.resetRecaptcha();
        return;
      }
      setTimeout(() => {
        this.$refs.verifyCode.$el.querySelector('input').focus();
      }, 100);
      const patientAddress = {
        stepsMobilePhone: this.mobilePhone,
        stepsEmailAddress: this.emailAddress
      };
      this.verifyToken = result.verifyToken;
      this.creating = false;
      this.$store.commit('setPatientAddress', patientAddress);
      this.resetRecaptcha();
    },
    verify() {
      this.alertAccessCode = false;
      this.verifying = true;
      const url = config.apiUrl + '/verify';
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        body: JSON.stringify({
          action: 'verify',
          verifyToken: this.verifyToken,
          verifyCode: this.verifyCode
        })
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status !== 'S' || !response.valid) {
            this.resetRecaptcha();
            this.verifying = false;
            this.verifyCode = '';
            this.showAccessCodeError(
              'The access code entered is not valid,<br>please try again.'
            );
            setTimeout(() => {
              this.$refs.verifyCode.$el.querySelector('input').focus();
            }, 100);
            return;
          }
          this.checkToken();
          this.resetRecaptcha();
        })
        .catch(() => {
          this.resetRecaptcha();
          this.verifying = false;
          this.verifyCode = '';
          this.showAccessCodeError(
            'The access code entered is not valid,<br>please try again.'
          );
          setTimeout(() => {
            this.$refs.verifyCode.$el.querySelector('input').focus();
          }, 100);
        });
    },
    checkToken() {
      this.checking = true;
      const url = config.apiUrl + '/check';
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        body: JSON.stringify({
          verifyToken: this.verifyToken
        })
      })
        .then((response) => response.json())
        .then((response) => {
          this.checking = false;
          if (
            typeof response.status !== 'undefined' &&
            response.status === 'S'
          ) {
            if (response.allowed) {
              this.dialogQueueError = false;
              this.$emit('authed', {
                accessTokenEncrypted: response.accessToken
              });
              this.$router.push('/booking/clinics').catch(() => {});
            } else {
              this.dialogQueueError = true;
              this.queueTime = response.time;
              window.setTimeout(() => this.checkToken(), 5000);
            }
          } else {
            this.showAccessCodeError('Could not proceed,<br>please try again.');
          }
        })
        .catch(() => {
          this.checking = false;
          this.showAccessCodeError('Could not proceed,<br>please try again.');
        });
    }
  }
};
</script>
