<template>
  <div>
    <v-container
      fluid
      class="ma-0 pa-0 mb-16 jumbo-header"
      style="background-color: #F9F9F9;"
    >
      <v-row justify="center" align="center" class="fill-height">
        <v-col class="pt-8 pb-16">
          <v-container>
            <back-button
              class="my-8"
              @click.native="$router.go(-1)"
            ></back-button>
            <v-row>
              <v-col cols="12" lg="9">
                <h2 class="mb-8 home-header-2">
                  Unfortunately you are not eligible to book using this service at this time.
                </h2>
                <p class="mb-8 home-header-3">
                  If you believe you are still eligible, please email us at
                  <a style="color: #007e93;" href="mailto:pha.immunisation@hscni.net">pha.immunisation@hscni.net</a>.
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
    </v-container>
  </div>
</template>

<script>
import Vue from 'vue';
import BackButton from '@/components/BackButton';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepNotEligibleGP'
});
</script>
