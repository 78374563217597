<template>
    <div>
      <v-container
        fluid
        class="ma-0 pa-0 mb-16 jumbo-header"
        style="background-color: #F9F9F9;"
      >
        <v-row justify="center" align="center" class="fill-height">
          <v-col class="pt-8 pb-16">
            <v-container>
              <back-button
                class="my-8"
                @click.native="$router.go(-1)"
              ></back-button>
              <v-row>
                <v-col cols="12" lg="9">
                  <h2 class="mb-8 home-header-3">
                    Only one dose of Pertussis Vaccine is required during each <br />
                    pregnancy, hence, you are currently not eligible to receive this vaccine. <br />
                    Please discuss this with your midwife or GP.
                  </h2>
                  <h3>
                    If you require any further information please contact 
                    <a style="color: #007e93;" href="mailto:pha.immunisation@hscni.net">pha.immunisation@hscni.net</a>.
                  </h3>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
      <v-container> </v-container>
    </div>
  </template>
  
  <script>
  import Vue from 'vue';
  import BackButton from '@/components/BackButton';

  export default Vue.extend({
    components: {
      BackButton
    },
    name: 'StepPertussisNotEligible',
    mounted(){
      this.$gtag.event('Pertussis not eligible - received before')
    }
  });
  </script>
  