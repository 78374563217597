
function dateOfBirthFormattedDDMMYYYY(dateOfBirth: any) {
  const year = dateOfBirth.getFullYear();
  let month = dateOfBirth.getMonth() + 1;
  let day = dateOfBirth.getDate();
  if (month < 10) {
    month = '0' + (month + '');
  }
  if (day < 10) {
    day = '0' + (day + '');
  }
  return day + '/' + month + '/' + year;
}

export const  uniqId = () => {
  return Math.round(new Date().getTime() + (Math.random() * 100))
}

function dateOfBirthFormattedYYYYMMDD(dateOfBirth: any) {
  const year = dateOfBirth.getFullYear();
  let month = dateOfBirth.getMonth() + 1;
  let day = dateOfBirth.getDate();
  if (month < 10) {
    month = '0' + (month + '');
  }
  if (day < 10) {
    day = '0' + (day + '');
  }
  return year + '-' + month + '-' + day;
}

export const patientToFormJSON = (patient: any, accessToken: string) => {
  return JSON.stringify({
    patient_number_1: patient.patientDetails.healthAndCareNumber,
    first_name: patient.patientDetails.firstName,
    last_name: patient.patientDetails.familyName,
    date_of_birth: dateOfBirthFormattedDDMMYYYY(
      patient.patientDetails.dateOfBirth
    ),
    date_of_birth_sql: dateOfBirthFormattedYYYYMMDD(
      patient.patientDetails.dateOfBirth
    ),
    sex: patient.patientDetails.sex === 'Male' ? 'M' : 'F',
    address_1: patient.patientAddress.address1,
    address_2: patient.patientAddress.address2,
    post_code: patient.patientAddress.postcode,
    town: patient.patientAddress.town,
    mobile_number_1: accessToken ? patient.patientAddress.mobilePhone : null, //see otp
    email_address_1: accessToken ? patient.patientAddress.emailAddress : null, //see otp
    custom_1: null, //see otp
    custom_4: null, //allergies
    custom_5: null, //require assistance
    custom_6: patient.patientDetails.ethnicBackground,
    custom_7: patient.patientDetails.ethnicBackgroundDescription,
    custom_8: null, //see otp
    custom_27: patient.patientDetails.rqiaCode,
    custom_29: patient.patientDetails.staffNumber,
    custom_31: null, //see otp,
    custom_32: null //see otp
  });
}
