var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(
      !_vm.showSeasonalFlu &&
      !_vm.showCovidVaccine &&
      !_vm.showCovidUnder12Vaccine
    )?_c('v-container',{staticClass:"ma-0 pa-0 mb-16 jumbo-header",staticStyle:{"background-color":"#F9F9F9"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"pt-8 pb-16"},[_c('v-container',[_c('back-button',{staticClass:"my-8",nativeOn:{"click":function($event){return _vm.$router.go(-1)}}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"9"}},[_c('h2',{staticClass:"mb-8 home-header-2"},[_vm._v(" Unfortunately you are not eligible to book using this service at this time. You may be eligible to book at a local pharmacy or your GP. ")])])],1)],1)],1)],1)],1):_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pt-16",attrs:{"cols":"12"}},[_c('v-container',[_c('back-button',{nativeOn:{"click":function($event){return _vm.$router.go(-1)}}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('h3',{staticClass:"mb-8 home-header-3"},[_vm._v(" Which vaccinations are you booking today? ")]),_c('div',{staticClass:"mb-8"},[(_vm.showSeasonalFlu)?_c('v-checkbox',{staticClass:"ml-4 px-8 black--text",attrs:{"label":"Seasonal Flu","value":"FLU","color":"#006078","ripple":false},on:{"change":function($event){return _vm.onCheckboxGroupClicked()}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.addRemoveBookingTypes('FLU');
                  _vm.onCheckboxGroupClicked();}},model:{value:(_vm.bookingTypes),callback:function ($$v) {_vm.bookingTypes=$$v},expression:"bookingTypes"}}):_vm._e(),(_vm.showCovidVaccine && !_vm.showCovidUnder12Vaccine)?_c('v-checkbox',{staticClass:"ml-4 px-8 black--text",attrs:{"label":"COVID vaccine","value":"COVIDVACCINE","color":"#006078","ripple":false},on:{"change":function($event){return _vm.onCheckboxGroupClicked()}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.addRemoveBookingTypes('COVIDVACCINE');
                  _vm.onCheckboxGroupClicked();}},model:{value:(_vm.bookingTypes),callback:function ($$v) {_vm.bookingTypes=$$v},expression:"bookingTypes"}}):_vm._e(),(_vm.showCovidUnder12Vaccine)?_c('v-checkbox',{staticClass:"ml-4 px-8 black--text",attrs:{"label":"COVID vaccine","value":"COVIDVACCINEUNDER12","color":"#006078","ripple":false},on:{"change":function($event){return _vm.onCheckboxGroupClicked()}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.addRemoveBookingTypes('COVIDVACCINE');
                  _vm.onCheckboxGroupClicked();}},model:{value:(_vm.bookingTypes),callback:function ($$v) {_vm.bookingTypes=$$v},expression:"bookingTypes"}}):_vm._e()],1),_c('v-btn',{staticClass:"v-btn--primary",attrs:{"disabled":!_vm.bookingTypes.length},on:{"click":_vm.onAfterContinueClicked}},[_vm._v(" Continue ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }