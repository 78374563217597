<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <v-row>
          <v-col cols="12" lg="6">
            <h3 class="mb-8 home-header-3">
              Are you a NI resident and currently registered with a GP in NI?
            </h3>
            <v-radio-group
              v-model="ifResident"
              v-on:change="onRadioGroupClicked"
              class="mb-4"
            >
              <v-radio
                v-on:keyup.enter="
                  ifResident = 'Yes';
                  onRadioGroupClicked();
                "
                label="Yes"
                value="Yes"
                color="#006078"
                class="ml-4 px-8 py-4 black--text"
                :ripple="false"
              ></v-radio>
              <v-radio
                v-on:keyup.enter="
                  ifResident = 'No';
                  onRadioGroupClicked();
                "
                label="No"
                value="No"
                color="#006078"
                class="ml-4 px-8 py-4 black--text"
                :ripple="false"
              ></v-radio>
            </v-radio-group>
            <v-btn
              @click="onAfterContinueClicked"
              class="v-btn--primary"
              :disabled="!ifResident"
            >
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import BackButton from '@/components/BackButton';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepResident',
  data: () => ({
    ifResident: null
  }),
  computed: {
    ...mapGetters(['getHealthAndSocialCareWorker', 'getFlowName'])
  },
  methods: {
    onRadioGroupClicked() {
      this.$store.commit('setIfResident', this.ifResident === 'Yes');
    },
    onAfterContinueClicked() {
      if (this.ifResident === 'Yes') {
        this.$router.push('/booking/booking-types-2').catch(() => {});
      } else {
        this.$router.push('/booking/not-eligible-resident').catch(() => {});
      }
      this.$gtag.event( `Residency answered` + `${this.getFlowName}`, {})
    }
  }
});
</script>
