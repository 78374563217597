<template>
  <v-container fluid>
    <div>
      <v-container>
        <v-row class="ma-0">
          <v-col lg="10">
            <div class="d-inline-flex pa-2 pt-0 mt-4">
            <v-btn v-if="token" @click="redirectToBooking" class="mr-2">
              Proceed to booking
            </v-btn>
            <v-btn
              v-if="token && ifEligibleForCancellation"
              @click="cancellationDialog = true"
              class="mr-2"
            >
              Cancellation
            </v-btn>
            </div>
          </v-col>
          <v-col lg="2">
            <div class="d-inline-flex justify-end pa-2 pt-0 mt-4">
              <v-btn @click="redirectToAuth">
                {{ token ? 'Logout' : 'Login' }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-dialog v-model="cancellationDialog" max-width="960px">
      <v-card>
        <v-card-title class="headline">
          Cancellation
          <v-spacer />
          <v-btn
            @click="
              appointments = null;
              cancelling = false;
              dob = '';
              reference = '';
              lastName = '';
              mobile = '';
              email = '';
            "
            v-if="appointments"
            class="mr-2"
          >
            Start over
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container v-if="!appointments" style="max-width: 360px;">
            <v-form @submit="search">
              <v-row>
                <v-text-field
                  v-model="reference"
                  label="Appointment reference"
                  outlined
                />
              </v-row>
              <v-row>
                <v-dialog ref="dialog" v-model="birthdayModal" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="dobFormatted"
                      ref="dob"
                      label="Date of birth"
                      min="1900-01-01"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      light
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="dob"
                    scrollable
                    :max="new Date(2009, 7, 1).toISOString().substr(0, 10)"
                    min="1901-01-01"
                    @input="birthdayModal = false"
                  ></v-date-picker>
                </v-dialog>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="lastName"
                  label="Last name"
                  outlined
                  :disabled="reference !== ''"
                />
              </v-row>
              <v-row>
                <v-text-field
                  v-model="mobile"
                  label="Mobile"
                  outlined
                  :disabled="reference !== '' || email !== ''"
                />
              </v-row>
              <v-row>
                <v-text-field
                  v-model="email"
                  label="Email"
                  outlined
                  :disabled="reference !== '' || mobile !== ''"
                />
              </v-row>
              <v-row>
                <v-spacer />
                <v-btn
                  class="mr-2"
                  @click="
                    cancellationDialog = false;
                    appointments = null;
                  "
                >
                  Close
                </v-btn>
                <v-btn :loading="searching" @click="search">
                  Search
                </v-btn>
              </v-row>
            </v-form>
          </v-container>
          <v-container v-if="appointments" fluid>
            <v-row>
              <v-col
                cols="12"
                lg="6"
                v-for="a in appointments"
                :key="a.appointment_procedure_id"
              >
                <v-card class="mb-2">
                  <v-card-title class="px-7">
                    <v-row>
                      <div class="text-h6">
                        {{ a.first_name }} {{ a.last_name }}
                      </div>
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <table>
                      <tbody>
                        <tr>
                          <td>Address:</td>
                          <td>
                            {{ a.address_1 }} {{ a.address_2 }} {{ a.town }}
                            {{ a.post_code }}
                          </td>
                        </tr>
                        <tr>
                          <td>Date of birth:</td>
                          <td>{{ formatDate(a.date_of_birth) }}</td>
                        </tr>
                        <tr>
                          <td>Mobile:</td>
                          <td>{{ a.mobile_number_1 }}</td>
                        </tr>
                        <tr>
                          <td>Email:</td>
                          <td>{{ a.email_address_1 }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <v-divider />
                    <v-row class="font-weight-bold mt-2 mb-1 text-subtitle-1">
                      <v-avatar
                        :color="
                          a.appointment_status_color_code.replace('^', '#')
                        "
                        rounded
                        size="24px"
                        class="ml-4 mr-2"
                      >
                      </v-avatar>
                      {{ a.appointment_status_name }}
                    </v-row>
                    <v-divider />
                    <div class="text-subtitle-1">{{ a.procedure_name }}</div>
                    <table>
                      <tbody>
                        <tr>
                          <td>Centre:</td>
                          <td>{{ a.clinic_name }}</td>
                        </tr>
                        <tr>
                          <td>Date:</td>
                          <td>
                            {{ formatDate(a.appointment_date) }}
                            {{ a.start_time ? a.start_time.substr(0, 5) : '' }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      v-if="a.appointment_status_code === 'C'"
                      @click="cancelAppointment(a.appointment_id)"
                      :loading="cancelling"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="4000" light>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<style>
.v-input__prepend-inner {
  margin-top: 11px !important;
}
.v-alert__icon {
  display: none !important;
}
</style>
<style scoped>
.v-btn {
  border-radius: 10px !important;
  background: #006078 !important;
  color: #ffffff !important;
  box-shadow: none !important;
}
</style>

<script>
import auth from '@/helpers/auth';
import config from '@/config';

export default {
  name: 'Admin',
  components: { },
  data() {
    return {
      errorMessage: '',
      birthdayModal: false,
      cancellationDialog: false,
      appointments: null,
      dob: '',
      reference: '',
      lastName: '',
      mobile: '',
      email: '',
      searching: false,
      snackbar: false,
      snackbarText: '',
      cancelling: false,
      ifEligibleForCancellation: false
    };
  },
  computed: {
    token() {
      if (!auth.verifySavedToken()) {
        return false;
      }
      return localStorage.getItem(config.authTokenName);
    },
    dobFormatted() {
      if (!this.dob) {
        return '';
      }
      const parts = this.dob.split('-');
      return parts[2] + '/' + parts[1] + '/' + parts[0];
    },
  },
  watch: {
    birthdayModal(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    }
  },
  mounted() {
    this.$emit('hideAppBar');
    this.checkIfEligibleForCancellation();
  },
  methods: {
    redirectToAuth() {
      localStorage.removeItem(config.authTokenName);
      auth.redirectToAuth();
    },
    redirectToBooking() {
      this.$router.push('/booking/clinics').catch(() => {});
      this.$emit('hideAppBar', true);
    },
    showError(message) {
      this.errorMessage =
        typeof message !== 'undefined'
          ? message
          : 'Sorry, something went wrong, please try again.';
      setTimeout(() => {
        this.errorMessage = '';
      }, 4000);
    },
    search() {
      if (this.reference.trim() && this.dob) {
        this.doSearch();
      } else if (
        this.lastName.trim() &&
        this.dobFormatted &&
        (this.mobile.trim() || this.email.trim())
      ) {
        this.doSearch();
      } else {
        this.showSnackbar('Please fill in required information');
      }
    },
    doSearch() {
      this.searching = true;
      const url = config.adminApiUrl + '/otp/';
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem(config.authTokenName)
        },
        redirect: 'follow',
        body: JSON.stringify({
          action: 'search',
          reference: this.reference,
          dob: this.dobFormatted,
          lastName: this.lastName,
          mobile: this.mobile,
          email: this.email
        })
      })
        .then((response) => {
          if (response.status == 401) {
            this.redirectToAuth();
          } else {
            return response.json();
          }
        })
        .then((response) => {
          if (response.error) {
            this.showSnackbar(response.error);
            return;
          }
          this.appointments = response.response.appointments;
        })
        .catch((error) => {
          this.searching = false;
          this.showSnackbar(error);
        })
        .finally(() => {
          this.searching = false;
        });
    },
    cancelAppointment(appointmentId) {
      this.cancelling = true;
      const url = config.adminApiUrl + '/otp/';
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem(config.authTokenName)
        },
        redirect: 'follow',
        body: JSON.stringify({
          action: 'cancel',
          appointmentId: appointmentId
        })
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            this.showSnackbar(response.error);
            return;
          }
          this.appointments = response.response.appointments;
        })
        .catch((error) => {
          this.showSnackbar(error);
        })
        .finally(() => {
          this.cancelling = false;
        });
    },
    checkIfEligibleForCancellation() {
      const url = config.adminApiUrl + '/otp/';
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.token
        },
        redirect: 'follow',
        body: JSON.stringify({
          action: 'checkIfEligible'
        })
      })
        .then((response) => response.json())
        .then((response) => {
          this.checkingEligibility = false;
          if (response.status !== 'S' || !response.response.ifEligible) {
            this.ifEligibleForCancellation = false;
          }
          else {
            this.ifEligibleForCancellation = true;
          }      
        })
        .catch(() => {
            this.ifEligibleForCancellation = false;
        });
    },
    showSnackbar(text) {
      this.snackbarText = text;
      this.snackbar = true;
    },
    formatDate(date) {
      const parts = date.substr(0, 10).split('-');
      if (parts.length === 3) {
        return parts.reverse().join('/');
      }
      return '';
    }
  }
};
</script>

<style scoped></style>
