<template>
  <v-row>
    <v-col cols="7" lg="4">
      <label for="dob-days-part" class="d-inline-flex mb-2 home-input-label font-weight-bold"
        >Day</label
      >
      <v-text-field
        v-model="day"
        @input="limitDays()"
        :disabled="disabled"
        id="dob-days-part"
        ref="day"
        name="day"
        type="number"
        class="mb-0 date-input"
        autocomplete="off"
        light
        outlined
      ></v-text-field>
    </v-col>
    <v-col cols="7" lg="4">
      <label for="dob-months-part" class="d-inline-flex mb-2 home-input-label font-weight-bold"
        >Month</label
      >
      <v-select
        v-model="month"
        :items="months"
        item-text="text"
        item-value="value"
        :disabled="disabled"
        @change="emitEvents()"
        id="dob-months-part"
        ref="month"
        name="month"
        class="mb-0 date-input"
        light
        outlined
      >
      </v-select>
    </v-col>
    <v-col cols="7" lg="4">
      <label for="dob-years-part" class="d-inline-flex mb-2 home-input-label font-weight-bold"
        >Year</label
      >
      <v-text-field
        v-model="year"
        @input="limitYears()"
        :disabled="disabled"
        id="dob-years-part"
        ref="year"
        name="year"
        type="number"
        class="mb-0 date-input"
        autocomplete="off"
        light
        outlined
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<style scoped>
.date-input {
  width: 100%;
}
</style>

<script>
import Vue from 'vue';
import moment from 'moment/moment';
import { calculateAge } from '@/helpers/ageCalculation.ts';

export default Vue.extend({
  components: {},
  props: ['defaultDateOfBirth', 'disabled'],
  name: 'DateOfBirth',
  data: () => ({
    day: null,
    month: null,
    year: null
  }),
  watch: {
    defaultDateOfBirth(value) {
      this.day = moment(value).format('DD');
      this.month = moment(value).format('MM');
      this.year = moment(value).format('YYYY');
    }
  },
  computed: {
    years() {
      const currentYear = new Date().getFullYear();
      const howManyYears = 120;
      return [...Array(howManyYears).keys()].map(
        (x) => currentYear - howManyYears + x + 1
      );
    },
    months() {
      return [...Array(12).keys()].map((x) => {
        let index = (x + 1) + '';
        index = index.padStart(2, '0');
        return {
          text: moment(index, 'MM').format('MMMM'),
          value: index
        };
      });
    },
    days() {
      const daysInMonth = new Date(this.year, this.month, 0).getDate();
      return Array.from({ length: daysInMonth }, (_, i) => i + 1);
    },
    dateOfBirth() {
      if (this.dateOfBirthValid) {
        return new Date(this.year, this.month - 1, this.day);
      } else {
        return null;
      }
    },
    dateOfBirthAge() {
      return calculateAge(this.dateOfBirth);
    },
    dateOfBirthValid() {
      //if valid year
      if (!this.years.includes(parseInt(this.year))) {
        return false;
      }
      //if month valid
      if (!this.month) {
        return false;
      }
      //if valid year
      if (!this.days.includes(parseInt(this.day))) {
        return false;
      }
      return true;
    },
    dateOfBirthEntered() {
      //"3" wait until full year i typed.
      return this.day && this.month && this.year && this.year.length > 3;
    }
  },
  methods: {
    limitDays() {
      const MIN = 0;
      const MAX = 31;
      Vue.nextTick(() => {
        if (this.day && Number(this.day) < MIN) {
          this.day = MIN;
        }
        if (this.day && Number(this.day) > MAX) {
          this.day = MAX;
        }
        this.emitEvents();
      });
    },
    limitYears() {
      const MIN = 0;
      const MAX = new Date().getFullYear();
      Vue.nextTick(() => {
        if (this.year && Number(this.year) < MIN) {
          this.year = MIN;
        }
        if (this.year && Number(this.year) > MAX) {
          this.year = MAX;
        }
        this.emitEvents();
      });
    },
    emitEvents() {
      this.$emit('dateChanged', this.dateOfBirth);
      this.$emit('dateEntered', this.dateOfBirthEntered);
    }
  }
});
</script>
