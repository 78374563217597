<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <v-row>
          <v-col cols="12" lg="6">
            <h3 class="mb-8 home-header-3">
              As you are pregnant, you are not eligible to receive an MMR vaccine until after your baby has been born. Please discuss this with your midwife or GP.<br /><br />
              If you require any further information please contact
              <a style="color: #007e93;" href="mailto:pha.immunisation@hscni.net">pha.immunisation@hscni.net</a>.
            </h3>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import BackButton from '@/components/BackButton';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepPregnancy',
  data: () => ({
    ifResident: null
  }),
  computed: {
  },
  methods: {
    onRadioGroupClicked() {
    },
    onAfterContinueClicked() {
    }
  },
  mounted(){
    this.$gtag.event('MMR flow not eligible - pregnancy')
  }
});
</script>
