<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" class="pt-16">
        <v-container>
          <back-button @click.native="$router.go(-1)"></back-button>
          <v-row>
            <v-col cols="12" lg="6">
              <h3 class="mb-8 home-header-3">
                Which MMR dose are you booking?
              </h3>
              <v-radio-group
                v-model="selectedBookingTypesMMRDose"
                v-on:change="onRadioGroupClicked"
                class="mb-4"
              >
                <v-radio
                  v-on:keyup.native.enter="
                    selectedBookingTypesMMRDose = MMR_CODE_DOSE_1;
                    onRadioGroupClicked();
                  "
                  label="Dose 1"
                  color="#006078"
                  class="ml-4 px-8 py-4 black--text"
                  :value=MMR_CODE_DOSE_1
                  :ripple="false"
                ></v-radio>
                <v-radio
                  v-on:keyup.native.enter="
                    selectedBookingTypesMMRDose = MMR_CODE_DOSE_2;
                    onRadioGroupClicked();
                  "
                  label="Dose 2"
                  color="#006078"
                  class="ml-4 px-8 py-4 black--text"
                  :value=MMR_CODE_DOSE_2
                  :ripple="false"
                ></v-radio>
                <v-radio
                  v-on:keyup.native.enter="
                    selectedBookingTypesMMRDose = MMR_CODE_DOSE_NOT_SURE;
                    onRadioGroupClicked();
                  "
                  label="Not sure"
                  color="#006078"
                  class="ml-4 px-8 py-4 black--text"
                  :value=MMR_CODE_DOSE_NOT_SURE
                  :ripple="false"
                ></v-radio>
              </v-radio-group>
              <v-btn
                @click="onAfterContinueClicked"
                class="v-btn--primary"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';
import BackButton from '@/components/BackButton';

const MMR_CODE_DOSE_1 = 'MMR1';
const MMR_CODE_DOSE_2 = 'MMR2';
const MMR_CODE_DOSE_NOT_SURE = 'MMR_NOT_SURE';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepBookingTypesGroups',
  data: () => ({
    selectedBookingTypesMMRDose: null,
    MMR_CODE_DOSE_1: MMR_CODE_DOSE_1,
    MMR_CODE_DOSE_2: MMR_CODE_DOSE_2,
    MMR_CODE_DOSE_NOT_SURE: MMR_CODE_DOSE_NOT_SURE
  }),
  methods: {
    onRadioGroupClicked() {
      //we treat not sure as if dose 1
      const bookingTypes = [];
      bookingTypes.push(this.selectedBookingTypesMMRDose === MMR_CODE_DOSE_2 ? MMR_CODE_DOSE_2 : MMR_CODE_DOSE_1);

      this.$store.commit(
        'setBookingTypes',
        bookingTypes
      );
    },
    onAfterContinueClicked() {
      this.$gtag.event('MMR Dose selected')
      if (this.selectedBookingTypesMMRDose === this.MMR_CODE_DOSE_1 || this.selectedBookingTypesMMRDose === this.MMR_CODE_DOSE_NOT_SURE) {
        this.$router.push('/booking/eligible').catch(() => {});
       }
       else {
        this.$router.push('/booking/received-last-dose-mmr').catch(() => {});
       }
    }
  }
});
</script>
