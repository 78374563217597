<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <booking-details></booking-details>
        <h3 class="mb-6 home-header-3">
          About you
        </h3>
        <p class="mb-8 home-text-2">
          Fields marked with * are mandatory
        </p>
        <v-row>
          <v-col cols="12" lg="5">
            <label
              for="sex-dropdown"
              class="d-inline-flex mb-2 home-input-label font-weight-bold"
              >Sex *</label
            >
            <v-select
              v-model="sex"
              :items="sexArr"
              id="sex-dropdown"
              dense
              solo
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="5">
            <label
              for="ethnic-background-dropdown"
              class="d-inline-flex mb-2 home-input-label font-weight-bold"
              >Ethnic Background *</label
            >
            <v-select
              v-model="ethnicBackground"
              :items="ethnicBackgroundsArr"
              id="ethnic-background-dropdown"
              dense
              solo
            ></v-select>
          </v-col>
        </v-row>
        <v-fade-transition hide-on-leave>
          <v-row v-if="showEthnicBackgroundDescription">
            <v-col cols="12" lg="5">
              <label
                for="ethnic-background-description-input"
                class="d-inline-flex mb-2 home-input-label font-weight-bold"
                >Ethnic Background Description *</label
              >
              <v-text-field
                v-model="ethnicBackgroundDescription"
                id="ethnic-background-description-input"
                ref="ethnicBackgroundDescription"
                name="ethnicBackgroundDescription"
                light
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-fade-transition>
        <v-row>
          <v-col cols="12" lg="5">
            <label
              for="health-and-care-number-input"
              class="d-inline-flex mb-2 home-input-label font-weight-bold"
              >Health & Care Number</label
            >
            <v-text-field
              v-model="healthAndCareNumber"
              @input="limitHCNLength"
              id="health-and-care-number-input"
              ref="healthAndCareNumber"
              name="healthAndCareNumber"
              counter="10"
              light
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="getIfTrustPrimaryEmployer">
          <v-col cols="12" lg="5">
            <label
              for="rqia-code-input"
              class="d-inline-flex mb-2 home-input-label font-weight-bold"
              >Staff Number</label
            >
            <p>
              If you are non Trust staff, you should book an appointment with your local community pharmacy. You will not be able to have a vaccine at a Trust clinic.
            </p>
            <v-text-field
              v-model="staffNumber"
              @input="limitStaffNumberLength"
              id="staff-number-input"
              ref="staffNumber"
              name="staffNumber"
              counter="8"
              light
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          v-if="
            getJobFamily === 'Care Home Staff' ||
              (getDemographics || []).includes('Care Home Resident')
          "
        >
          <v-col cols="12" lg="5">
            <label
              for="rqia-code-input"
              class="d-inline-flex mb-2 home-input-label font-weight-bold"
              >RQIA code *</label
            >
            <v-text-field
              v-model="rqiaCode"
              id="rqia-code-input"
              ref="rqiaCode"
              name="rqiaCode"
              light
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-alert v-if="showError" color="#CD1041" class="mt-6 mb-6">
          <div class="px-12 py-10 white--text" v-html="errorMessage"></div>
        </v-alert>
        <v-btn
          @click="onAfterContinueClicked"
          :loading="updatingPatient"
          class="mt-8 v-btn--primary"
        >
          Continue
        </v-btn>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import auth from '@/helpers/auth';
import auth4Patients from '@/helpers/auth4Patients';
import config from '@/config';
import Vue from 'vue';
import moment from 'moment/moment';
import { mapGetters } from 'vuex';
import { patientToFormJSON } from '@/helpers/patient.ts';
import { validateHealthAndCareNumber, validateStaffNumber } from '@/helpers/validations.ts';
import { getSesKey } from '@/helpers/session.ts';
import BackButton from '@/components/BackButton';
import BookingDetails from './_BookingDetails';

export default Vue.extend({
  components: { BackButton, BookingDetails },
  name: 'BookingPatientDetails',
  data: () => ({
    showError: false,
    errorMessage: '',
    updatingPatient: false,
    sex: null,
    ethnicBackground: null,
    healthAndCareNumber: null,
    staffNumber: null,
    rqiaCode: null,
    ethnicBackgroundDescription: null,
    sexArr: ['Female', 'Male'],
    ethnicBackgroundsArr: [
      { text: 'White', value: 'White' },
      { text: 'Irish Traveller', value: 'Irish Traveller' },
      { text: '', value: '', header: 'Mixed / Multiple ethnic groups' },
      { text: 'White and Black Caribbean', value: 'White and Black Caribbean' },
      { text: 'White and Black African', value: 'White and Black African' },
      { text: 'White and Asian', value: 'White and Asian' },
      {
        text: 'Any other Mixed / Multiple ethnic background, please describe',
        value: 'Any other Mixed / Multiple ethnic background, please describe'
      },
      { text: '', value: '', header: 'Asian / Asian British' },
      { text: 'Indian', value: 'Indian' },
      { text: 'Pakistani', value: 'Pakistani' },
      { text: 'Bangladeshi', value: 'Bangladeshi' },
      { text: 'Chinese', value: 'Chinese' },
      {
        text: 'Any other Asian background, please describe',
        value: 'Any other Asian background, please describe'
      },
      {
        text: '',
        value: '',
        header: 'Black / African / Caribbean / Black British'
      },
      { text: 'African', value: 'African' },
      { text: 'Caribbean', value: 'Caribbean' },
      {
        text:
          'Any other Black / African / Caribbean background, please describe',
        value:
          'Any other Black / African / Caribbean background, please describe'
      },
      { text: '', value: '', header: 'Other ethnic group' },
      { text: 'Arab', value: 'Arab' },
      {
        text: 'Any other ethnic group, please describe',
        value: 'Any other ethnic group, please describe'
      }
    ]
  }),
  mounted: function() {
    this.$store.commit('setPatientMatched', null);
    this.setPatientDetails();
  },
  computed: {
    ...mapGetters([
      'getDemographics',
      'getIfTrustPrimaryEmployer',
      'getTrust',
      'getJobFamily',
      'getBookingApiURLs',
      'getAppointmentProcedure',
      'getClinic',
      'getSlot',
      'getPatient',
      'getFlowName'
    ]),
    accessToken4Admin() {
      if (!auth.verifySavedToken()) {
        return false;
      }
      return localStorage.getItem(config.authTokenName);
    },
    accessToken4Patients() {
      if (!auth4Patients.verifySavedToken()) {
        return false;
      }
      return localStorage.getItem(config.patientAuthTokenName);
    },
    slotDate() {
      return moment(this.getSlot.availability_date).format('D MMMM YYYY');
    },
    slotTime() {
      return moment(this.getSlot.slot_start, 'hh:mm:ss')
        .format('HH:mm a')
        .toUpperCase();
    },
    showEthnicBackgroundDescription() {
      return (this.ethnicBackground || '').toLowerCase().indexOf('other') > -1;
    }
  },
  methods: {
    limitHCNLength() {
      const LEN = 10;
      Vue.nextTick(() => {
        if (this.healthAndCareNumber && this.healthAndCareNumber.length > LEN) {
          this.healthAndCareNumber = this.healthAndCareNumber.substring(0, 10);
        }
      });
    },
    limitStaffNumberLength() {
      const LEN = 8;
      Vue.nextTick(() => {
        if (this.staffNumber && this.staffNumber.length > LEN) {
          this.staffNumber = this.staffNumber.substring(0, 8);
        }
      });
    },
    setPatientDetails() {
      if (this.getPatient.patientDetails) {
        this.sex = this.getPatient.patientDetails.sex;
        this.ethnicBackground = this.getPatient.patientDetails.ethnicBackground;
        this.ethnicBackgroundDescription = this.getPatient.patientDetails.ethnicBackgroundDescription;
        this.healthAndCareNumber = this.getPatient.patientDetails.healthAndCareNumber;
        this.rqiaCode = this.getPatient.patientDetails.rqiaCode;
        this.staffNumber = this.getPatient.patientDetails.staffNumber;
      }
    },
    savePatientDataToStore() {
      const patientDetails = this.getPatient.patientDetails || {};
      patientDetails.sex = this.sex;
      patientDetails.ethnicBackground = this.ethnicBackground;
      patientDetails.ethnicBackgroundDescription = this.ethnicBackgroundDescription;
      patientDetails.healthAndCareNumber = this.healthAndCareNumber;
      patientDetails.rqiaCode = this.rqiaCode;
      patientDetails.staffNumber = this.staffNumber;
      this.$store.commit('setPatientDetails', patientDetails);
    },
    validatePatientDetails() {
      let showError = false;
      let errorMessage = '';
      if (!this.sex) {
        showError = true;
        errorMessage += 'Please select sex.<br>';
      }
      if (!this.ethnicBackground) {
        showError = true;
        errorMessage += 'Please select ethnic background.<br>';
      }
      if (
        !this.ethnicBackgroundDescription &&
        this.showEthnicBackgroundDescription
      ) {
        showError = true;
        errorMessage += 'Please enter ethnic background description.<br>';
      }
      if (
        (this.healthAndCareNumber || '').trim() &&
        !validateHealthAndCareNumber(this.healthAndCareNumber)
      ) {
        showError = true;
        errorMessage += 'Please enter valid Health & Care Number.<br>';
      }
      if (
        !this.rqiaCode &&
        ((this.getDemographics || []).includes('Care Home Resident') ||
          this.getJobFamily === 'Care Home Staff')
      ) {
        showError = true;
        errorMessage += 'Please enter RQIA code.<br>';
      }
      if (this.getIfTrustPrimaryEmployer && !validateStaffNumber(this.staffNumber)) {
        showError = true;
        errorMessage += 'Please enter valid staff number.<br>';
      }
      this.showError = showError;
      this.errorMessage = errorMessage;
    },
    onAfterContinueClicked() {
      this.validatePatientDetails();
      if (this.showError) {
        return;
      }
      this.updatePatient().then( () => {
        this.$gtag.event(`Patient details 2 filled` +
         `${this.getFlowName}`,
          {})
      });
    },
    async updatePatient() {
      this.updatingPatient = true;
      //update store via commit
      this.savePatientDataToStore();
      let result = null;
      try {
        const resultFetch = await fetch(this.getBookingApiURLs.updatePatientUrl, {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'Key': getSesKey()
          },
          redirect: 'follow',
          body: patientToFormJSON(this.getPatient, this.accessToken4Admin || this.accessToken4Patients)
        });
        result = await resultFetch.json();
      }
      catch {
        this.updatingPatient = false;
        this.showError = true;
        this.errorMessage = 'Could not update patient data. Please try again.';
      }

      this.updatingPatient = false;
      if (result.status === 'S') {
        this.showError = false;
        this.errorMessage = '';
        this.$router.push('/booking/patient-summary').catch(() => {});
      } else {
        this.showError = true;
        this.errorMessage =
          'Could not update patient data. Please try again.';
      }
    }
  }
});
</script>
