<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <h3 class="mb-8 home-header-3">
          Are you a
        <v-menu
          v-model="showTooltip"
          open-on-hover
          bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <u>frontline</u>
            </span>
          </template>
    
          <div class="pa-4">
              <p class="text-right">
                <v-icon @click="showTooltip=false" color="white" large>
                  mdi-close-circle-outline
                </v-icon>
              </p>
              <p class="text-body-1 white--text">
                <strong>Frontline</strong>:<br>
                <ul style="list-style-type:circle">
                  <li>Staff involved in Direct Patient or Client Care;</li>
                  <li>Other staff in Non-Clinical contact with Patients or Clients;</li>
                  <li>Laboratory or Pathology Staff</li>
                </ul>
              </p>
              <p class="mb-0 text-body-1 white--text">
                <strong>Non-frontline</strong>:
                <ul style="list-style-type:circle">
                  <li>Any member of staff Not involved in face to face or social contact with Patients or Clients during the performance of their duties.</li>
                </ul>
              </p>
            </div>
        </v-menu>
        Health and Social Care worker?
        </h3>
        <v-radio-group
          v-model="frontlineHealthAndSocialCareWorker"
          v-on:change="onRadioGroupClicked"
          class="mb-4"
        >
          <v-radio
            v-on:keyup.enter="
              frontlineHealthAndSocialCareWorker = 'Yes';
              onRadioGroupClicked();
            "
            label="Yes"
            value="Yes"
            color="#006078"
            class="ml-4 px-8 py-4 black--text"
            :ripple="false"
          ></v-radio>
          <v-radio
            v-on:keyup.enter="
              frontlineHealthAndSocialCareWorker = 'No';
              onRadioGroupClicked();
            "
            label="No"
            value="No"
            color="#006078"
            class="ml-4 px-8 py-4 black--text"
            :ripple="false"
          ></v-radio>
        </v-radio-group>
        <v-btn
          @click="onAfterContinueClicked"
          class="v-btn--primary"
          :disabled="!frontlineHealthAndSocialCareWorker"
        >
          Continue
        </v-btn>
      </v-container>
    </v-col>
  </v-row>
</template>

<style scoped>
.v-menu__content {
  background-color: #006078 !important;
  opacity: 1 !important;
  pointer-events: auto !important;
}
</style>

<script>
import Vue from 'vue';
import BackButton from '@/components/BackButton';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepFrontlineHealthAndSocialCareWorker',
  data: () => ({
    frontlineHealthAndSocialCareWorker: null,
    showTooltip: false
  }),
  computed: {
    ...mapGetters(['getFlowName'])
  },
  methods: {
    onRadioGroupClicked() {
      this.$store.commit(
        'setFrontlineHealthAndSocialCareWorker',
        this.frontlineHealthAndSocialCareWorker === 'Yes'
      );
    },
    onAfterContinueClicked() {
      this.$gtag.event(`Frontline social care worker question answered` + `${this.getFlowName}`, {})
      this.$router.push('/booking/job-family').catch(() => {});
    }
  }
});
</script>
