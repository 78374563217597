<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <booking-details></booking-details>
        <h3 class="mb-6 home-header-3">
          Your details
        </h3>
        <p class="mb-8 home-text-2">
          Fields marked with * are mandatory
        </p>
        <v-row>
          <v-col cols="12" lg="5">
            <label
              for="first-name-input"
              class="d-inline-flex mb-2 home-input-label font-weight-bold"
              >First Name *</label
            >
            <v-text-field
              v-model="firstName"
              id="first-name-input"
              ref="firstName"
              name="firstName"
              light
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="5">
            <label
              for="family-name-input"
              class="d-inline-flex mb-2 home-input-label font-weight-bold"
              >Family Name *</label
            >
            <v-text-field
              v-model="familyName"
              id="family-name-input"
              ref="familyName"
              name="familyName"
              light
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="5">
            <label class="d-flex mb-2 home-input-label font-weight-bold"
              >Date of Birth *</label
            >
            <label
              class="d-flex mb-2 home-input-label font-weight-normal"
              style="color: rgba(34, 34, 34, 0.5);"
            >
              For example, 27 March 2007
            </label>
            <date-of-birth
              @dateChanged="onDateOfBirthChanged"
              v-bind:defaultDateOfBirth="dateOfBirth"
              v-bind:disabled="getDateOfBirth !== null"
            ></date-of-birth>
          </v-col>
        </v-row>
        <v-alert v-if="showError" color="#CD1041" class="mt-6 mb-6">
          <div class="px-12 py-10 white--text" v-html="errorMessage"></div>
        </v-alert>
        <v-btn @click="onAfterContinueClicked" class="mt-8 v-btn--primary">
          Continue
        </v-btn>
      </v-container>
    </v-col>
  </v-row>
</template>

<style scoped></style>

<script>
import Vue from 'vue';
import moment from 'moment/moment';
import { mapGetters } from 'vuex';
import BackButton from '@/components/BackButton';
import BookingDetails from './_BookingDetails';
import DateOfBirth from '@/components/controls/DateOfBirth';

export default Vue.extend({
  components: { BackButton, BookingDetails, DateOfBirth },
  name: 'BookingPatientDetails',
  data: () => ({
    showError: false,
    errorMessage: '',
    firstName: null,
    familyName: null,
    dateOfBirth: null
  }),
  mounted: function() {
    this.$store.commit('setPatientMatched', null);
    this.setPatientDetails();
  },
  computed: {
    ...mapGetters([
      'getDateOfBirth',
      'getBookingApiURLs',
      'getAppointmentProcedure',
      'getClinic',
      'getSlot',
      'getPatient',
      'getFlowName'
    ]),
    slotDate() {
      return moment(this.getSlot.availability_date).format('D MMMM YYYY');
    },
    slotTime() {
      return moment(this.getSlot.slot_start, 'hh:mm:ss')
        .format('HH:mm a')
        .toUpperCase();
    }
  },
  methods: {
    setPatientDetails() {
      if (this.getPatient.patientDetails) {
        this.firstName = this.getPatient.patientDetails.firstName;
        this.familyName = this.getPatient.patientDetails.familyName;
        this.dateOfBirth = this.getPatient.patientDetails.dateOfBirth;
      }
      if (!this.dateOfBirth) {
        this.dateOfBirth = this.getDateOfBirth;
      }
    },
    savePatientDetails() {
      const patientDetails = this.getPatient.patientDetails || {};
      patientDetails.firstName = this.firstName;
      patientDetails.familyName = this.familyName;
      patientDetails.dateOfBirth = this.dateOfBirth;
      this.$store.commit('setPatientDetails', patientDetails);
    },
    validatePatientDetails() {
      let showError = false;
      let errorMessage = '';
      if (!this.firstName || !this.firstName.trim()) {
        showError = true;
        errorMessage += 'Please enter first name.<br>';
      }
      if (!this.familyName || !this.familyName.trim()) {
        showError = true;
        errorMessage += 'Please enter family name.<br>';
      }
      if (!this.dateOfBirth) {
        showError = true;
        errorMessage += 'Please enter correct date of birth.<br>';
      }
      this.showError = showError;
      this.errorMessage = errorMessage;
    },
    onDateOfBirthChanged(date) {
      this.dateOfBirth = date;
    },
    onAfterContinueClicked() {
      this.validatePatientDetails();
      if (this.showError) {
        return;
      }
      this.$gtag.event(`Patient details 1 filled` +
       `${this.getFlowName}`,
        {})
      this.savePatientDetails();
      this.$router.push('/booking/patient-address').catch(() => {});
    }
  }
});
</script>
