<template>
  <div>
    <v-container
      fluid
      class="ma-0 pa-0 mb-16 jumbo-header"
      style="background-color: #F9F9F9;"
    >
      <v-row justify="center" align="center" class="fill-height">
        <v-col class="pt-8 pb-16">
          <v-container>
            <back-button
              class="my-8"
              @click.native="$router.go(-1)"
            ></back-button>
            <v-row>
              <v-col cols="12" lg="9">
                <div v-if="!message">
                  <h2 class="mb-8 home-header-2">
                    Unfortunately, you are not eligible to book your vaccination
                    online.
                  </h2>
                  <p class="mb-4 home-header-3">
                    You will be able to book your vaccination when you are
                    invited to do so.
                  </p>
                  <p class="mb-4 home-header-3">
                    Thank you for your patience during this time.
                  </p>
                  <a
                    class="mb-8 home-header-3"
                    style="color: #007e93;"
                    href="https://covid-19.hscni.net/get-vaccinated/"
                    target="_blank"
                    >Read more about vaccination eligibility</a
                  >
                </div>
                <v-row v-if="message === 'no-letter'">
                  <v-col cols="12" lg="9">
                    <h2 class="mb-8 home-header-2">
                      Unfortunately you are not eligible to book using this
                      service - only children with a letter from their
                      doctor/clinician are allowed to book at this time.
                    </h2>
                  </v-col>
                </v-row>
                <div v-if="message == 1">
                  <h2 class="mb-8 home-header-2" style="line-height: 3.75rem">
                    Unfortunately you are not eligible to book using this
                    service at this time. Please return when 12 weeks have
                    passed since your last COVID vaccination.
                  </h2>
                </div>
                <div v-if="message == 2">
                  <h2 class="mb-8 home-header-2" style="line-height: 3.75rem">
                    Unfortunately you are not eligible to book using this
                    service at this time. Please return when 8 weeks have passed
                    passed since your last COVID vaccination.
                  </h2>
                </div>
                <div v-if="message == 3">
                  <h2 class="mb-8 home-header-2" style="line-height: 3.75rem">
                    Unfortunately you are not eligible to book using this
                    service at this time. Please return when 3 months have passed
                    passed since your last COVID vaccination.
                  </h2>
                </div>
                <div v-if="message == 4">
                  <h2 class="mb-8 home-header-2" style="line-height: 3.75rem">
                    Unfortunately you are not eligible to book using this
                    service at this time. Please return when 6 months have
                    passed since your last COVID vaccination.
                  </h2>
                </div>
                <div v-if="message == 5">
                  <h2 class="mb-8 home-header-2" style="line-height: 3.75rem">
                    Unfortunately you are not eligible to book using this
                    service at this time. Please return when 3 months have
                    passed since your last COVID vaccination.
                  </h2>
                </div>
                <div v-if="message == 6">
                  <h2 class="mb-8 home-header-2" style="line-height: 3.75rem">
                    Unfortunately you are not eligible to book using this
                    service at this time. Please return when 3 months has passed
                    passed since your last COVID vaccination.
                  </h2>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from 'vue';
import BackButton from '@/components/BackButton';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepNotEligibleAge',
  computed: {
    message() {
      return this.$route.query.m;
    }
  }
});
</script>
