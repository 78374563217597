<template>
  <v-container fluid class="ma-0 pa-0" ref="pagetop" style="height: calc(100vh - 100px);">
      <iframe v-if="loadIframe" style="height: 100%;width: 100%;border:0;" :src="portalUrl" border="0" />
  </v-container>
</template>
<script>

import { detect } from 'detect-browser';
import config from '@/config';
export default {
  name: 'Portal',
  components: {
  },
  data: () => ({
    config: config,
    loadIframe: true
  }),
  methods: {
  },
  mounted() {
    const browser = detect();
    if (browser && (browser.name === 'safari' || browser.name === 'ios' || browser.name === 'ios-webview')) {
      this.loadIframe = false;
      window.location = this.portalUrl;
    }
  },
  computed: {
    portalUrl() {
      if (this.$route.params.appointmentId) {
        return this.config.portalUrl + "?username=" + this.$route.params.appointmentId;
      }

      return this.config.portalUrl
    }
  }
}
</script>
