<template>
  <div>
    <v-container
      v-if="
        !showSeasonalFlu &&
        !showCovidVaccine &&
        !showCovidUnder12Vaccine
      "
      fluid
      class="ma-0 pa-0 mb-16 jumbo-header"
      style="background-color: #F9F9F9;"
    >
      <v-row justify="center" align="center" class="fill-height">
        <v-col class="pt-8 pb-16">
          <v-container>
            <back-button
              class="my-8"
              @click.native="$router.go(-1)"
            ></back-button>
            <v-row>
              <v-col cols="12" lg="9">
                <h2 class="mb-8 home-header-2">
                  Unfortunately you are not eligible to book using this service
                  at this time. You may be eligible to book at a local pharmacy
                  or your GP.
                </h2>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-row v-else justify="center">
      <v-col cols="12" class="pt-16">
        <v-container>
          <back-button @click.native="$router.go(-1)"></back-button>
          <v-row>
            <v-col cols="12" lg="6">
              <h3 class="mb-8 home-header-3">
                Which vaccinations are you booking today?
              </h3>
              <div class="mb-8">
                <v-checkbox
                  v-if="showSeasonalFlu"
                  v-model="bookingTypes"
                  v-on:change="onCheckboxGroupClicked()"
                  v-on:keyup.native.enter="
                    addRemoveBookingTypes('FLU');
                    onCheckboxGroupClicked();
                  "
                  label="Seasonal Flu"
                  value="FLU"
                  color="#006078"
                  class="ml-4 px-8 black--text"
                  :ripple="false"
                ></v-checkbox>
                <v-checkbox
                  v-if="showCovidVaccine && !showCovidUnder12Vaccine"
                  v-model="bookingTypes"
                  v-on:change="onCheckboxGroupClicked()"
                  v-on:keyup.native.enter="
                    addRemoveBookingTypes('COVIDVACCINE');
                    onCheckboxGroupClicked();
                  "
                  label="COVID vaccine"
                  value="COVIDVACCINE"
                  color="#006078"
                  class="ml-4 px-8 black--text"
                  :ripple="false"
                ></v-checkbox>
                <v-checkbox
                  v-if="showCovidUnder12Vaccine"
                  v-model="bookingTypes"
                  v-on:change="onCheckboxGroupClicked()"
                  v-on:keyup.native.enter="
                    addRemoveBookingTypes('COVIDVACCINE');
                    onCheckboxGroupClicked();
                  "
                  label="COVID vaccine"
                  value="COVIDVACCINEUNDER12"
                  color="#006078"
                  class="ml-4 px-8 black--text"
                  :ripple="false"
                ></v-checkbox>
              </div>
              <v-btn
                @click="onAfterContinueClicked"
                class="v-btn--primary"
                :disabled="!bookingTypes.length"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import BackButton from '@/components/BackButton';
import moment from 'moment/moment';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepBookingTypes',
  data: () => ({
    bookingTypes: []
  }),
  computed: {
    ...mapGetters([
      'getDemographics',
      'getDateWhenLastDoseReceived',
      'getFrontlineHealthAndSocialCareWorker',
      'getIfCovidOrFluLetter',
      'getDateOfBirth',
      'getAge',
      'getFlowName'
    ]),
    
    showSeasonalFlu() {
      if (
        this.getAge >= 65 || 
        this.getIfCovidOrFluLetter ||
        this.getFrontlineHealthAndSocialCareWorker ||
        (
            this.getDemographics.includes('Carer') ||
          (
            this.getDemographics.includes('Close Contact') &&
            this.getAge >= 18
          ) || 
          this.getDemographics.includes('HSC Worker') ||
          this.getDemographics.includes('Pregnant') ||
          this.getDemographics.includes('Care Home Resident') ||
          (
            this.getDemographics.includes('At Risk') &&
            this.getAge >=16 
          ) ||
          this.getDemographics.includes('Bird Culler') ||
          (
            this.getDemographics.includes('Immunosuppressed') && 
            this.getAge > 15
          )
        ) 
      ) {
        return true
      }



      return false
    },
    showCovidVaccine() {
      if (this.getAge >= 65) {
        return true
      }

      if(this.getIfCovidOrFluLetter){
        return true
      }

      if(
        this.getFrontlineHealthAndSocialCareWorker ||
        this.getDemographics.includes('Care Home Resident') ||
        (
          this.getDemographics.includes('At Risk') &&
          this.getAge >= 12
        ) ||
        this.getDemographics.includes('Pregnant') ||
        this.getDemographics.includes('Immunosuppressed') 
      ) {
        return true
      }

      return false
    },
    showCovidUnder12Vaccine() {
      return this.getAge >= 5 && 
        this.getAge < 12 && 
        (
          this.getIfCovidOrFluLetter ||
          this.getDemographics.includes('At Risk') || 
          this.getDemographics.includes('Immunosuppressed')
        )
    },
    ifMoreThan12WeeksSinceLastDose() {
      return moment()
        .startOf('day')
        .subtract(12, 'weeks') >= this.getDateWhenLastDoseReceived;
    },
    ifMoreThan8WeeksSinceLastDose() {
      return moment()
        .startOf('day')
        .subtract(8, 'weeks') >= this.getDateWhenLastDoseReceived;
    },
    ifMoreThan3MonthsSinceLastDose() {
      return moment()
        .startOf('day')
        .subtract(3, 'months') >= this.getDateWhenLastDoseReceived;
    },
    ifOlderThan74YearsAnd11Months() {
      const referenceDate = moment('1947-06-30', 'YYYY-MM-DD');
      return this.getDateOfBirth <= referenceDate;
    },
    ifOlderThan5YearsSince01_09() {
      const referenceDate = moment('2017-09-01', 'YYYY-MM-DD');
      return this.getDateOfBirth <= referenceDate;
    },
  },
  methods: {
    addRemoveBookingTypes(value) {
      const index = this.bookingTypes.indexOf(value);
      if (index > -1) {
        this.bookingTypes.splice(index, 1);
      } else {
        this.bookingTypes.push(value);
      }
    },
    saveBookingTypes() {
      this.$store.commit('setBookingTypes', this.bookingTypes);
    },
    onCheckboxGroupClicked() {
      this.saveBookingTypes();
    },
    onAfterContinueClicked() {
      this.$gtag.event(`Booking type selected` + `${this.getFlowName}`, {})
      this.$router.push('/booking/eligible').catch(() => {});
    }
  }
});
</script>
