<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <v-row>
          <v-col cols="12" lg="6">
            <h3 class="mb-8 home-header-3">
              Which trust do you work for?
            </h3>
            <v-select
              v-model="trust"
              :items="trusts"
              @change="onTrustSelected"
              id="trusts-dropdown"
              class="mb-6"
              dense
              solo
            >
              <template #item="{item}">
                <span class="notranslate">{{ item }}</span>
              </template></v-select
            >
            <v-btn
              @click="onAfterContinueClicked"
              class="v-btn--primary"
              :disabled="!trust"
            >
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import BackButton from '@/components/BackButton';
import trusts from '@/data/trusts';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepCovid',
  data: () => ({
    trust: null,
    trusts: trusts,
  }),
  computed: {
    ...mapGetters(['getFlowName'])
  },
  methods: {
    onTrustSelected() {
      this.$store.commit('setTrust', this.trust);
    },
    onAfterContinueClicked() {
      this.$router.push('/booking/frontline-social-care-worker').catch(() => {});
      this.$gtag.event(`Trust selected` + `${this.getFlowName}`)
    }
  }
});
</script>
