<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <v-row v-if="getSelectedBookingTypesGroup === 'MMR'">
          <v-col cols="12" lg="7">
            <h3 class="mb-8 home-header-3">
              Important guidelines to be followed when you arrive at an MMR vaccination clinic for your appointment.
            </h3>
            <h3 class="mb-8 home-header-3">
              Children and young adults aged 6-16 years will need to be accompanied by a parent or guardian. If you have received an invitation letter informing you that you need to receive an MMR vaccination, please bring this with you to your appointment.
            </h3>
            <h3 class="mb-8 home-header-3">
              Those aged 17 and above will need to bring photographic identification such as a passport or driving licence. If you have received an invitation letter informing you that you need to receive an MMR vaccination, please bring this with you to your appointment.
            </h3>
          </v-col>
        </v-row>
        <v-row v-else-if="getSelectedBookingTypesGroup === 'PERTUSSIS' || getSelectedBookingTypesGroup === 'RSV'">
          <v-col cols="12" lg="7">
            <h3 v-if="getSelectedBookingTypesGroup === 'PERTUSSIS'" class="mb-8 home-header-3">
              Important guidelines to be followed when you arrive at a Pertussis vaccination clinic for your appointment.
            </h3>
            <h3 v-else class="mb-8 home-header-3">
              Important guidelines to be followed when you arrive at an RSV vaccination clinic for your appointment.
            </h3>
            <h3 class="mb-8 home-header-3">
              Young adults up to 16 years old will need to be accompanied by a parent or guardian.
            </h3>
            <h3 class="mb-8 home-header-3">
              Those aged 17 and above will need to bring photographic identification such as a passport or driving licence. 
            </h3>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" lg="7">
            <h3 class="mb-8 home-header-3">
              When you arrive at the vaccination clinic, please tell the
              vaccination team if any of the following apply to you:
            </h3>
            <div class="mb-12 home-text-2">
              <ul class="ma-0 mp-0">
                <li class="mb-4" aria-label="">
                  Are allergic to any of the ingredients of the vaccine
                </li>
                <li class="mb-4" aria-label="">
                  Have ever had a severe allergic reaction or breathing problems
                  after any other medicine, vaccine or food in the past, or a
                  severe allergic reaction that could not be explained
                </li>
                <li class="mb-4" aria-label="">
                  Are, or could be, pregnant
                </li>
                <li class="mb-4" aria-label="">
                  Have a severe illness with a high fever
                </li>
                <li class="mb-4" aria-label="">
                  Have a problem with bleeding or bruising, or if you are taking
                  a blood thinning medication (anticoagulant)
                </li>
                <li class="mb-4" aria-label="">
                  Have a weakened immune system, or are on a medicine that
                  affects your immune system such as high-dose corticosteroids,
                  immunosuppressants or cancer medicines. If you have received a letter from your doctor, please bring it with you
                </li>
                <li class="mb-4" aria-label="">
                  If you need assistance
                </li>
              </ul>
              <p class="mb-4" aria-label="">
                If you are attending the vaccination clinic with someone who is
                unable to answer these questions, you must be prepared to answer
                these questions on their behalf. If you are unable to answer
                these questions we will be unable to give a vaccine and referral
                back to GP will be advised.
              </p>
            </div>
          </v-col>
        </v-row>
        <v-alert v-if="showError" color="#CD1041" class="mb-12">
          <div class="px-12 py-10 white--text" v-html="errorMessage"></div>
        </v-alert>
        <v-btn
          @click="onAfterContinueClicked"
          class="v-btn--primary"
          :loading="confirmingAppointment"
        >
          Book appointment
        </v-btn>
      </v-container>
      <v-dialog v-model="confirmingAppointment" width="500" persistent>
        <v-card :loading="true" class="mx-auto">
          <template slot="progress">
            <v-progress-linear
              color="#006078"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-text class="py-8 text-subtitle-1 text-center">
            Your appointment is being confirmed - please do not leave this page or you will need to start again.
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogIngredients" max-width="1000">
        <v-card>
          <v-card-title>
            <v-container>
              <v-row>
                <span style="color:#377a90;">
                  COVID-19 Vaccine Ingredient List
                </span>
                <v-spacer />
                <v-btn
                  fab
                  x-small
                  icon
                  outlined
                  color="primary"
                  @click="dialogIngredients = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <p style="color:#377a90;">
                <strong>What the Pfizer vaccine contains:</strong>
              </p>
              <ul>
                <li aria-label="">The active substance is BNT162b2 RNA</li>
                <li aria-label="">
                  This vaccine contains polyethylene glycol/macrogol (PEG) as
                  part of ALC-0159
                </li>
              </ul>
              <br>
              <p>The other ingredients are:</p>
              <ul class="mb-8">
                <li aria-label="">
                  ALC-0315 =
                  (4-hydroxybutyl)azanediyl)bis(hexane-6,1-diyl)bis(2-hexyldecanoate)
                </li>
                <li aria-label="">
                  ALC-0159 = 2[(polyethylene
                  glycol)-2000]-N,N-ditetradecylacetamide
                </li>
                <li aria-label="">1,2-Distearoyl-sn-glycero-3-phosphocholine</li>
                <li aria-label="">cholesterol</li>
                <li aria-label="">potassium chloride</li>
                <li aria-label="">potassium dihydrogen phosphate</li>
                <li aria-label="">sodium chloride</li>
                <li aria-label="">disodium hydrogen phosphate dihydrate</li>
                <li aria-label="">sucrose</li>
                <li aria-label="">water for injections</li>
              </ul>
              <p style="color:#377a90;">
                <strong>What COVID-19 Vaccine AstraZeneca contains:</strong>
              </p>
              <ul>
                <li aria-label="">The active substance is BNT162b2 RNA</li>
                <li aria-label="">
                  This vaccine contains polyethylene glycol/macrogol (PEG) as
                  part of ALC-0159
                </li>
              </ul>
              <br>
              <p>One dose (0.5 ml) contains: COVID 19 Vaccine (ChAdOx1-S* recombinant) 5 × 10^10 viral particles.</p>
              <p>*Recombinant, replication-deficient chimpanzee adenovirus vector encoding the SARS CoV 2 Spike glycoprotein. Produced in genetically modified human embryonic kidney (HEK) 293 cells.</p>
              <p>This product contains genetically modified organisms (GMOs).</p>
              <p>The other excipients are:</p>
              <ul class="mb-8">
                <li aria-label="">L-histidine</li>
                <li aria-label="">L-histidine hydrochloride monohydrate</li>
                <li aria-label="">magnesium chloride hexahydrate</li>
                <li aria-label="">polysorbate 80</li>
                <li aria-label="">ethanol</li>
                <li aria-label="">sucrose</li>
                <li aria-label="">sodium chloride</li>
                <li aria-label="">disodium edetate dihydrate</li>
                <li aria-label="">water for injections</li>
              </ul>
              <p style="color:#377a90;">
                <strong>What COVID-19 Vaccine Moderna contains:</strong>
              </p>
              <ul>
                <li aria-label="">lipid SM-102</li>
                <li aria-label="">cholesterol</li>
                <li aria-label="">1,2-Distearoyl-sn-glycero-3-phosphocholine (DSPC)</li>
                <li aria-label="">1,2-Dimyristoyl-rac-glycero-3-methoxypolyethylene glycol-2000 (PEG2000 DMG)</li>
                <li aria-label="">tromethamol</li>
                <li aria-label="">tromethamol hydrochloride</li>
                <li aria-label="">acetic acid</li>
                <li aria-label="">sodium acetate trihydrate</li>
                <li aria-label="">sucrose</li>
                <li aria-label="">water for injections</li>
              </ul>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<style scoped></style>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import BackButton from '@/components/BackButton';
import { getSesKey } from '@/helpers/session.ts';

export default Vue.extend({
  components: { BackButton },
  name: 'BookingNotes',
  data: () => ({
    showError: false,
    errorMessage: '',
    dialogIngredients: false,
    confirmingAppointment: false
  }),
  computed: {
    ...mapGetters([
      'getSelectedBookingTypesGroup',
      'getBookingApiURLs',
      'getAppointmentProcedure',
      'getClinic',
      'getSlot',
      'getPatient',
      'getQuickSchedule',
      'getFlowName'
    ]),
    getConfirmAppointmentUrl() {
      return (
        this.getBookingApiURLs.confirmAppointmentUrl +
        '&clinicId=' +
        this.getClinic.clinic_id +
        '&procedureId=' +
        this.getAppointmentProcedure.procedureId
      );
    }
  },
  methods: {
    onAfterContinueClicked() {
      this.confirmAppointment();
    },
    saveAppointment(appointment) {
      this.$store.commit('setAppointment', appointment);
    },
    confirmAppointment() {
      this.confirmingAppointment = true;
      fetch(this.getConfirmAppointmentUrl, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          'Key': getSesKey()
        },
        redirect: 'follow',
        body: '{}'
      })
        .then((response) => response.json())
        .then((response) => {
          this.confirmingAppointment = false;
          if (response.status === 'S') {
            this.showError = false;
            this.errorMessage = '';
            this.$gtag.event(`Appointment confirmed` +
             `${this.getFlowName}`,
              {'value' : 1})
            this.saveAppointment(response.response.appointment);
            this.$router.push('/booking/summary').catch(() => {});
          } else {
            this.showError = true;
            this.errorMessage =
              'Could not confirm appointment. Please try again.';
          }
        })
        .catch(() => {});
    }
  }
});
</script>
