import Vue from 'vue'
import auth from '@/helpers/auth'
import auth4Patients from '@/helpers/auth4Patients'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Sites from '../views/Sites.vue'
import Cookies from '../views/Cookies.vue'
import Info from '../views/Info.vue'
import Admin from '../views/Admin.vue'
import Book from '../views/Book.vue'
import Maintenance from '../views/Maintenance.vue'
import Portal from '../views/Portal.vue'
// @ts-ignore
import { setCallRecallUrl } from '@/helpers/session.ts';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  //UNCOMMENT TO SET MAINTENANCE SCREEN
  // {
  //   path: '/',
  //   name: 'Maintenance',
  //   component: Maintenance
  // },
  // {
  //   path: '/booking',
  //   name: 'Maintenance',
  //   component: Maintenance
  // },
  { path: '/', redirect: '/booking/date-of-birth' },
  { path: '/booking', redirect: '/booking/date-of-birth' },
  {
    path: '/booking/:stage?',
    name: 'Home',
    component: Home
  },
  {
    path: '/location-search',
    name: 'Sites',
    component: Sites
  },
  {
    path: '/cookies',
    name: 'Cookies',
    component: Cookies
  },
  {
    path: '/i',
    name: 'Info',
    component: Info
  },
  {
    path: '/info',
    name: 'Info',
    component: Info
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/book/:appointmentAccessCode?/:patientAccessCode?',
    name: 'Book',
    component: Book
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance
  },
  {
    path: '/portal/:appointmentId?',
    name: 'Portal',
    component: Portal
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.path?.toLowerCase() === '/auth') {
    try {
      // @ts-ignore
      const data = await auth.exchangeCodeForToken(to.query);
      // @ts-ignore
      auth.handleVerifiedToken(data.authResponse.access_token);
      next('/');
    }
    catch (error) {
      // @ts-ignore
      throw Error(error)
    }
  }
  else if (to.path?.toLowerCase() === '/patient-auth') {
    try {
      // @ts-ignore
      const data = await auth4Patients.exchangeCodeForToken(to.query);
      // @ts-ignore
      auth4Patients.handleVerifiedToken(data.authResponse.access_token);
     
      const accessCode = localStorage.getItem('accessCode');
      const patientAccessCode = localStorage.getItem('patientAccessCode');
      const url = `/book/${accessCode}/${patientAccessCode}`;

      setCallRecallUrl(url);
      next(`/book/${accessCode}/${patientAccessCode}`);
    }
    catch (error) {
      // @ts-ignore
      throw Error(error)
    }
  }
  else {
    next();
  }
})

export default router
