<template>
  <div>
    <v-container
      fluid
      class="ma-0 pa-0 mb-16 jumbo-header"
      style="background-color: #F9F9F9;"
    >
      <v-row justify="center" align="center" class="fill-height">
        <v-col class="pt-8 pb-16">
          <v-container>
            <back-button
              class="my-8"
              @click.native="$router.go(-1)"
            ></back-button>
            <v-row v-if="message === 'child'">
              <v-col cols="12" lg="9">
                <h2 class="mb-8 home-header-2">
                  Sorry, you can't use this service - please come back with an adult.
                </h2>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" lg="9">
                <h2 class="mb-8 home-header-2">
                  Unfortunately, you are not eligible to book a COVID-19
                  vaccination online.
                </h2>
                <p class="mb-4 home-header-3">
                  Your age group has not been invited for the COVID-19
                  vaccination yet.
                </p>
                <p class="mb-4 home-header-3">
                  You will be able to book your vaccination when you are invited
                  to do so.
                </p>
                <p class="mb-4 home-header-3">
                  Thank you for your patience during this time.
                </p>
                <a
                  class="mb-8 home-header-3"
                  style="color: #007e93;"
                  href="https://covid-19.hscni.net/get-vaccinated/"
                  target="_blank"
                  >Read more about vaccination eligibility</a
                >
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-container> </v-container>
  </div>
</template>

<script>
import Vue from 'vue';
import BackButton from '@/components/BackButton';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepNotEligibleAge',
  computed: {
    message() {
      return this.$route.query.m;
    }
  }
});
</script>
