<template>
  <div class="d-inline">
    <v-btn
      v-if="!recallSurveySubmitted"
      @click="recallSurveyDialog = true"
      ref="continue"
      color="#006078"
      class="v-btn--primary mb-4 mb-lg-0"
      x-small
    >
      Can’t find what <br class="d-lg-none">you’re looking for?
    </v-btn>
    <v-dialog v-model="recallSurveyDialog" max-width="800" max-height="600">
      <v-card>
        <v-card-title>
          Can’t find what you’re looking for?
        </v-card-title>
        <v-card-text>
          <v-radio-group
            v-model="recallSurveyAnswer"
          >
            <v-radio
              label="I’d rather visit my local GP or Pharmacy"
              value="LocalGPorPharmacy"
              color="#006078"
              class="ml-4 px-8 py-4 black--text"
              :ripple="false"
            ></v-radio>
            <v-radio
              label="I can’t find a suitable time slot"
              value="NoSuitableSlot"
              color="#006078"
              class="ml-4 px-8 py-4 black--text"
              :ripple="false"
            ></v-radio>
            <v-radio
              label="Other"
              value="Other"
              color="#006078"
              class="ml-4 px-8 py-4 black--text"
              :ripple="false"
            ></v-radio>
          </v-radio-group>
          <div>
            <p
              class="my-2 text-left"
              style="color:  black; font-size: 16px; text-align: left;"
            >
              Tell us what is the reason:
            </p>
            <v-textarea
              v-model="recallSurveyOtherDescription"
              rows="3"
              row-height="25"
              solo
              hide-details
            ></v-textarea>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#006078" text @click="recallSurveyDialog = false">
            Close
          </v-btn>
          <v-btn :disabled="disableRecallSurveySubmit" color="#006078" text @click="submitRecallSurvey()">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import auth4Patients from '@/helpers/auth4Patients';
import config from '@/config';
import Vue from 'vue';

export default Vue.extend({
  components: {},
  name: 'RecallSurvey',
  data: () => ({
    recallSurveyAnswer: null,
    recallSurveyOtherDescription: null,
    recallSurveyDialog: false,
    recallSurveySubmitted: false,
  }),
  computed: {
    appointmentAccessCode() {
      return localStorage.getItem('accessCode');
    },
    patientAccessCode() {
      return localStorage.getItem('patientAccessCode');
    },
    showRecallSurveyOtherDescription() {
      return this.recallSurveyAnswer === 'Other';
    },
    disableRecallSurveySubmit() {
      return !this.recallSurveyAnswer || (this.showRecallSurveyOtherDescription && !this.recallSurveyOtherDescription);
    }
  },
  methods: {
    accessToken4Patients() {
      if (!auth4Patients.verifySavedToken()) {
        return false;
      }
      return localStorage.getItem(config.patientAuthTokenName);
    },
    submitRecallSurvey() {
      this.submittingRecallSurvey = true;
      fetch(
        config.adminApiUrl + '/otp/' + '?hash=pauqm52nct384vz10r4852swkgnv5esw',
        {
          referrerPolicy: 'no-referrer-when-downgrade',
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.accessToken4Patients(),
            Scope: 'PATIENT'
          },
          redirect: 'follow',
          body: JSON.stringify({
            action: 'submitSatisfactionSurvey4RecallAnswer',
            accessCode: this.appointmentAccessCode,
            patientAccessCode: this.patientAccessCode,
            recallSurveyAnswer: this.recallSurveyAnswer,
            recallSurveyOtherDescription: this.recallSurveyOtherDescription
          })
        }
      )
        .then((response) => response.json())
        .then(() => {
          this.recallSurveyDialog = false;
          this.submittingRecallSurvey = false;
          this.recallSurveyAnswer = null;
          this.recallSurveyOtherDescription = null;
          this.recallSurveySubmitted = true;
        })
        .catch(() => {
          this.recallSurveyDialog = false;
          this.submittingRecallSurvey = false;
          this.recallSurveyAnswer = null;
          this.recallSurveyOtherDescription = null;
          this.recallSurveySubmitted = true;
        });
    }
  }
});
</script>
