
const SES_KEY = 'callrecallkey';
const SES_URL_CALL_RECALL = 'callrecallurl';

export const setSesKey = (key: any) => {
  sessionStorage.setItem(SES_KEY, key);
};

export const getSesKey = () => {
  const key = sessionStorage.getItem(SES_KEY) || '';
  return key;
};

export const remSesKey = () => {
  sessionStorage.removeItem(SES_KEY);
};

export const setCallRecallUrl = (url: any) => {
  sessionStorage.setItem(SES_URL_CALL_RECALL, url);
};

export const getCallRecallUrl = () => {
  const url = sessionStorage.getItem(SES_URL_CALL_RECALL) || '';
  return url;
};

export const remCallRecallUrl = () => {
  sessionStorage.removeItem(SES_URL_CALL_RECALL);
};
