import Vue from 'vue';
import Vuetify, {
  VAvatar,
  VCard,
  VRating,
  VToolbar,
  VApp,
  VBtn,
  VStepper,
  VStepperStep,
  VStepperHeader,
  VStepperContent,
  VMain,
  VContainer,
  VRow,
  VCol,
  VCardText,
  VCardActions,
  VCardTitle,
  VImg,
  VSpacer,
  VDialog,
  VSelect,
  VBtnToggle,
  VDivider,
  VAppBar,
  VToolbarTitle,
  VForm,
  VTextField,
  VLayout,
  VFlex,
  VList,
  VListItem,
  VListItemTitle,
  VListItemSubtitle,
  VListItemContent,
  VAlert,
  VIcon,
  VProgressLinear,
  VSnackbar,
  VSlideXTransition,
  VFadeTransition,
  VCheckbox,
  VAutocomplete,
  VDatePicker,
  VRadio,
  VRadioGroup,
  VMenu,
  VTooltip,
  VHover,
  VFooter,
  VSlideGroup,
  VSlideItem,
  VTextarea,
  VChip
} from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify, {
  components: {
    VAvatar,
    VCard,
    VRating,
    VToolbar,
    VApp,
    VBtn,
    VStepper,
    VStepperStep,
    VStepperHeader,
    VStepperContent,
    VMain,
    VContainer,
    VRow,
    VCol,
    VCardText,
    VCardActions,
    VCardTitle,
    VImg,
    VSpacer,
    VDialog,
    VSelect,
    VBtnToggle,
    VDivider,
    VAppBar,
    VToolbarTitle,
    VForm,
    VTextField,
    VLayout,
    VFlex,
    VList,
    VListItem,
    VListItemTitle,
    VListItemSubtitle,
    VListItemContent,
    VAlert,
    VIcon,
    VProgressLinear,
    VSnackbar,
    VSlideXTransition,
    VFadeTransition,
    VCheckbox,
    VAutocomplete,
    VDatePicker,
    VRadio,
    VRadioGroup,
    VMenu,
    VTooltip,
    VHover,
    VFooter,
    VSlideGroup,
    VSlideItem,
    VTextarea,
    VChip
  },
  directives: {
    Ripple
  }
});

export default new Vuetify({
  theme: { disable: true }
});
