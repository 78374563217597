<template>
  <div>
    <v-container
      fluid
      class="ma-0 pa-0 mb-16 jumbo-header"
      style="background-color: #F9F9F9;"
    >
      <v-row justify="center" align="center" class="fill-height">
        <v-col class="pt-8 pb-16">
          <v-container>
            <back-button
              class="my-8"
              @click.native="$router.go(-1)"
            ></back-button>
            <v-row>
              <v-col cols="12" lg="9">
                <h2 class="mb-8 home-header-2 font-weight-regular">
                  Unfortunately there are no slots available at the moment.
                </h2>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
    </v-container>
  </div>
</template>

<script>
import Vue from 'vue';
import BackButton from '@/components/BackButton';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepNotEligibleAge'
});
</script>
