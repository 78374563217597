<template>
  <div>
    <v-container
      fluid
      class="ma-0 pa-0 mb-16 jumbo-header"
      style="background-color: #F9F9F9;"
    >
      <v-row justify="center" align="center" class="fill-height">
        <v-col class="pt-8 pb-16">
          <v-container>
            <back-button
              class="my-8"
              @click.native="$router.go(-1)"
            ></back-button>
            <v-row>
              <v-col cols="12" lg="9">
                <h2 v-if="message == 1" class="mb-8 home-header-2">
                  Unfortunately you are not eligible to book using this service
                  at this time as you have tested positive for COVID within 12
                  weeks.
                </h2>
                <h2 v-if="message == 2" class="mb-8 home-header-2">
                  Unfortunately you are not eligible to book using this service
                  at this time as you have tested positive for COVID within 28
                  days.
                </h2>
                <h3 v-if="message == 1" class="mb-8 home-header-3">
                  Please try to book again in 12 weeks.
                </h3>
                <h3 v-if="message == 2" class="mb-8 home-header-3">
                  Please try to book again in 28 days.
                </h3>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-container> </v-container>
  </div>
</template>

<script>
import Vue from 'vue';
import BackButton from '@/components/BackButton';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepNotEligibleCovid',
  computed: {
    message() {
      return this.$route.query.m;
    }
  }
});
</script>
