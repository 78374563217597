<template>
    <v-row justify="center">
      <v-col cols="12" class="pt-16">
        <v-container>
          <back-button @click.native="$router.go(-1)"></back-button>
          <v-row>
            <v-col cols="12" lg="6">
              <h3 class="mb-8 home-header-3">
                You are not eligible to receive another COVID-19 vaccination until three months has elapsed since you received your last COVID-19 vaccination, as per clinical requirements.
                Please book a COVID-19 vaccination appointment three months or more after your previous COVID-19 vaccination.
              </h3>
              <v-btn
                @click="onAfterContinueClicked"
                class="v-btn--primary"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import Vue from 'vue';
  import BackButton from '@/components/BackButton';
  import { mapGetters } from 'vuex';
  
  export default Vue.extend({
    components: {
      BackButton
    },
    name: 'StepBookingCovidDisclaimer',
    computed: {
      ...mapGetters(['getFlowName'])
    },
    methods: {
      onAfterContinueClicked() {
        this.$router.push('/booking/demographics').catch(() => {});
      }
    }
  });
  </script>
  