<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <v-row>
          <v-col cols="12" lg="6">
            <h3 class="mb-8 home-header-3">
              Which of the following apply to you?
            </h3>
            <div
              v-for="demographicsItem in demographicsItems"
              :key="demographicsItem.value"
            >
              <v-checkbox
                v-model="demographics"
                v-on:change="onCheckboxGroupClicked"
                v-on:keyup.native.enter="
                  addRemoveDemographics(demographicsItem.value)
                "
                :label="demographicsItem.label"
                :value="demographicsItem.value"
                color="#006078"
                class="ml-4 black--text"
                :ripple="false"
              ></v-checkbox>
            </div>
            <v-btn @click="onAfterContinueClicked" class="mt-8 v-btn--primary">
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import BackButton from '@/components/BackButton';
import demographicsItems from '@/data/demographics';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepBookingTypes',
  data: () => ({
    demographicsItems: demographicsItems,
    demographics: []
  }),
  mounted: function() {
    this.$store.commit('setDemographics', []);
  },
  computed: {
    ...mapGetters(['getHealthAndSocialCareWorker', 'getFrontlineHealthAndSocialCareWorker', 'getAge', 'getDemographics',])
  },
  methods: {
    addRemoveDemographics(value) {
      const index = this.demographics.indexOf(value);
      if (index > -1) {
        this.demographics.splice(index, 1);
      } else {
        this.demographics.push(value);
      }
    },
    saveDemographics() {
      this.$store.commit('setDemographics', this.demographics);
    },
    onCheckboxGroupClicked() {
      this.saveDemographics();
    },
    checkIfAlreadyEligible() {
      const isEligibleForSeasonalFlu =
        this.getAge >= 65 ||
        this.getFrontlineHealthAndSocialCareWorker ||
        this.getDemographics.includes('Carer') ||
        (this.getDemographics.includes('Close Contact') && this.getAge >= 18) ||
        this.getDemographics.includes('HSC Worker') ||
        this.getDemographics.includes('Pregnant') ||
        this.getDemographics.includes('Care Home Resident') ||
        (this.getDemographics.includes('At Risk') && this.getAge >= 16) ||
        this.getDemographics.includes('Bird Culler') ||
        (this.getDemographics.includes('Immunosuppressed') && this.getAge > 15)

      const isEligibleForCovidVaccine =
        this.getAge >= 65 ||
        this.getFrontlineHealthAndSocialCareWorker ||
        this.getDemographics.includes('Care Home Resident') ||
        (this.getDemographics.includes('At Risk') && this.getAge >= 12) ||
        this.getDemographics.includes('Pregnant') ||
        this.getDemographics.includes('Immunosuppressed')

      const isEligibleForCovidUnder12Vaccine =
        this.getAge >= 5 &&
        this.getAge < 12 &&
        (
          this.getDemographics.includes('At Risk') ||
          this.getDemographics.includes('Immunosuppressed')
        )

        return (isEligibleForSeasonalFlu && isEligibleForCovidVaccine) 
          || (isEligibleForCovidUnder12Vaccine && isEligibleForSeasonalFlu)
          || false 
    },
    onAfterContinueClicked() {
      this.$gtag.event('Demographics filled', {})

      if(this.checkIfAlreadyEligible()){
        if (this.getAge >= 18 && (this.getDemographics.includes('HSC Worker'))) {
          this.$router.push('/booking/trust').catch(() => {});
        }
        else {
          this.$router.push('/booking/booking-disclaimer').catch(() => {});
        }
      } else {
        this.$router.push('/booking/covid-flu-letter').catch(() => {});
      }

    }
  }
});
</script>
