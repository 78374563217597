// @ts-nocheck

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/vue-tel-input';
import 'whatwg-fetch'
import './plugins/text-encoding'
import LoadScript from 'vue-plugin-load-script';
import VueGtag from 'vue-gtag'
import config from './config';

Vue.config.productionTip = false

Vue.use(LoadScript);

Vue.use(VueGtag, {
  config: {
    id: config.gtagID,
    params: {
      send_page_view: false
    }
  }
}, router)

new Vue({
  router,
  store,
  vuetify,
  LoadScript,
  render: h => h(App)
}).$mount('#app')
