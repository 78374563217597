<template>
    <v-row justify="center">
      <v-col cols="12" class="pt-16">
        <v-container>
          <back-button @click.native="$router.go(-1)"></back-button>
          <h3 class="mb-8 home-header-3">
            Are you currently over 28 weeks pregnant?
          </h3>
          <v-radio-group
            v-model="isOver28Pregnant"
            class="mb-4"
          >
            <v-radio
              v-on:keyup.enter="
                isOver28Pregnant = 'Yes';
              "
              label="Yes"
              value="Yes"
              color="#006078"
              class="ml-4 px-8 py-4 black--text"
              :ripple="false"
            ></v-radio>
            <v-radio
              v-on:keyup.enter="
                isOver28Pregnant = 'No';
              "
              label="No"
              value="No"
              color="#006078"
              class="ml-4 px-8 py-4 black--text"
              :ripple="false"
            ></v-radio>
          </v-radio-group>
          <v-btn
            @click="onAfterContinueClicked"
            class="v-btn--primary"
            :disabled="!isOver28Pregnant"
          >
            Continue
          </v-btn>
        </v-container>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import Vue from 'vue';
  import BackButton from '@/components/BackButton';
  
  export default Vue.extend({
    components: { BackButton },
    name: 'StepRSVPregnancy1',
    data: () => ({
      isOver28Pregnant: null
    }),
    methods: {
      onAfterContinueClicked() {
        this.$gtag.event('Pertussis over 16 weeks pregnant answered', {})
        if (this.isOver28Pregnant === 'Yes') {
          this.$router.push('/booking/rsv-pregnancy-2').catch(() => {});
        } else {
          this.$router.push('/booking/rsv-pregnancy-not-eligible').catch(() => {});
        }
      }
    }
  });
  </script>
  