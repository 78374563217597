<template>
  <div>
    <v-container
      fluid
      class="ma-0 pa-0 mb-16 jumbo-header"
      style="background-color: #F9F9F9;"
    >
      <v-row justify="center" align="center" class="fill-height">
        <v-col class="pt-8 pb-16">
          <v-container>
            <back-button
              class="my-8"
              @click.native="$router.go(-1)"
            ></back-button>
            <v-row>
              <v-col cols="12" lg="9">
                <h2 class="mb-8 home-header-3">
                  You are not eligible to receive dose 2 of MMR until a month has elapsed since you received dose 1, as per clinical requirements.<br /><br />
                  Please select an appointment 30 days or more after your dose 1 appointment.
                </h2>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-container> </v-container>
  </div>
</template>

<script>
import Vue from 'vue';
import BackButton from '@/components/BackButton';
//currently not in usage
export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepNotEligibleCovid',
  computed: {
    message() {
      return this.$route.query.m;
    }
  },
  mounted(){
    this.$gtag.event('MMR flow not eligible')
  }
});
</script>
