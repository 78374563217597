<template>
  <div v-if="getAppointment">
    <v-container
      fluid
      class="ma-0 jumbo-header"
      style="background-color: #f2fbfc;"
    >
      <v-container>
        <v-row align="center" class="fill-height">
          <v-col rows="12" sm="8" lg="6">
            <h2 class="mb-8 home-header-2">
              Booking confirmation number: {{ getAppointment.appointmentIdHIS }}
            </h2>
            <h3 class="mb-8 home-header-3">
              Your appointment details have been sent to you.
            </h3>
            <div v-if="getSelectedBookingTypesGroup === 'PERTUSSIS' || getSelectedBookingTypesGroup === 'RSV'" >
              <h3 class="mb-8 home-header-3">
               Young adults up to 16 years old will need to be accompanied by a parent or guardian.
              </h3>
            </div>
            <div v-else>
              <h3 class="mb-8 home-header-3">
                You must bring photo ID with you, as well as other identification
                depending on which group you are in.
              </h3>
              <h3 v-if="getSelectedBookingTypesGroup === 'MMR'" class="mb-8 home-header-3">
                Children and young adults aged 6-16 years old will need to be accompanied by a parent or guardian.
              </h3>
              <h3 class="mb-8 home-header-3">
                If you have been asked to bring a letter from your GP/Clinician, please bring it with
                you.
              </h3>
            </div>
          </v-col>
        </v-row>
        <v-row align="center" class="fill-height">
          <v-col rows="12" lg="10">
            <p>
              <v-btn @click="print" class="mb-8 mb-lg-0 mr-8 v-btn--info">
                Print
              </v-btn>
              <v-btn
                @click="window.print()"
                class="mb-8 mb-lg-0 mr-8 v-btn--info"
                href="https://covid-19.hscni.net/get-vaccinated/"
                target="_blank"
              >
                <dfn
                  ><abbr title="Read Frequently Asked Questions">
                    Read FAQs
                  </abbr></dfn
                >
              </v-btn>
              <v-btn @click="$router.push('/booking').catch(() => {})" class="mb-8 mb-lg-0 mr-8 v-btn--info">
                New booking
              </v-btn>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container v-if="!getSmileFaceSubmitted">
      <smile-faces
        v-bind:appointment-id="getAppointment.appointmentIdHIS"
      ></smile-faces>
    </v-container>
    <v-container fluid class="ma-0" style="background-color: #f2fbfc;">
      <v-container>
        <div class="mt-4">
          <p class="mb-8 home-header-4">
            <span style="color: #007e93;">Vaccine Details</span>
          </p>
          <p class="mb-1">Vaccine Centre</p>
          <p class="mb-8 home-text-2">
            <strong>{{ getClinic.clinic_name }}</strong>
          </p>
          <p class="mb-1">Centre Address</p>
          <p class="mb-8 home-text-2">
            <strong>{{ getClinic.clinic_address }}</strong>
          </p>
          <!-- <p v-if="getBookingType === 'COVID-19'" class="mb-1">COVID-19</p>
          <p v-if="getBookingType === 'FLU'" class="mb-1">Flu</p>
          <p v-if="getBookingType === 'BOTH'" class="mb-1">COVID-19 and Flu</p> -->
          <p class="mb-8 home-text-2">
            <strong
              >{{ dose1Date }} <span class="ml-2">{{ dose1Time }}</span></strong
            >
          </p>
        </div>
      </v-container>
    </v-container>
    <v-container
      v-if="
        false && getPatient.patientMatched.status === 'S' &&
          !getPatient.patientMatched.matched
      "
      fluid
      class="ma-0"
      style="background-color: black; color: white;"
    >
      <v-container class="py-12">
        <v-row>
          <v-col rows="12" lg="6">
            <p class="mb-8">
              <strong>About your Health & Care Number</strong>
            </p>
            <p class="mb-8">
              Unfortunately, we could not match your details to the Health and
              Care Index.
            </p>
            <p class="mb-8">
              This is because the details you entered did not match your GP
              records.
            </p>
            <p>
              If we don’t have your correct details, you can apply for a new
              medical card and apply to change your details by
              <a
                class="white--text"
                href="https://hscforms.hscni.net/replacement-medical-card/"
                target="_blank"
                >clicking this link</a
              >.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<style scoped>
.vaccine__details {
  border-left: 1px solid black;
}
.v-btn--info {
  width: 240px !important;
  height: 48px !important;
  background: #e1f1f5 !important;
  box-shadow: none !important;
  color: black !important;
  border: 1px solid #e1f1f5;
  border-radius: 4px !important;
  font-size: 20px !important;
  line-height: 20px !important;
}

.v-btn--info /deep/ .v-btn__content {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  text-transform: none;
}
</style>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import moment from 'moment/moment';
import SmileFaces from '@/components/SmileFaces';

export default Vue.extend({
  components: { SmileFaces },
  name: 'StepBookingSummary',
  data: () => ({}),
  computed: {
    ...mapGetters([
      'getSelectedBookingTypesGroup',
      'getAppointment',
      'getClinic',
      'getPatient',
      'getSmileFaceSubmitted',
      'getFlowName'
    ]),
    patientDateOfBirth() {
      return moment(this.getAppointment.patient.dateOfBirth).format(
        'DD / MM / YYYY'
      );
    },
    patientGender() {
      return this.getAppointment.patient.sex === 'M' ? 'Male' : 'Female';
    },
    patientAddress() {
      let address = '';
      if (this.getPatient.patientAddress.address1) {
        address += this.getPatient.patientAddress.address1;
      }
      if (this.getPatient.patientAddress.address2) {
        address += ', ' + this.getPatient.patientAddress.address2;
      }
      if (this.getPatient.patientAddress.town) {
        address += ', ' + this.getPatient.patientAddress.town;
      }
      if (this.getPatient.patientAddress.postcode) {
        address += ', ' + this.getPatient.patientAddress.postcode;
      }
      return address;
    },
    dose1Date() {
      return moment(this.getAppointment.appointmentDate.date).format(
        'DD / MM / YYYY'
      );
    },
    dose1Time() {
      return moment(this.getAppointment.appointmentTime, 'HH:mm').format(
        'hh:mm a'
      );
    }
  },
  methods: {
    print() {
      window.print();
    }
  },
  mounted(){
    this.$gtag.event(`Appointment summary visited` + `${this.getFlowName}`, {})
  }
});
</script>
