export default {
  keyRecaptchaSite: process.env.VUE_APP_RECAPTCHA_SITE,
  apiUrl: process.env.VUE_APP_API_URL,
  adminApiUrl: process.env.VUE_APP_ADMIN_API_URL,
  bookingUrl: process.env.VUE_APP_BOOKING_URL,
  authEndpoint: process.env.VUE_APP_AUTH_ENDPOINT,
  authTokenName: process.env.VUE_APP_AUTH_TOKEN_NAME,
  authClientId: process.env.VUE_APP_AUTH_CLIENT_ID,
  authScope: process.env.VUE_APP_AUTH_SCOPE,
  authRedirectUrl: process.env.VUE_APP_AUTH_REDIRECT_URL,
  authLogoutEndpoint: process.env.VUE_APP_AUTH_LOGOUT_ENDPOINT,
  patientAuthEndpoint: process.env.VUE_APP_PATIENT_AUTH_ENDPOINT,
  patientAuthTokenName: process.env.VUE_APP_PATIENT_AUTH_TOKEN_NAME,
  patientAuthClientId: process.env.VUE_APP_PATIENT_AUTH_CLIENT_ID,
  patientAuthScope: process.env.VUE_APP_PATIENT_AUTH_SCOPE,
  patientAuthRedirectUrl: process.env.VUE_APP_PATIENT_AUTH_REDIRECT_URL,
  patientAuthLogoutEndpoint: process.env.VUE_APP_PATIENT_AUTH_LOGOUT_ENDPOINT,
  portalUrl: process.env.VUE_APP_PORTAL_URL ?? 'https://manage-my-appointments.aptvision.com',
  googleMapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  gtagID: process.env.VUE_APP_GTAG_ID
}
