const disallowedHealthCareNumbers = [
  '0000000000',
  '1111111111',
  '2222222222',
  '3333333333',
  '4444444444',
  '5555555555',
  '6666666666',
  '7777777777',
  '8888888888',
  '9999999999',
];

// # 9434765919
export const validateHealthAndCareNumber = (healthAndCareNumber: string) => {
  healthAndCareNumber = (healthAndCareNumber || '').replace(/\s/g, '');
  if (!healthAndCareNumber) {
    return false;
  }
  if (!/^\d+$/.test(healthAndCareNumber)) {
    return false;
  }
  if (disallowedHealthCareNumbers.includes(healthAndCareNumber)) {
    return false;
  }
  let result = false;
  if (healthAndCareNumber.length === 10) {
    let total = 0;
    let i = 0;
    for (i = 0; i <= 8; i++) {
      const digit: any = healthAndCareNumber.substr(i, 1);
      const factor: any = 10 - i;
      total += digit * factor;
    }
    let checkDigit = 11 - (total % 11);
    if (checkDigit === 11) {
      checkDigit = 0;
    }
    if (checkDigit === parseInt(healthAndCareNumber.substr(9, 1))) {
      result = true;
    }
  }
  return result;
};

export const validateStaffNumber = (staffNumber: string) => {
  staffNumber = (staffNumber || '').replace(/\s/g, '');
  
  // its optional per client request
  if (!staffNumber) {
    return true;
  }
  if (!/^[0-9a-zA-Z]+$/.test(staffNumber)) {
    return false;
  }
  if (staffNumber.length !== 8) {
    return false;
  }
  return true;
};
