import moment from 'moment/moment';

export const calculateAge = (dateOfBirth: any) => {
  const referenceDate = moment();
  const duration = moment.duration(referenceDate.diff(dateOfBirth));
  return duration.years();
};

export const calculateAgeInMonths = (dateOfBirth: any) => {
  const referenceDate = moment();
  const duration = moment.duration(referenceDate.diff(dateOfBirth));
  return duration.months();
};

export const formatDateOfBirth = (dateOfBirth: any) => {
  const parsedDate = moment(dateOfBirth, 'ddd MMM DD YYYY HH:mm:ss [GMT]Z');
  const formattedDate = parsedDate.format('DD/MM/YYYY');
  return formattedDate;
}
