<template>
  <div>
    <v-container>
      <v-row
        align="top"
        justify="center"
        >
        <v-col cols="12" lg="8">
          <div id="main-content" style="font-size: 1.2em">

            <div class="breadcrumb-padding"></div>
            <div class="govuk-grid-row">
              <div id="area-1" class="sortablelist connectedSortable ui-sortable govuk-grid-column-two-thirds activearea">


                <h1 class="govuk-heading-l ui-sortable-handle">Cookies</h1>

                <p class="govuk-body ui-sortable-handle">
                  They improve things by:
                </p>
                <ul>
                  <li>
                    <p class="govuk-body ui-sortable-handle">
                      Remembering settings, so you don’t have to keep re-entering them whenever you visit a new page
                    </p>
                  </li>
                  <li>
                    <p class="govuk-body ui-sortable-handle">
                      Remembering information you’ve given (eg your postcode) so you don’t need to keep entering it
                    </p>
                  </li>
                  <li>
                    <p class="govuk-body ui-sortable-handle">
                      Measuring how you use the website so we can make sure it meets your needs
                    </p>
                  </li>
                </ul>
                <p class="govuk-body ui-sortable-handle">
                  Our cookies aren’t used to identify you personally.
                  They’re just here to make the site work better for you.
                  Indeed, you can manage and/or delete these small files as you wish.
                </p>
                <h2 class="govuk-heading-m ui-sortable-handle">
                  How we use cookies
                </h2>
                <p class="govuk-body ui-sortable-handle">
                  We use cookies in several places – we’ve listed each of them
                  below with more details about why we use them and how long they will last.
                </p>
                <h2 class="govuk-heading-m ui-sortable-handle">
                  Necessary cookies
                </h2>
                <p class="govuk-body ui-sortable-handle">
                  These essential cookies do things like remember your progress through a form.
                  They always need to be on.
                </p>
                <v-simple-table>
                  <template v-slot:default>
                    <thead class="govuk-table__head">
                    <tr class="govuk-table__row">
                      <th scope="col" class="govuk-table__header">Name</th>
                      <th scope="col" class="govuk-table__header">Purpose</th>
                      <th scope="col" class="govuk-table__header">Expires</th>
                    </tr>
                    </thead>
                    <tbody class="govuk-table__body">
                    <tr class="govuk-table__row" style="border-bottom: 1px solid grey;">
                      <th scope="row" class="govuk-table__header">PHPSESSID</th>
                      <td class="govuk-table__cell">
                        The PHPSESSID cookie is used to establish a user session and to pass state data via a temporary cookie, which is commonly referred to as a session cookie. As the PHPSESSID cookie has no timed expiry, it disappears when the client is closed.
                      </td>
                      <td class="govuk-table__cell">Tab closed/refreshed</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <h2 class="govuk-heading-m ui-sortable-handle">
                  Cookies that measure website use
                </h2>
                <p class="govuk-body ui-sortable-handle">
                  We use Google Analytics to measure how you use the website so we can improve it based on user needs.
                  We do not allow Google to use or share the data about how you use this site.
                </p>
                <p class="govuk-body ui-sortable-handle">
                  Google Analytics sets cookies that store anonymised information about:
                </p>
                <ul>
                  <li>
                    <p class="govuk-body ui-sortable-handle">
                      how you got to the site
                    </p>
                  </li>
                  <li>
                    <p class="govuk-body ui-sortable-handle">
                      the pages you visit on 'Help us trace your contacts' and how long you spend on each page
                    </p>
                  </li>
                  <li>
                    <p class="govuk-body ui-sortable-handle">
                      what you click on while you're visiting the site
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: 'CookiePolicy',
  components: {
  },
  data: () => ({
  }),
  methods: {
    openBooking() {
      window.location.href = '/booking'
    }
  }
}
</script>

<style>

</style>