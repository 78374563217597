<template>
  <div>
    <div style="max-width: 540px;" v-if="cardSmileFaces">
      <v-container>
        <p
          class="ma-0 home-header-4"
          style="word-break: normal; color: #007e93;"
        >
          How did you find your booking experience?
        </p>
      </v-container>
      <v-container align-items="center" class="pa-0">
        <v-btn-toggle v-model="smileFace" class="mb-2" group>
          <v-btn value="S" height="100" class="mr-4 mb-4 rounded-lg">
            <img src="/img/icons/smile-face-2.png" />
          </v-btn>
          <v-btn value="N" height="100" class="mr-4 mb-4 rounded-lg">
            <img src="/img/icons/smile-face-1.png" />
          </v-btn>
          <v-btn value="U" height="100" class="mr-4 mb-4 rounded-lg">
            <img src="/img/icons/smile-face-0.png" />
          </v-btn>
        </v-btn-toggle>
        <div v-if="smileFace">
          <p
            class="my-2 text-left"
            style="color:  black; font-size: 16px; text-align: left;"
          >
            Tell us how we can improve
          </p>
          <v-textarea
            v-model="whatWeCanImprove"
            rows="3"
            row-height="25"
            solo
          ></v-textarea>
          <p class="text-right">
            <v-btn
              @click="submitSmileFace"
              :disabled="!smileFace || submittingSmileFace"
              class="v-btn--primary"
            >
              Submit
            </v-btn>
          </p>
        </div>
      </v-container>
    </div>
  </div>
</template>

<style scoped>
.v-btn.v-item--active {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
    0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12) !important;
}
</style>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: {},
  props: ['appointmentId'],
  name: 'SmileFaces',
  data: () => ({
    cardSmileFaces: true,
    smileFace: null,
    whatWeCanImprove: null,
    submittingSmileFace: false
  }),
  computed: {
    ...mapGetters(['getBookingApiURLs'])
  },
  methods: {
    submitSmileFace() {
      this.submittingSmileFace = true;
      fetch(
        this.getBookingApiURLs.submitSmileFaceUrl +
          '?appointmentId=' +
          this.appointmentId,
        {
          referrerPolicy: 'no-referrer-when-downgrade',
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'follow',
          body: JSON.stringify({
            smileFace: this.smileFace,
            whatWeCanImprove: this.whatWeCanImprove
          })
        }
      )
        .then((response) => response.json())
        .then(() => {
          this.submittingSmileFace = false;
          this.cardSmileFaces = false;
          this.$store.commit('setSmileFaceSubmitted', true);
        })
        .catch(() => {
          this.submittingSmileFace = false;
          this.cardSmileFaces = false;
          this.$store.commit('setSmileFaceSubmitted', true);
        });
    }
  }
});
</script>
