<template>
  <v-app>
    <v-main ref="pagetop">
      <v-app-bar
        v-if="appBar"
        :extended="extended"
        height="92px"
        color="white"
        style="box-shadow: 0 0 0 1px rgba(0,0,0,.05)!important;"
        class="pa-0 ma-0"
        extension-height="140px"
        app
        fixed
        flat
        outlined
      >
        <v-container v-if="!accessToken" fluid>
          <v-row align-content="center" align="center" justify="center">
            <v-col cols="12" class="px-9 pt-2">
              <v-container>
                <div class="d-flex">
                  <a
                    class="d-flex black--text"
                    href="https://vaccinations.covid-19.hscni.net/booking"
                  >
                    <div class="d-inline-flex" style="width: 65px;">
                      <img src="/img/logo.svg" alt="HSC Logo" />
                    </div>
                    <span
                      class="d-inline-flex mt-0 ml-2 font-weight-bold justify-center align-center align-content-center"
                      style="font-size: 1.125rem;"
                    >
                      NI VACCINATIONS
                    </span>
                  </a>
                  <v-spacer> </v-spacer>
                  <div
                    class="d-inline-flex ml-2 font-weight-bold justify-center align-center align-content-center"
                    style="font-size: 1.16em"
                  >
                    <span v-if="$vuetify.breakpoint.mdAndDown">
                      <v-btn icon @click="extended = !extended">
                        <v-icon size="32">
                          mdi-dots-grid
                        </v-icon>
                      </v-btn>
                    </span>
                    <span v-if="$vuetify.breakpoint.lgAndUp">
                      <a href="https://covid-19.hscni.net/guidance/manage-my-appointments/" class="ml-8 header">Change appointment</a>
                      <a
                        class="ml-8 header"
                        href="https://covid-19.hscni.net/get-vaccinated/"
                        target="_blank"
                        ><dfn
                          ><abbr title="Frequently Asked Questions"
                            >FAQs</abbr
                          ></dfn
                        ></a
                      >
                      <a
                        class="ml-8 header"
                        href="https://covid-19.hscni.net/vaccine-service-privacy-notice/"
                        target="_blank"
                        >Privacy Notice</a
                      >
                    </span>
                  </div>
                </div>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else-if="accessToken4Admin" fluid>
          <v-row align-content="center" align="center" justify="center">
            <v-col cols="12" class="px-9 pt-2">
              <v-container>
                <div class="d-flex justify-end">
                  <a href="/admin" class="header mobile">Back to admin</a>
                </div>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
        <template v-slot:extension v-if="extended">
          <v-row
            align-content="center"
            align="center"
            justify="center"
            class="pa-0 px-2 ma-0"
          >
            <v-col cols="12" class="pa-0 ma-0 max-w">
              <span v-if="isBooking"
                ><a href="https://covid-19.hscni.net/guidance/manage-my-appointments/" class="header mobile"
                  >Change appointment</a
                ></span
              >
              <span v-if="!isBooking"
                ><a href="/" class="header mobile">Book appointment</a></span
              >
              <br />
              <v-divider />
              <a
                class="header mobile"
                href="https://covid-19.hscni.net/get-vaccinated/"
                target="_blank"
                ><dfn
                  ><abbr title="Frequently Asked Questions">FAQs</abbr></dfn
                ></a
              >
              <v-divider />
              <a
                class="header mobile"
                href="https://covid-19.hscni.net/vaccine-service-privacy-notice/"
                target="_blank"
                >Privacy Notice</a
              >
            </v-col>
          </v-row>
        </template>
      </v-app-bar>
      <section ref="body">
        <router-view @hideAppBar="hideAppBar" />
      </section>
      <page-footer v-bind:fixed="footerFixed" ref="footer" />
    </v-main>
  </v-app>
</template>

<script>
import auth from '@/helpers/auth';
import auth4Patients from '@/helpers/auth4Patients';
import config from '@/config';
import Vue from 'vue';
import PageFooter from '@/components/PageFooter';

export default {
  name: 'App',
  components: { PageFooter },
  data: () => ({
    accessToken: null,
    extended: false,
    appBar: true,
    footerFixed: true
  }),
  mounted() {
    const resizeObserver = new ResizeObserver(() => {
      if (!this.$refs.body || !this.$refs.footer) {
        return;
      }
      this.footerFixed = true;
      Vue.nextTick(() => {
        const BUFFOR_MARGIN = 100; //space that we need between footer and content
        const FOOTER_HEIGHT = 360;
        const windowHeight = window.innerHeight;
        const bodyHeight = this.$refs.body.clientHeight;
        if (bodyHeight + FOOTER_HEIGHT + BUFFOR_MARGIN > windowHeight) {
          this.footerFixed = false;
        } else {
          this.footerFixed = true;
        }
      });
      //FETCH GOOGLE TRANSLATE SCRIPT
      this.$loadScript(
        `//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit`
      );
    });
    resizeObserver.observe(this.$refs.body);
  },
  updated() {
    this.$nextTick(function () {
      this.accessToken = this.accessToken4Admin || this.accessToken4Patients;
    })
  },
  computed: {
    accessToken4Admin() {
      if (!auth.verifySavedToken()) {
        return false;
      }
      return localStorage.getItem(config.authTokenName);
    },
    accessToken4Patients() {
      if (!auth4Patients.verifySavedToken()) {
        return false;
      }
      return localStorage.getItem(config.patientAuthTokenName);
    },
  },
  methods: {
    hideAppBar(value) {
      this.appBar = value;
    }
  }
};
</script>

<style>
/* Google translate fieldsets */
.v-application .v-input__slot fieldset {
  display: none !important;
}
</style>

<style scoped>
.v-application {
  overflow: hidden;
  line-height: 1.375 !important;
  font-family: Aktiv Grotesk, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
}

.v-application /deep/ .jumbo-header {
  min-height: 370px;
  line-height: 1.2 !important;
}

/* < lg */
@media (max-width: 1264px) {
  .v-application /deep/ .jumbo-header {
    height: auto;
  }
}

.v-toolbar__content {
  margin: 0 !important;
  padding: 0 !important;
}

/* =========== buttton ==========*/

.v-application
  /deep/
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background: #7ebdc8 !important;
  color: #aed5dc !important;
  opacity: 1 !important;
}

.v-application /deep/ .v-btn.v-btn--primary {
  height: 48px !important;

  border-radius: 6px !important;
  background: #006078 !important;
  color: #ffffff !important;
  box-shadow: none !important;
}

.v-application /deep/ .v-btn.v-btn--primary:hover {
  background: #344244 !important;
  color: #ffffff !important;
  opacity: 1 !important;
}

.v-application /deep/ .v-btn.v-btn--primary .v-btn__content {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-transform: none;
}

/* =========== radios ==========*/

.v-application /deep/ .v-input--radio-group .v-radio {
  max-width: 390px;
}

.v-application /deep/ .v-input--radio-group .v-radio:hover {
  background-color: rgba(0, 172, 201, 0.05);
}

.v-application /deep/ .mdi-radiobox-blank::before {
  content: url(/img/icons/radio--blank.svg);
  margin-right: 48px;
}

.v-application /deep/ .mdi-radiobox-marked::before {
  content: url(/img/icons/radio--marked.svg);
  margin-right: 48px;
}

.v-application /deep/ .v-input--radio-group label {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 20px !important;
  color: #000000 !important;
}

.v-application /deep/ .v-radio--is-focused {
  background-color: rgba(0, 172, 201, 0.05);
}

/* =========== selects and textboxes ==========*/

.v-application /deep/ .v-input__slot[role='button'],
.v-application /deep/ .vue-tel-input,
.v-application /deep/ .v-text-field .v-input__slot {
  height: 48px !important;
  background: #f5f5f5 !important;
  box-shadow: none !important;
  border: 1px solid #d0d4d4 !important;
  border-radius: 0 !important;
}

.v-application /deep/ .v-text-field.v-input--is-focused .v-input__slot {
  box-shadow: 0 1px 5px rgb(0 0 0 / 0.2) !important;
}

.v-application
  /deep/
  .v-input--checkbox.v-input--is-focused
  .v-input--selection-controls__input {
  box-shadow: 0 1px 5px rgb(0 0 0 / 0.2) !important;
}

.v-application /deep/ .vue-tel-input .vti__dropdown:hover,
.v-application /deep/ .vue-tel-input .vti__dropdown:focus-visible {
  padding: 3px 0 !important;
  background-color: #f5f5f5 !important;
  box-shadow: 0 1px 5px rgb(0 0 0 / 0.2) !important;
}

.v-application /deep/ .vue-tel-input .vti__input:hover,
.v-application /deep/ .vue-tel-input .vti__input:focus-visible {
  padding: 3px 0 3px 7px !important;
  background-color: #f5f5f5 !important;
  box-shadow: 0 1px 5px rgb(0 0 0 / 0.2) !important;
}

.v-application /deep/ .v-textarea .v-input__slot {
  height: auto !important;
}

.v-application /deep/ .v-text-field--outlined fieldset {
  border-width: 0 !important;
}

.v-application /deep/ .v-input__slot[role='button'] .v-select__selection,
.v-application /deep/ .vue-tel-input .v-select__selection {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
}

/* =========== alerts ==========*/

.v-application /deep/ .v-alert {
  max-width: 470px;
}

.v-application /deep/ .v-alert .v-alert__content {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
}

/* ========== custom ===========*/

.v-application /deep/ .home-header-2 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
}

.v-application /deep/ .home-header-3 {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
}

.v-application /deep/ .home-header-4 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}

.v-application /deep/ .home-text-1 {
  font-size: 22px;
  font-style: normal;
  font-weight: normal;
}

.v-application /deep/ .home-text-2 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}

.v-application /deep/ .home-input-label {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}

.v-application /deep/ a.header {
  font-weight: normal;
  color: #000;
  font-size: 0.87em;
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.v-application /deep/ a.header:hover {
  text-decoration: underline;
}

.v-application /deep/ a {
  text-decoration: none;
}

.v-application /deep/ a:hover {
  text-decoration: underline;
}

.v-application /deep/ a.header.mobile {
  font-size: 1.125em;
  line-height: 2.5em;
}

.v-application /deep/ abbr {
  text-decoration: none;
  font-style: normal;
}
</style>
