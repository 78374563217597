<template>
    <v-row justify="center">
      <v-col cols="12" class="pt-16">
        <v-container>
          <back-button @click.native="$router.go(-1)"></back-button>
          <v-row>
            <v-col cols="12" lg="6">
              <h3 class="mb-8 home-header-3">
                You should only book a vaccination appointment if you are eligible to receive a vaccination in Northern Ireland
              </h3>
              <v-btn
                @click="onAfterContinueClicked"
                class="v-btn--primary"
              >
                Acknowledge
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import Vue from 'vue';
  import BackButton from '@/components/BackButton';
  import { mapGetters } from 'vuex';
  
  export default Vue.extend({
    components: {
      BackButton
    },
    name: 'StepBookingDisclaimer',
    computed: {
      ...mapGetters(['getFlowName'])
    },
    methods: {
      onAfterContinueClicked() {
        this.$gtag.event(`Booking disclaimer acknowledged` + `${this.getFlowName}`, {})
        this.$router.push('/booking/booking-types-2').catch(() => {});
      }
    }
  });
  </script>
  