// @ts-ignore
import VueJwtDecode from 'vue-jwt-decode'
import config from '@/config'

export default {
  saveToken (token: string) {
    if (!this.verifyToken(token)) {
      throw Error('Invalid or expired token')
    }
    localStorage.setItem(config.patientAuthTokenName, token)
  },
  clearToken () {
    localStorage.setItem(config.patientAuthTokenName, '')
  },
  handleVerifiedToken (token: string) {
    try {
      this.saveToken(token)
    } catch (ex) {
      this.redirectToAuth()
    }
  },
  logout() {
    const token = localStorage.getItem(config.patientAuthTokenName);
    fetch(config.patientAuthLogoutEndpoint, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer '+ token
      }
    })
    .then(() => {
      this.clearToken();
      this.redirectToAuth();
    })
  },
  redirectToAuth (state?: any, username?: any) {
    // throw Error('redirecting')
    return new Promise(() => {
      const codeVerifier = this.generateCodeVerifier()
      localStorage.setItem('pkceCodeVerifier', codeVerifier)
      const stateCodeVerifier = this.generateRandomString(64)
      localStorage.setItem('stateCodeVerifier', stateCodeVerifier)
      // convert state to object if not an object
      if (state && !(state instanceof Object && !(state instanceof Array))) {
        state = {
          value: state
        }
      }
      state = Object.assign(
        {
          stateCodeVerifier: stateCodeVerifier
        },
        state
      )
      
      this.pkceChallengeFromVerifier(codeVerifier).then((codeChallenge) => {
        // @ts-ignore
        window.location =
          config.patientAuthEndpoint +
          '/authorize' +
          '?response_type=code' +
          '&client_id=' +
          encodeURIComponent(config.patientAuthClientId) +
          '&state=' +
          encodeURIComponent(new URLSearchParams(state).toString()) +
          '&scope=' +
          encodeURIComponent(config.patientAuthScope) +
          '&redirect_uri=' +
          encodeURIComponent(config.patientAuthRedirectUrl) +
          '&code_challenge=' +
          encodeURIComponent(codeChallenge) +
          '&code_challenge_method=S256' + 
          (username ? '&username=' + encodeURIComponent(username) : '');
      })
    })
  },
  toUrlEncoded (obj: any) {
    return Object.keys(obj)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
      )
      .join('&')
  },
  exchangeCodeForToken (queryString: { state: string }) {
    return new Promise((resolve, reject) => {
      const state = Object.fromEntries(
        new URLSearchParams(queryString).entries()
      )
      const innerState = Object.fromEntries(
        new URLSearchParams(state.state).entries()
      )
      if (
        !localStorage.getItem('stateCodeVerifier') ||
        localStorage.getItem('stateCodeVerifier') !==
        innerState.stateCodeVerifier
      ) {
        reject()
        this.redirectToAuth()
        return
        // throw Error('stateCodeVerifier do not match')
      }
      const data = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        grant_type: 'authorization_code',
        code: state.code,
        // eslint-disable-next-line @typescript-eslint/camelcase
        client_id: config.patientAuthClientId,
        state: queryString.state,
        // eslint-disable-next-line @typescript-eslint/camelcase
        redirect_uri: config.patientAuthRedirectUrl,
        // eslint-disable-next-line @typescript-eslint/camelcase
        code_verifier: localStorage.getItem('pkceCodeVerifier')
      }
      fetch(config.patientAuthEndpoint + '/access_token', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF8'
        },
        body: this.toUrlEncoded(data)
      })
        .then((response) => response.json())
        .then((json) => {
          resolve({
            authResponse: json,
            state: innerState
          })
        }).catch(error => {
        reject()
        throw Error(error)
      })
    })
  },
  verifySavedToken () {
    return this.verifyToken(localStorage.getItem(config.patientAuthTokenName))
  },
  verifyToken (token: string | null) {
    if (typeof token === 'undefined' || !token) {
      return false
    }

    const jwt = this.getDecodedToken(token)
    if (typeof jwt !== 'object' || !jwt || typeof jwt.exp === 'undefined') {
      return false
    }

    return jwt.exp >= Math.floor(Date.now() / 1000)
  },
  getDecodedToken (token: string) {
    return VueJwtDecode.decode(token)
  },
  getAuthHeader () {
    // return authorization header with jwt token
    const token = localStorage.getItem(config.patientAuthTokenName)

    if (token) {
      return { Authorization: 'Bearer ' + token }
    } else {
      this.redirectToAuth()
    }
  },
  isPublicRoute (route: string) {
    // @ts-ignore
    return ['404', 'PractitionerScheduleHome'].includes(route)
  },
  // ////////////////////////////////////////////////////////////////////
  // PKCE HELPER FUNCTIONS

  // Generate a secure random string using the browser crypto functions
  generateRandomString (length?: number): string {
    const array = new Uint32Array(length || 32)
    // @ts-ignore
    const cr = window.crypto || window.msCrypto;
    cr.getRandomValues(array)
    return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join(
      ''
    )
  },
  generateCodeVerifier (): string {
    return (
      this.generateRandomString()
        // @ts-ignore
        .toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '')
    )
  },
  // Calculate the SHA256 hash of the input text.
  // Returns a promise that resolves to an ArrayBuffer
  sha256 (plain: string): Promise<ArrayBuffer> {
    return new Promise((resolve) => {
      const encoder = new TextEncoder()
      const data = encoder.encode(plain)
      resolve(window.crypto.subtle.digest('SHA-256', data))
    })
  },
  // Base64-urlencodes the input string
  base64urlencode (str: ArrayBuffer): string {
    // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
    // btoa accepts chars only within ascii 0-255 and base64 encodes them.
    // Then convert the base64 encoded to base64url encoded
    //   (replace + with -, replace / with _, trim trailing =)
    // @ts-ignore
    return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '')
  },

  // Return the base64-urlencoded sha256 hash for the PKCE challenge
  pkceChallengeFromVerifier (verifier: string): Promise<string> {
    return new Promise((resolve) => {
      this.sha256(verifier).then((arrayBuffer) => {
        resolve(this.base64urlencode(arrayBuffer))
      })
    })
  }
}
