<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16 max-w">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <v-row>
          <v-col cols="12" lg="8">
            <h3 class="mb-8 home-header-3">
              How would you like to verify your contact details?
            </h3>
            <p class="mb-8 home-text-2">
              We will send appointment details, reminders and your verification code here.
            </p>
            <v-radio-group
              v-model="contactDetails"
              v-on:change="onRadioGroupClicked"
              class="mb-4"
            >
              <v-radio
                v-on:keyup.enter="contactDetails = 'mobilePhone'; onRadioGroupClicked()"
                label="Mobile Phone Number"
                value="mobilePhone"
                color="#006078"
                class="ml-4 px-8 py-4 black--text"
                :ripple="false"
              ></v-radio>
              <v-radio
                v-on:keyup.enter="contactDetails = 'emailAddress'; onRadioGroupClicked()"
                label="Email Address"
                value="emailAddress"
                color="#006078"
                class="ml-4 px-8 py-4 black--text"
                :ripple="false"
              ></v-radio>
            </v-radio-group>
            <v-btn
              @click="onAfterContinueClicked"
              class="v-btn--primary"
              :disabled="!contactDetails"
            >
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import BackButton from '@/components/BackButton';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepContactDetails',
  data: () => ({
    contactDetails: null
  }),
  computed: {
    ...mapGetters(['getFlowName'])
  },
  methods: {
    onRadioGroupClicked() {
      this.$emit('selected', this.contactDetails);
    },
    onAfterContinueClicked() {
      this.$gtag.event(`Contact details filled` + `${this.getFlowName}`, {})
      if (this.contactDetails === 'mobilePhone') {
        this.$router.push('/booking/auth').catch(() => {});
      } else if (this.contactDetails === 'emailAddress') {
        this.$router.push('/booking/auth').catch(() => {});
      }
    }
  }
});
</script>
