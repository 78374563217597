<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <v-alert v-if="showError" color="#CD1041" class="mb-12">
          <div class="px-12 py-10 white--text">
            Please enter correct date of birth.
          </div>
        </v-alert>
        <h3 class="mb-8 home-header-3">
          What is your date of birth?
        </h3>
        <p
          class="mb-8 home-input-label font-weight-normal"
          style="color: rgba(34, 34, 34, 0.5);"
        >
          For example, 27 March 2007
        </p>
        <v-row class="mb-4">
          <v-col cols="12" lg="5">
            <date-of-birth
              v-bind:defaultDateOfBirth="null"
              v-bind:disabled="false"
              @dateChanged="onDateOfBirthChanged"
              @dateEntered="onDateOfBirthEntered"
            ></date-of-birth> </v-col
        ></v-row>
        <v-btn
          @click="onAfterContinueClicked"
          class="v-btn--primary"
          :loading="executeClinicsAvailability"
          :disabled="!dateEntered"
        >
          Continue
        </v-btn>
        <clinics-availability
          v-bind:dateOfBirthAge="dateOfBirthAge"
          v-bind:execute="executeClinicsAvailability"
          @availabilitySuccess="onAvailabilitySuccess"
          @availabilityFailure="onAvailabilityFailure"
          class="mt-8"
        ></clinics-availability>
      </v-container>
    </v-col>
  </v-row>
</template>

<style scoped>
.date-input {
  width: 100%;
}
</style>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import DateOfBirth from '@/components/controls/DateOfBirth';
import ClinicsAvailability from '@/components/ClinicsAvailability';
import { calculateAge, calculateAgeInMonths } from '@/helpers/ageCalculation.ts';

export default Vue.extend({
  components: {
    DateOfBirth,
    ClinicsAvailability
  },
  name: 'StepDateOfBirth',
  data: () => ({
    showError: false,
    dateOfBirth: null,
    dateEntered: false,
    executeClinicsAvailability: false
  }),
  mounted: function() {
    this.$store.commit('setDateOfBirth', null);
    this.$store.commit('setAge', null);
    this.$store.commit('setAgeInMonths', null);
  },
  computed: {
    ...mapGetters([
      'getPreAvailability'
    ]),
    dateOfBirthAge() {
      return calculateAge(this.dateOfBirth);
    },
    dateOfBirthAgeInMonths() {
      return calculateAgeInMonths(this.dateOfBirth);
    }
  },
  methods: {
    saveDateOfBirth() {
      this.$store.commit('setDateOfBirth', this.dateOfBirth);
      this.$store.commit('setAge', this.dateOfBirthAge);
      this.$store.commit('setAgeInMonths', this.dateOfBirthAgeInMonths);
      this.$gtag.event('Date of Birth filled', {})
    },
    goToPath() {
      if (this.dateOfBirthAge >= 5 && !this.getPreAvailability.availabilityForAnyVaccine.length) {
        this.$router.push('/booking/no-availability').catch(() => {});
      } else {
        this.$router.push('/booking/booking-types-groups').catch(() => {});
      }
    },
    onDateOfBirthEntered(value) {
      this.dateEntered = value;
    },
    onDateOfBirthChanged(date) {
      this.dateOfBirth = date;
    },
    onAfterContinueClicked() {
      if (this.dateEntered && !this.dateOfBirth) {
        this.showError = true;
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        return;
      } else if (this.dateEntered && this.dateOfBirth) {
        this.showError = false;
        this.saveDateOfBirth();
        this.executeClinicsAvailability = true;
      }
    },
    onAvailabilitySuccess() {
      this.executeClinicsAvailability = false;
      this.goToPath();
    },
    onAvailabilityFailure() {
      this.executeClinicsAvailability = false;
    }
  }
});
</script>
