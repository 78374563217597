<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <v-row>
          <v-col cols="12" lg="6">
            <h3 class="mb-8 home-header-3">
              Are you a RoI resident currently registered with a NI GP?
            </h3>
            <v-radio-group
              v-model="ifResidentRoI"
              v-on:change="onRadioGroupClicked"
              class="mb-4"
            >
              <v-radio
                v-on:keyup.enter="ifResidentRoI = 'Yes'; onRadioGroupClicked()"
                label="Yes"
                value="Yes"
                color="#006078"
                class="ml-4 px-8 py-4 black--text"
                :ripple="false"
              ></v-radio>
              <v-radio
                v-on:keyup.enter="ifResidentRoI = 'No'; onRadioGroupClicked()"
                label="No"
                value="No"
                color="#006078"
                class="ml-4 px-8 py-4 black--text"
                :ripple="false"
              ></v-radio>
            </v-radio-group>
            <v-btn
              @click="onAfterContinueClicked"
              class="v-btn--primary"
              :disabled="!ifResidentRoI"
            >
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import BackButton from '@/components/BackButton';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepResident',
  data: () => ({
    ifResidentRoI: null
  }),
  methods: {
    onRadioGroupClicked() {
      this.$emit('selected', this.ifResidentRoI);
    },
    onAfterContinueClicked() {
      this.$gtag.event('RoI resident question answered', {})
      this.$router.push('/booking/not-eligible-resident').catch(() => {});
    }
  }
});
</script>