<template>
  <div>
    <booking-clinics v-if="getClinicsUrl && clinicsMapUrl" :standalone="true" :getClinicsUrl="getClinicsUrl" :clinicsMapUrl="clinicsMapUrl"></booking-clinics>
    <vue-recaptcha
      ref="invisibleRecaptcha"
      @render="onAfterRecaptchaRendered"
      @verify="handleRecaptcha"
      @error="showCaptchaError"
      @expired="onExpired"
      size="invisible"
      :sitekey="recaptchaSiteKey"
    >
    </vue-recaptcha>
    <v-alert v-if="alertCaptcha" color="#CD1041" class="mb-12">
      <div class="px-12 py-10 white--text">
        {{ alertCaptchaMessage }}
      </div>
    </v-alert>
  </div>
</template>

<script>

import config from '@/config';
import Vue from 'vue';
import { VueRecaptcha } from 'vue-recaptcha';
import BookingClinics from '@/components/booking/clinics/Clinics';

export default Vue.extend({
  components: { VueRecaptcha, BookingClinics },
  name: 'Sites',
  data: () => ({
    scanId: 17004,
    regionId: 1,
    gettingClinics: true,
    alertCaptcha: false,
    alertCaptchaMessage: '',
    getClinicsUrl: null,
    clinicsMapUrl: null
  }),
  mounted: function() {
  },
  computed: {
    recaptchaSiteKey() {
      return config.keyRecaptchaSite;
    }
  },
  methods: {
    onAfterRecaptchaRendered() {
      this.$refs.invisibleRecaptcha.execute();
      this.gettingClinics = true;
    },
    handleRecaptcha(token) {
      this.recaptchaToken = token;
      this.getClinics();
    },
    resetRecaptcha() {
      this.recaptchaToken = '';
      this.$refs.invisibleRecaptcha.reset();
    },
    onExpired() {
      window.location.reload(true);
    },
    showCaptchaError(message) {
      this.alertCaptchaMessage =
        typeof message !== 'undefined'
          ? message
          : 'Could not get clinics availability. Reload the page and try again.';
      this.alertCaptcha = true;
    },
    getClinics() {
      const url = config.adminApiUrl + '/otp/';
      fetch(url, {
        referrerPolicy: 'no-referrer-when-downgrade',
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        body: JSON.stringify({
          action: 'clinics',
          recaptchaToken: this.recaptchaToken
        })
      })
        .then((response) => response.json())
        .then((response) => {
          this.gettingClinics = false;
          this.resetRecaptcha();
          if (response.status !== 'S') {
            this.showCaptchaError();
            this.getClinicsUrl = null;
            this.clinicsMapUrl = null;
          }
          else {
            this.getClinicsUrl = response.response.getClinicsUrl;
            this.clinicsMapUrl = response.response.clinicsMapUrl;
          }
        })
        .catch((e) => {
          this.gettingClinics = false;
          this.resetRecaptcha();
          this.showCaptchaError();
          this.getClinicsUrl = null;
          this.clinicsMapUrl = null;
        });
    }
  }
});
</script>
