<template>
    <v-row justify="center">
      <v-col cols="12" class="pt-16">
        <v-container>
          <back-button @click.native="$router.go(-1)"></back-button>
          <v-row>
            <v-col cols="12" lg="6">
              <h3 class="mb-8 home-header-3">
                Have you received any letter from your doctor or clinician advising that you require a COVID or Flu vaccination?
              </h3>
              <v-radio-group
                v-model="ifCovidOrFluLetter"
                v-on:change="onRadioGroupClicked"
                class="mb-4"
              >
                <v-radio
                  v-on:keyup.enter="
                    ifCovidOrFluLetter = 'Yes';
                    onRadioGroupClicked();
                  "
                  label="Yes"
                  value="Yes"
                  color="#006078"
                  class="ml-4 px-8 py-4 black--text"
                  :ripple="false"
                ></v-radio>
                <v-radio
                  v-on:keyup.enter="
                    ifCovidOrFluLetter = 'No';
                    onRadioGroupClicked();
                  "
                  label="No"
                  value="No"
                  color="#006078"
                  class="ml-4 px-8 py-4 black--text"
                  :ripple="false"
                ></v-radio>
              </v-radio-group>
              <v-btn
                @click="onAfterContinueClicked"
                class="v-btn--primary"
                :disabled="!ifCovidOrFluLetter"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import Vue from 'vue';
  import { mapGetters } from 'vuex';
  import BackButton from '@/components/BackButton';
  
  export default Vue.extend({
    components: {
      BackButton
    },
    name: 'StepCovid3DoseLetter',
    data: () => ({
      ifCovidOrFluLetter: null
    }),
    computed: {
      ...mapGetters([
      'getAge',
      'getDemographics'
      ])
    },
    mounted: function() {
      this.$store.commit('setIfCovidOrFluLetter', null);
    },
    methods: {
      onRadioGroupClicked() {
        this.$store.commit('setIfCovidOrFluLetter', this.ifCovidOrFluLetter === 'Yes');
      },
      onAfterContinueClicked() {
        this.$gtag.event('Covid or Flu Letter Answered', {})

        if (this.getAge >= 18 && (this.getDemographics.includes('HSC Worker'))) {
          this.$router.push('/booking/trust').catch(() => {});
        }

        else {
          this.$router.push('/booking/booking-disclaimer').catch(() => {});
        }
      }
    }
  });
  </script>
  