import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dateOfBirth: null,
    age: null,
    ageInMonths: null,
    behalfOfChild: null,
    selectedBookingTypesGroup: null,
    preSelectedBookingTypes: [],
    dateWhenLastDoseReceived: null,
    ifCovid: null,
    demographics: [],
    ifCovidOrFluLetter: null,
    ifCovid3DoseLetter: null,
    ifCovidBoosterLetter: null,
    ifCovidDoctorClinicianLetter: null,
    healthAndSocialCareWorker: null,
    frontlineHealthAndSocialCareWorker: null,
    trust: null,
    ifTrustPrimaryEmployer: null,
    jobFamily: null,
    ifResident: null,
    bookingTypes: [],
    ifReceived1Dose: null,
    ifReceived2Dose: null,
    ifReceived3Dose: null,
    preAvailability: null,
    bookingApiURLs: null,
    appointment: null,
    appointmentProcedure: null,
    clinic: null,
    availability: null,
    availabilitySlot: null,
    slot: null,
    invalidSlots: null,
    patient: {
      patientDetails: null,
      patientAddress: null,
      patientMatched: null,
    },
    smileFaceSubmitted: null,
    quickSchedule: {
      appointmentId: null,
      slot: null
    },
    recallFlow: false
  },
  getters: {
    getDateOfBirth: state => state.dateOfBirth,
    getAge: state => state.age,
    getAgeInMonths: state => state.ageInMonths,
    getBehalfOfChild: state => state.behalfOfChild,
    getSelectedBookingTypesGroup: state => state.selectedBookingTypesGroup,
    getPreSelectedBookingTypes: state => state.preSelectedBookingTypes,
    getDateWhenLastDoseReceived: state => state.dateWhenLastDoseReceived,
    getIfCovid: state => state.ifCovid,
    getDemographics: state => state.demographics,
    getIfCovidOrFluLetter: state => state.ifCovidOrFluLetter,
    getIfCovid3DoseLetter: state => state.ifCovid3DoseLetter,
    getIfCovidBoosterLetter: state => state.ifCovidBoosterLetter,
    getIfCovidDoctorClinicianLetter: state => state.ifCovidDoctorClinicianLetter,
    getHealthAndSocialCareWorker: state => state.healthAndSocialCareWorker,
    getFrontlineHealthAndSocialCareWorker: state => state.frontlineHealthAndSocialCareWorker,
    getTrust: state => state.trust,
    getIfTrustPrimaryEmployer: state => state.ifTrustPrimaryEmployer,
    getJobFamily: state => state.jobFamily,
    getIfResident: state => state.ifResident,
    getBookingTypes: state => state.bookingTypes,
    getIfReceived1Dose: state => state.ifReceived1Dose,
    getIfReceived2Dose: state => state.ifReceived2Dose,
    getIfReceived3Dose: state => state.ifReceived3Dose,
    getPreAvailability: state => state.preAvailability,
    getBookingApiURLs: state => state.bookingApiURLs,
    getAppointment: state => state.appointment,
    getAppointmentProcedure: state => state.appointmentProcedure,
    getClinic: state => state.clinic,
    getAvailability: state => state.availability,
    getAvailabilitySlot: state => state.availabilitySlot,
    getSlot: state => state.slot,
    getInvalidSlots: state => state.invalidSlots || [],
    getPatient: state => state.patient,
    getSmileFaceSubmitted: state => state.smileFaceSubmitted,
    getQuickSchedule: state => state.quickSchedule,
    getIfRecallFlow: state => state.recallFlow,
    getFlowName: state => state.recallFlow ? 'RECALL FLOW' : '',  
    getBookingDetailsJSON: state => {
      return JSON.stringify({
        dateOfBirth: state.dateOfBirth,
        age: state.age,
        behalfOfChild: state.behalfOfChild,
        selectedBookingTypesGroup: state.selectedBookingTypesGroup,
        preSelectedBookingTypes: state.preSelectedBookingTypes,
        ifCovid: state.ifCovid,
        demographics: state.demographics,
        ifCovid3DoseLetter: state.ifCovid3DoseLetter,
        ifCovidBoosterLetter: state.ifCovidBoosterLetter,
        ifCovidDoctorClinicianLetter: state.ifCovidDoctorClinicianLetter,
        healthAndSocialCareWorker: state.healthAndSocialCareWorker,
        frontlineHealthAndSocialCareWorker: state
          .frontlineHealthAndSocialCareWorker,
        trust: state.trust,
        ifTrustPrimaryEmployer: state.ifTrustPrimaryEmployer,
        jobFamily: state.jobFamily,
        ifResident: state.ifResident,
        bookingTypes: state.bookingTypes,
        ifReceived1Dose: state.ifReceived1Dose,
        ifReceived2Dose: state.ifReceived2Dose,
        ifReceived3Dose: state.ifReceived3Dose
      });
    },
  },
  mutations: {
    setDateOfBirth: (state, dateOfBirth) => state.dateOfBirth = dateOfBirth,
    setRecallFlow: (state, recallFlow) => state.recallFlow = recallFlow,
    setAge: (state, age) => state.age = age,
    setAgeInMonths: (state, ageInMonths) => state.ageInMonths = ageInMonths,
    setBehalfOfChild: (state, behalfOfChild) => state.behalfOfChild = behalfOfChild,
    setSelectedBookingTypesGroup: (state, selectedBookingTypesGroup) => state.selectedBookingTypesGroup = selectedBookingTypesGroup,
    setPreSelectedBookingTypes: (state, preSelectedBookingTypes) => state.preSelectedBookingTypes = preSelectedBookingTypes,
    setDateWhenLastDoseReceived: (state, dateWhenLastDoseReceived) => state.dateWhenLastDoseReceived = dateWhenLastDoseReceived,
    setIfCovid: (state, ifCovid) => state.ifCovid = ifCovid,
    setDemographics: (state, demographics) => state.demographics = demographics,
    setIfCovidOrFluLetter: (state, ifCovidOrFluLetter) => state.ifCovidOrFluLetter = ifCovidOrFluLetter,
    setIfCovid3DoseLetter: (state, ifCovid3DoseLetter) => state.ifCovid3DoseLetter = ifCovid3DoseLetter,
    setIfCovidBoosterLetter: (state, ifCovidBoosterLetter) => state.ifCovidBoosterLetter = ifCovidBoosterLetter,
    setIfCovidDoctorClinicianLetter: (state, ifCovidDoctorClinicianLetter) => state.ifCovidDoctorClinicianLetter = ifCovidDoctorClinicianLetter,
    setHealthAndSocialCareWorker: (state, healthAndSocialCareWorker) => state.healthAndSocialCareWorker = healthAndSocialCareWorker,
    setFrontlineHealthAndSocialCareWorker: (state, frontlineHealthAndSocialCareWorker) => state.frontlineHealthAndSocialCareWorker = frontlineHealthAndSocialCareWorker,  
    setTrust: (state, trust) => state.trust = trust,
    setIfTrustPrimaryEmployer: (state, ifTrustPrimaryEmployer) => state.ifTrustPrimaryEmployer = ifTrustPrimaryEmployer,
    setJobFamily: (state, jobFamily) => state.jobFamily = jobFamily,
    setIfResident: (state, ifResident) => state.ifResident = ifResident,
    setBookingTypes: (state, bookingTypes) => state.bookingTypes = bookingTypes,
    setIfReceived1Dose: (state, ifReceived1Dose) => state.ifReceived1Dose = ifReceived1Dose,
    setIfReceived2Dose: (state, ifReceived2Dose) => state.ifReceived2Dose = ifReceived2Dose,
    setIfReceived3Dose: (state, ifReceived3Dose) => state.ifReceived3Dose = ifReceived3Dose,
    setPreAvailability: (state, preAvailability) => state.preAvailability = preAvailability,
    setBookingApiURLs: (state, bookingApiURLs) => state.bookingApiURLs = bookingApiURLs,
    setAppointment: (state, appointment) => state.appointment = appointment,
    setAppointmentProcedure: (state, appointmentProcedure) => state.appointmentProcedure = appointmentProcedure,
    setClinic: (state, clinic) => state.clinic = clinic,
    setAvailability: (state, availability) => state.availability = availability,
    setAvailabilitySlot: (state, availabilitySlot) => state.availabilitySlot = availabilitySlot,
    setSlot: (state, slot) => state.slot = slot,
    setInvalidSlots: (state, invalidSlots) => state.invalidSlots = invalidSlots,
    setPatientDetails: (state, patientDetails) => state.patient.patientDetails = patientDetails,
    setPatientAddress: (state, patientAddress) => state.patient.patientAddress = patientAddress,
    setPatientMatched: (state, patientMatched) => state.patient.patientMatched = patientMatched,
    setSmileFaceSubmitted: (state, smileFaceSubmitted) => state.smileFaceSubmitted = smileFaceSubmitted,
    setQuickSchedule: (state, quickSchedule) => state.quickSchedule = quickSchedule
  },
  actions: {
    clearState() {
      this.commit('setDateOfBirth', null);
      this.commit('setAge', null);
      this.commit('setAgeInMonths', null);
      this.commit('setBehalfOfChild', null);
      this.commit('setSelectedBookingTypesGroup', null);
      this.commit('setPreSelectedBookingTypes', []);
      this.commit('setDateWhenLastDoseReceived', null);
      this.commit('setIfCovid', null);
      this.commit('setDemographics', []);
      this.commit('setIfCovid3DoseLetter', null);
      this.commit('setIfCovidBoosterLetter', null);
      this.commit('setHealthAndSocialCareWorker', null);
      this.commit('setFrontlineHealthAndSocialCareWorker', null);
      this.commit('setTrust', null);
      this.commit('setJobFamily', null);
      this.commit('setBookingTypes', null);
      this.commit('setIfReceived1Dose', null);
      this.commit('setIfReceived2Dose', null);
      this.commit('setIfReceived3Dose', null);
      this.commit('setPreAvailability', null);
      this.commit('setBookingApiURLs', null);
      this.commit('setAppointment', null);
      this.commit('setAppointmentProcedure', null);
      this.commit('setClinic', null);
      this.commit('setAvailability', null);
      this.commit('setAvailabilitySlot', null);
      this.commit('setSlot', null);
      this.commit('setInvalidSlots', null);
      this.commit('setPatientDetails', null);
      this.commit('setPatientAddress', null);
      this.commit('setPatientMatched', null);
      this.commit('setSmileFaceSubmitted', null);
      this.commit('setRecallFlow', false)
    },
    clearAppointment() {
      this.commit('setBookingApiURLs', null);
      this.commit('setAppointment', null);
      this.commit('setAppointmentProcedure', null);
      this.commit('setSmileFaceSubmitted', null);
    },
    clearSlots() {
      this.commit('setClinic', null);
      this.commit('setAvailability', null);
      this.commit('setAvailabilitySlot', null);
      this.commit('setSlot', null);
      this.commit('setInvalidSlots', null);
    }
  },
  modules: {
  }
})
