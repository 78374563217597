<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <v-row>
          <v-col cols="12" lg="6">
            <h3 class="mb-8 home-header-3">
              Is a Trust your primary employer?
            </h3>
            <p class="mb-8 home-text-2">
              If you work for a Trust as a locum, agency staff, student or trainee, please select "Yes".
            </p>
            <v-radio-group
              v-model="ifTrustPrimaryEmployer"
              v-on:change="onRadioGroupClicked"
              class="mb-4"
            >
              <v-radio
                v-on:keyup.enter="ifTrustPrimaryEmployer = 'Yes'"
                label="Yes"
                value="Yes"
                color="#006078"
                class="ml-4 px-8 py-4 black--text"
                :ripple="false"
              ></v-radio>
              <v-radio
                v-on:keyup.enter="ifTrustPrimaryEmployer = 'No'"
                label="No"
                value="No"
                color="#006078"
                class="ml-4 px-8 py-4 black--text"
                :ripple="false"
              ></v-radio>
            </v-radio-group>
            <v-btn
              @click="onAfterContinueClicked"
              class="v-btn--primary"
              :disabled="!ifTrustPrimaryEmployer"
            >
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import BackButton from '@/components/BackButton';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepCovid',
  data: () => ({
    ifTrustPrimaryEmployer: null
  }),
  mounted: function() {
    this.$store.commit('setTrust', null);
    this.$store.commit('setTrust', null);
  },
  computed: {
    ...mapGetters(['getFlowName'])
  },
  methods: {
    onRadioGroupClicked() {
      this.$store.commit(
        'setIfTrustPrimaryEmployer',
        this.ifTrustPrimaryEmployer === 'Yes'
      );
    },
    onAfterContinueClicked() {
      if (this.ifTrustPrimaryEmployer === 'Yes') {
        this.$router.push('/booking/trust-selection').catch(() => {});
      }
      else {
        this.$router
          .push('/booking/frontline-social-care-worker')
          .catch(() => {});
      }
      this.$gtag.event(`Trust as primary employer answered` + `${this.getFlowName}`, {})
    }
  }
});
</script>
