<template>
  <div>
    <vue-recaptcha
      ref="invisibleRecaptcha"
      @verify="handleRecaptcha"
      @error="showCaptchaError"
      @expired="onExpired"
      size="invisible"
      :sitekey="recaptchaSiteKey"
    >
    </vue-recaptcha>
    <v-alert v-if="alertCaptcha" color="#CD1041" class="mb-12">
      <div class="px-12 py-10 white--text">
        {{ alertCaptchaMessage }}
      </div>
    </v-alert>
  </div>
</template>

<script>
import config from '@/config';
import Vue from 'vue';
import { VueRecaptcha } from 'vue-recaptcha';

export default Vue.extend({
  components: { VueRecaptcha },
  props: ['dateOfBirthAge', 'execute'],
  name: 'ClinicsAvailability',
  data: () => ({
    checkingAvailability: false,
    alertCaptcha: false,
    alertCaptchaMessage: ''
  }),
  watch: {
    execute(value) {
      if (value) {
        this.$refs.invisibleRecaptcha.execute();
        this.checkingAvailability = true;
      }
    }
  },
  computed: {
    recaptchaSiteKey() {
      return config.keyRecaptchaSite;
    }
  },
  methods: {
    handleRecaptcha(token) {
      this.recaptchaToken = token;
      this.getAvailability();
    },
    resetRecaptcha() {
      this.recaptchaToken = '';
      this.$refs.invisibleRecaptcha.reset();
    },
    onExpired() {
      window.location.reload(true);
    },
    showCaptchaError(message) {
      this.alertCaptchaMessage =
        typeof message !== 'undefined'
          ? message
          : 'Could not get clinics availability. Reload the page and try again.';
      this.alertCaptcha = true;
    },
    getAvailability() {
      const age = this.dateOfBirthAge === 0 ? 1 : this.dateOfBirthAge; //0 age does not make sense
      const url = config.adminApiUrl + '/otp/';
      fetch(url, {
        referrerPolicy: 'no-referrer-when-downgrade',
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        body: JSON.stringify({
          action: 'availability',
          recaptchaToken: this.recaptchaToken,
          age: age
        })
      })
        .then((response) => response.json())
        .then((response) => {
          this.checkingAvailability = false;
          this.resetRecaptcha();
          if (response.status !== 'S') {
            this.showCaptchaError();
            this.$store.commit('setPreAvailability', null);
            this.$emit('availabilityFailure');
          }
          else {
            this.$store.commit('setPreAvailability', response.response);
            this.$emit('availabilitySuccess');
          }
        })
        .catch((e) => {
          this.checkingAvailability = false;
          this.resetRecaptcha();
          this.showCaptchaError();
          this.$store.commit('setPreAvailability', null);
          this.$emit('availabilityFailure');
        });
    }
  }
});
</script>
