<template>
  <v-container fluid>
    <div>
      <vue-recaptcha
        ref="invisibleRecaptcha"
        @render="onAfterRecaptchaRendered"
        @verify="handleRecaptcha"
        @error="showCaptchaError"
        @expired="onExpired"
        size="invisible"
        :sitekey="recaptchaSiteKey"
      >
      </vue-recaptcha>
      <v-alert v-if="alertCaptcha" color="#CD1041" class="mb-12">
        <div class="px-12 py-10 white--text">
          {{ alertCaptchaMessage }}
        </div>
      </v-alert>
    </div>
    <v-container>
      <v-card
        v-if="redirectingToAuth || !captchaVerified || checkingAppointmentEligible || gettingPreferredSlots"
        :loading="true"
        class="mx-auto"
      >
        <template slot="progress">
          <v-progress-linear
            color="#006078"
            indeterminate
          ></v-progress-linear>
        </template>
      </v-card>
  </v-container>
    <div v-if="!redirectingToAuth">
      <v-container>
        <v-row class="ma-0">
          <v-col lg="10"> </v-col>
          <v-col lg="2">
            <div class="text-right">
              <v-btn v-if="accessToken4Patients()" @click="redirectToAuth()">
                Logout
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="captchaVerified && !checkingAppointmentEligible && !appointmentEligible">
        <h3 class="mb-8 home-header-3">
          Thank you for using our vaccination service.
        </h3>
        <h3 class="mb-8 home-text-2">
          This link has expired, please visit
          <a href="https://www.nidirect.gov.uk/articles/get-covid-19-vaccination-northern-ireland" target="_blank" style="color: #007e93">NIDirect</a>
          to check whether you are still eligible for COVID-19 vaccination.
        </h3>
      </v-container>
      <v-container v-else-if="captchaVerified && appointmentIsConfirmed">
        <h3 class="mb-8 home-header-3">
          Thank you for using our vaccination service.
        </h3>
        <h3 class="mb-8 home-text-2">
          It seems that your appointment has already been confirmed.
        </h3>
        <h3 class="mb-8 home-text-2">
          To reschedule or cancel your appointments please visit:
          <a :href="'/portal/' + patientAccessCode" target="_blank" style="color: #007e93">https://vaccinations.covid-19.hscni.net/portal/{{patientAccessCode}}</a>
        </h3>
      </v-container>
      <v-container v-else-if="captchaVerified && appointmentEligible && !accessToken4Patients()">
        <v-row>
          <v-col cols="12" lg="6">
            <h3 class="mb-8 home-header-3">
              Thank you for using our vaccination service.
            </h3>
            <h3 class="mb-8 home-text-2">
              If you wish to be vaccinated at a clinic run by one of the healthcare trusts,
              please continue to book by clicking the "Start now" button below.
            </h3>
            <h3 class="mb-8 home-text-2">
              Alternatively, you may wish to be vaccinated at your local pharmacy.
              To find your nearest pharmacy offering flu vaccinations, please visit
              <a href="https://vaccinations.covid-19.hscni.net/location-search" target="_blank" style="color: #007e93">this site</a>.
            </h3>
			<h3 class="mb-8 home-text-2">
              If you have already received a flu vaccine since the start of the programme on 18th September 2023 you do not need a further vaccine.
            </h3>
            <h3 class="mb-8 home-text-2 font-weight-bold">
              For further information regarding Flu vaccinations please visit the 
              <a href="https://www.nidirect.gov.uk/articles/flu-vaccine" target="_blank" style="color: #007e93">NI Direct website</a>.
            </h3>
            <p class="text-center">
              <v-btn @click="redirectToAuth()" class="v-btn--primary">
                Start now
              </v-btn>
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <div v-if="showSlotsError">
          <h3 class="mb-8 home-header-3">
            Thank you for using our vaccination service.
          </h3>
          <v-alert color="#CD1041" class="mb-14">
            <div
              class="px-12 py-10 white--text"
              v-html="slotsErrorMessage"
            ></div>
          </v-alert>
        </div>
        <v-row
          v-if="accessToken4Patients() && !gettingPreferredSlots && slots.length"
        >
          <v-col cols="12" lg="6">
            <h3 class="mb-8 home-header-3">
              Thank you for using our vaccination service. You are now eligible
              to book your {{ bookingTypeName }} appointment.
            </h3>
            <p class="mb-8 home-text-2">
              We have identified the following locations and appointment times
              for you.
            </p>
            <p class="mb-8 home-text-2">
              Please select one that suits and click "Continue".<br />
              Alternatively, please clock on "Choose another time" and search
              for additional availability.
            </p>
            <div class="home-header-3 mb-8">
              <span style="color: #006078;">Select location and time</span>
            </div>
            <div class="slots-per-clinics">
            <div v-for="(slotsPerClinic, clinicKey) in slotsPerClinics" :key="clinicKey" class="slots-per-clinic">
              <v-row>
                <v-col sm="6" cols="12">
                  <p class="ma-0 mb-0 home-text-2 font-weight-bold">
                    {{ slotsPerClinics[clinicKey][0].clinic_name }}
                  </p>
                  <p class="ma-0 mb-2 home-text-2">
                    {{ slotsPerClinics[clinicKey][0].clinic_address }}
                  </p>
                  <v-btn
                    v-if="!isEmptyOrWhiteSpaces(slotsPerClinics[clinicKey][0].popup_info)"
                    @click.prevent.stop="showClinicInfoDialog(slotsPerClinics[clinicKey][0])"
                    class="pa-2 black--text"
                    x-small
                    color="primary"
                  >
                    View details
                  </v-btn>
                </v-col>
                <v-col sm="6" cols="12">
                  <v-radio-group
                    v-if="slotsPerClinic"
                    v-model="selectedSlot"
                    v-on:change="onRadioGroupClicked"
                    class="ma-0"
                  >
                    <v-radio
                      v-for="slot in slotsPerClinic"
                      :key="
                        '' +
                          slot.clinic_name +
                          '_' +
                          slot.availability_date +
                          '_' +
                          slot.slot_start
                      "
                      :value="slot"
                      v-on:keyup.enter="
                        selectedSlot = slot;
                        onRadioGroupClicked();
                      "
                      color="#006078"
                      class="ml-2 mb-4 py-2 px-8 black--text"
                      :ripple="false"
                    >
                      <template slot="label">
                        <div class="flex-row">
                          <p class="ma-0 font-weight-bold">
                            {{ getSlotTime(slot) }} {{ getSlotAM_PM(slot) }} on
                            {{ getSlotDay(slot) }}, {{ getSlotDate(slot) }}
                          </p>
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <hr class="mb-8" />
            </div>
            </div>
            <v-alert v-if="showScheduleError" color="#CD1041" class="mb-14">
              <div
                class="px-12 py-10 white--text"
                v-html="scheduleErrorMessage"
              ></div>
            </v-alert>
            <div class="text-right">
              <v-btn
                @click="onAfterChooseAnotherTimeClicked"
                :loading="
                  gettingPreferredSlots ||
                    gettingPatient ||
                    schedulingAppointment
                "
                class="mb-8 mb-lg-0 v-btn--primary"
                x-small
              >
                Choose another<br class="d-lg-none">time or location
              </v-btn>
              <v-btn
                @click="onAfterContinueClicked"
                :loading="
                  gettingPreferredSlots ||
                    gettingPatient ||
                    schedulingAppointment
                "
                :disabled="!slotScheduled"
                ref="continueBtn"
                class="mb-8 mb-lg-0 ml-4 v-btn--primary"
              >
                Continue
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-dialog v-model="clinicInfoDialog" max-width="800" max-height="600">
      <v-card class="pa-4">
        <p v-if="clinicInfoName(clinicInfo)">
          {{ clinicInfoName(clinicInfo) }}
        </p>
        <a
          v-if="clinicInfoLink(clinicInfo)"
          :href="clinicInfoLink(clinicInfo)"
          target="_blank"
          >{{ clinicInfoLink(clinicInfo) }}</a
        >
        <p
          v-if="clinicInfoText(clinicInfo)"
          v-html="clinicInfoText(clinicInfo)"
          class="fill-height"
        ></p>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#006078" text @click="clinicInfoDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
  hr {
    height: 1px;
    border: 0;
    border-top: 1px solid #007e93;
  }
  .slots-per-clinics /deep/ .slots-per-clinic:last-of-type hr {
    display: none;
  }
</style>

<script>
import auth4Patients from '@/helpers/auth4Patients';
import config from '@/config';
import moment from 'moment/moment';
import { getSesKey, setSesKey } from '@/helpers/session.ts';
import { VueRecaptcha } from 'vue-recaptcha';

const SLOT_DURATION = 10;

export default {
  name: 'Appointment',
  components: { VueRecaptcha },
  data() {
    return {
      alertCaptcha: false,
      alertCaptchaMessage: '',
      captchaVerified: false,
      scanId: 17004,
      regionId: 1,
      redirectingToAuth: false,
      checkingAppointmentEligible: false,
      gettingPreferredSlots: false,
      gettingPatient: false,
      schedulingAppointment: false,
      appointmentEligible: false,
      appointmentIsConfirmed: false,
      slotScheduled: false,
      slots: [],
      selectedSlot: null,
      patient: null,
      bookingType: null,
      bookingTypeName: null,
      clinicInfo: null,
      clinicInfoDialog: false,
      showSlotsError: false,
      slotsErrorMessage: '',
      showScheduleError: false,
      scheduleErrorMessage: ''
    };
  },
  mounted() {
    this.$emit('hideAppBar');
    this.$store.commit('setQuickSchedule', null);
    this.$store.commit('setPatientDetails', null);
    this.$store.commit('setPatientAddress', null);
    this.$store.commit('setPatientMatched', null);
    localStorage.setItem('accessCode', this.appointmentAccessCode);
    localStorage.setItem('patientAccessCode', this.patientAccessCode);
    this.$store.commit('setRecallFlow', true)
  },
  computed: {
    recaptchaSiteKey() {
      return config.keyRecaptchaSite;
    },
    appointmentAccessCode() {
      return this.$route.params.appointmentAccessCode;
    },
    patientAccessCode() {
      return this.$route.params.patientAccessCode;
    },
    getPreferredSlotsUrl() {
      return (
        config.adminApiUrl + '/otp/' + '?hash=pauqm52nct384vz10r4852swkgnv5esw'
      );
    },
    checkAppointmentEligibleUrl() {
      return (
        config.adminApiUrl + '/otp/' + '?hash=pauqm52nct384vz10r4852swkgnv5esw'
      );
    },
    getPatientUrl() {
      return (
        config.adminApiUrl + '/otp/' + '?hash=pauqm52nct384vz10r4852swkgnv5esw'
      );
    },
    getScheduleAppointmentUrl() {
      return (
        config.adminApiUrl + '/otp/' + '?hash=pauqm52nct384vz10r4852swkgnv5esw'
      );
    },
    slotsPerClinics() {
      const perClinic = this.groupBy(this.slots, 'clinic_name');
      return perClinic;
    }
  },
  methods: {
    onAfterRecaptchaRendered() {
        this.$refs.invisibleRecaptcha.execute();
    },
    async handleRecaptcha(token) {
      this.captchaVerified = true;
      this.recaptchaToken = token;
      
      await this.checkAppointmentEligible();
      
      if (this.appointmentEligible) {
        if (this.accessToken4Patients()) {
          this.getSuggestedSlots();
        }
      }
    },
    resetRecaptcha() {
      this.captchaVerified = false;
      this.recaptchaToken = '';
      this.$refs.invisibleRecaptcha.reset();
    },
    onExpired() {
      window.location.reload(true);
    },
    showCaptchaError(message) {
      this.alertCaptchaMessage =
        typeof message !== 'undefined'
          ? message
          : 'Could not verify your appointment. Reload the page and try again.';
      this.alertCaptcha = true;
    },
    accessToken4Patients() {
      if (!auth4Patients.verifySavedToken()) {
        return false;
      }
      return localStorage.getItem(config.patientAuthTokenName);
    },
    groupBy(xs, key) {
      return xs.reduce(function(rv, x) {
        if (!x) return rv;
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    onRadioGroupClicked() {
      this.scheduleAppointment();
      this.$refs.continueBtn.$el.scrollIntoView({
        behavior: 'smooth'
      });
    },
    async onAfterContinueClicked() {
      this.$gtag.event('Recall flow started', {})
      await this.getPatient();
      this.setPatient();
      this.setClinic();
      this.$store.commit('setSlot', this.selectedSlot);
      this.$store.commit('setQuickSchedule', {
        accessCode: this.appointmentAccessCode,
        slot: this.selectedSlot
      });
      this.$router.push('/booking/patient-summary').catch(() => {});
    },
    async onAfterChooseAnotherTimeClicked() {
      await this.getPatient();
      this.setPatient();
      this.$store.commit('setClinic', null);
      this.$store.commit('setSlot', null);
      this.$store.commit('setQuickSchedule', {
        accessCode: this.appointmentAccessCode,
        slot: null
      });
      this.$router.push('/booking/clinics').catch(() => {});
    },
    setPatient() {
      const dateOfBirth = moment(
        this.patient.dob,
        'YYYY-MM-DD HH:mm:ss'
      ).toDate();
      this.$store.commit('setPatientDetails', {
        firstName: this.patient.name,
        familyName: this.patient.surname,
        dateOfBirth: dateOfBirth,
        sex: this.patient.gender == 'F' ? 'Female' : 'Male',
        ethnicBackground: this.patient.ethnic_background,
        ethnicBackgroundDescription: this.patient.ethnic_background_description,
        healthAndCareNumber: this.patient.health_and_care_number,
        rqiaCode: this.patient.rqia_code,
        staffNumber: this.patient.staff_number
      });
      this.$store.commit('setPatientAddress', {
        address1: this.patient.address_1,
        address2: this.patient.address_2,
        town: this.patient.town,
        postcode: this.patient.post_code,
        mobilePhone: this.patient.mobile_number,
        emailAddress: this.patient.email_address,
        stepsMobilePhone: null,
        stepsEmailAddress: null
      });
    },
    setClinic() {
      this.$store.commit('setClinic', {
        clinic_id: this.selectedSlot.clinic_id,
        clinic_name: this.selectedSlot.clinic_name,
        clinic_address: this.selectedSlot.clinic_address
      });
    },
    setSlot() {},
    getSlotDay(slot) {
      return moment(slot.availability_date, 'YYYY-MM-DD').format('ddd');
    },
    getSlotDate(slot) {
      return moment(slot.availability_date, 'YYYY-MM-DD').format('DD MMM YYYY');
    },
    getSlotTime(slot) {
      const time = moment(slot.slot_start, 'HH:mm:ss');
      return moment(time, 'HH:mm:ss')
        .format('HH:mm')
        .toUpperCase();
    },
    getSlotAM_PM(slot) {
      const time = moment(slot.slot_start, 'HH:mm:ss');
      return moment(time, 'HH:mm:ss')
        .format('a')
        .toUpperCase();
    },
    isEmptyOrWhiteSpaces(str) {
      return !str || str.match(/^ *$/) !== null;
    },
    clinicInfoName(slot) {
      if (!slot) {
        return null;
      }
      return slot.clinic_name;
    },
    clinicInfoLink(slot) {
      if (!slot) {
        return null;
      }
      if (slot.popup_info && slot.popup_info.startsWith('http')) {
        const spaceSplitted = slot.popup_info.split(' ');
        return spaceSplitted[0];
      }
      return null;
    },
    clinicInfoText(slot) {
      if (!slot) {
        return null;
      }
      if (slot.popup_info && slot.popup_info.startsWith('http')) {
        const spaceSplitted = slot.popup_info.split(' ');
        return spaceSplitted[1];
      }
      return slot.popup_info;
    },
    showClinicInfoDialog(slot) {
      this.clinicInfo = slot;
      this.clinicInfoDialog = true;
    },
    redirectToAuth() {
      this.redirectingToAuth = true;
      localStorage.removeItem(config.patientAuthTokenName);
      auth4Patients.redirectToAuth(null, this.patientAccessCode);
    },
    async checkAppointmentEligible() {
      this.appointmentEligible = false;
      this.checkingAppointmentEligible = true;

      try {
        const resultFetch = await fetch(this.checkAppointmentEligibleUrl, {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.accessToken4Patients() ? 'Bearer ' + this.accessToken4Patients() : '',
            'Scope': 'PATIENT',
            'Key': getSesKey()

          },
          body: JSON.stringify({
            action: 'checkAppointmentEligible',
            recaptchaToken: this.recaptchaToken,
            accessCode: this.appointmentAccessCode
          })
        });
        const result = await resultFetch.json();
        this.appointmentEligible = result.response.ifEligible;
      }
      finally {
        this.checkingAppointmentEligible = false;
      }
    },
    async getPatient() {
      this.patient = null;
      this.gettingPatient = true;

      const resultFetch = await fetch(this.getPatientUrl, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.accessToken4Patients() ? 'Bearer ' + this.accessToken4Patients() : '',
          'Scope': 'PATIENT',
          'Key': getSesKey()

        },
        body: JSON.stringify({
          action: 'getPatient',
          accessCode: this.appointmentAccessCode
        })
      });
      const result = await resultFetch.json();

      this.patient = result.response.patient;
      this.gettingPatient = false;
    },
    getSuggestedSlots() {
      this.gettingPreferredSlots = true;
      this.showSlotsError = false;
      this.slotsErrorMessage = '';
      fetch(this.getPreferredSlotsUrl, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.accessToken4Patients() ? 'Bearer ' + this.accessToken4Patients() : '',
          Scope: 'PATIENT'
        },
        body: JSON.stringify({
          action: 'preferredSlots',
          accessCode: this.appointmentAccessCode,
          patientAccessCode: this.patientAccessCode,
          regionId: this.regionId
        })
      })
        .then((response) => {
          if (response.status === 401 || response.status === 403) {
            auth4Patients.clearToken();
            this.$router.go();
          }
          return response.json();
        })
        .then((response) => {
          this.gettingPreferredSlots = false;
          if (response.status === 'S') {
            //safari cross domain
            setSesKey(response.response.key);

            if (response.response.appointmentConfirmed) {
              this.appointmentIsConfirmed = true;
              return;
            }
            this.slots = response.response.availability;
            this.bookingType = response.response.bookingType;
            this.bookingTypeName = response.response.bookingTypeName;
            if (this.slots.length === 0) {
              this.onAfterChooseAnotherTimeClicked();
            }
          } else {
            this.slots = [];
          }
        })
        .catch(() => {
          this.gettingPreferredSlots = false;
          this.showSlotsError = true;
          this.slotsErrorMessage =
            'We are sorry, it looks like we cannot offer you any available appointments at this time.';
        });
    },
    scheduleAppointment() {
      this.schedulingAppointment = true;
      this.slotScheduled = false;
      this.showScheduleError = false;
      this.scheduleErrorMessage = '';
      fetch(this.getScheduleAppointmentUrl, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.accessToken4Patients() ? 'Bearer ' + this.accessToken4Patients() : '',
          'Scope': 'PATIENT',
          'Key': getSesKey()
        },
        body: JSON.stringify({
          action: 'scheduleAppointment',
          accessCode: this.appointmentAccessCode,
          scanId: this.scanId,
          regionId: this.regionId,
          clinicScanRoomId: this.selectedSlot.clinic_scan_room_id,
          slotDate: this.selectedSlot.availability_date,
          slotTime: this.selectedSlot.slot_start,
          slotDuration:
            this.selectedSlot.procedure_interval_generic || SLOT_DURATION
        })
      })
        .then((response) => response.json())
        .then((response) => {
          this.schedulingAppointment = false;
          if (response.status === 'S' && response.response.status) {
            this.onScheduleSuccess();
          } else {
            this.onScheduleError();
          }
        })
        .catch(() => {
          this.schedulingAppointment = false;
          this.onScheduleError();
        });
    },
    onScheduleSuccess() {
      this.slotScheduled = true;
    },
    onScheduleError() {
      this.selectedSlot = null;
      this.showScheduleError = true;
      this.scheduleErrorMessage =
        'We are sorry, but the selected slot is no longer available. The list of available slots will now refresh. Please select an alternative time for your appointment.';
    }
  }
};
</script>

<style scoped></style>
