<template>
  <v-container fluid class="ma-0 pa-0">
    <v-container
      fluid
      class="ma-0 pa-0 mb-16"
      style="background-color: #f2fbfc;"
    >
      <v-row justify="center">
        <v-col class="py-16">
          <v-container>
            <v-row>
              <v-col cols="12" lg="10">
                <p class="ma-0 home-header-2">
                  What to do if you need to change or cancel your vaccination
                  appointment
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" lg="8">
          <div>
            <div class="home-header-2 mb-8">
              How do I cancel my vaccination?
            </div>
            <div class="mb-8 home-text-2">
              <p class="mb-4">
                You will need your date of birth and a mobile number or email
                address so we can confirm your identity.
              </p>
              <p class="mb-4">
                Please note that if you cancel your first vaccination
                appointment, your second appointment will also be cancelled.
                This is because your appointments are made ten weeks apart and
                they are linked. When you are ready, you can use the online
                <a style="color: #007e93;" href="/booking">booking system</a>
                to rebook your vaccination.
              </p>
              <p class="mb-4">
                If you want to cancel your second appointment you will need to
                call the vaccination clinic where you had your first appointment
                to rebook.
              </p>
            </div>
            <v-btn
              class="mb-12 v-btn--primary"
              @click="$router.push('/portal').catch(() => {})"
            >
              Cancel Appointment
            </v-btn>
          </div>
          <div class="home-header-2 mb-8">
            How do I change my vaccination?
          </div>
          <div class="mb-12 home-text-2">
            <p class="mb-4">
              If you want to <b>change</b> the date or time of either of your
              appointments you can ring the vaccination clinic where you booked.
            </p>
            <p class="mb-4">
              Please click <a href="https://covid-19.hscni.net/get-vaccinated/#how-do-i-change-or-cancel-my-vaccination" style="color: #007e93;" target="_blank">here</a> to check your relevant contact details.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <page-footer />
  </v-container>
</template>
<script>
import PageFooter from '@/components/PageFooter';

export default {
  name: 'Info',
  components: {
    PageFooter
  },
  data: () => ({
    src:
      'https://booking-immunisation-hscni.aptvision.com/demo/hscni.html#scanid=17001',
    dialog: false
  }),
  methods: {}
};
</script>
