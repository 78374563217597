<template>
  <v-container fluid class="ma-0 pa-0">
    <v-fade-transition hide-on-leave>
      <v-row ref="htop" align-content="center" align="center" justify="center" class="ma-0 pa-0" style="background-color: #f2fbfc;">
        <div class="ma-0 px-6 py-16 max-w">
          <div class="home-header3">
            The booking system is currently down for maintenance.<br>We will be back soon.
          </div>
        </div>
      </v-row>
    </v-fade-transition>
  </v-container>
</template>
<script>
export default {
  name: 'Maintenance',
  components: {

  },
  data: () => ({
  }),
  methods: {

  }
}
</script>
