<template>
  <v-container class="ma-0 pa-0">
    <p class="mb-6 home-header-4" style="color: #007e93;">
      Booking Details
    </p>
    <p class="mb-6 home-text-2 font-weight-normal" style="color: #007e93;">
      <span class="d-inline-block mb-2">{{ slotDate }}, {{ slotTime }}</span><br>
      <span>{{ getClinic.clinic_name }}</span>
    </p>
    <hr class="mt-12 mb-12">
  </v-container>
</template>

<style scoped>
  hr {
    height: 1px; 
    border: 0; 
    border-top: 1px solid #007e93;
  }
</style>

<script>
import Vue from 'vue';
import moment from 'moment/moment';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: { },
  name: 'BookingDetails',
  data: () => ({
  }),
  mounted: function() {
  },
  computed: {
    ...mapGetters([
      'getClinic',
      'getSlot',
    ]),
    slotDate() {
      return moment(this.getSlot.availability_date).format('D MMMM YYYY');
    },
    slotTime() {
      return moment(this.getSlot.slot_start, 'hh:mm:ss')
        .format('HH:mm a')
        .toUpperCase();
    }
  }
});
</script>
