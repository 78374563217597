<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <v-row>
          <v-col cols="12" lg="6">
            <h3 class="mb-8 home-header-3">
              <label for="job-families-dropdown" class="ma-0 pa-0"
                >Which best describes your job?</label
              >
            </h3>
            <v-row>
              <v-col cols="12" lg="8" class="mt-2 mb-8">
                <v-select
                  v-model="jobFamily"
                  :items="jobFamiliesFiltered"
                  item-text="label"
                  item-value="value"
                  @change="onJobFamilySelected"
                  id="job-families-dropdown"
                  class="mb-6"
                  dense
                  solo
                >
                  <template #item="{item}">
                    <span class="notranslate">{{ item.label }}</span>
                  </template></v-select
                >
                <v-btn
                  @click="onAfterContinueClicked"
                  class="v-btn--primary"
                  :disabled="!jobFamily"
                >
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import BackButton from '@/components/BackButton';
import jobFamilies from '@/data/job-families';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepOrganisation',
  data: () => ({
    jobFamily: '',
    jobFamilies: jobFamilies
  }),
  computed: {
    ...mapGetters([
      'getIfTrustPrimaryEmployer',
      'getFrontlineHealthAndSocialCareWorker',
      'getFlowName'
    ]),
    jobFamiliesFiltered() {
      if (this.getFrontlineHealthAndSocialCareWorker) {
        return this.jobFamilies.filter((j) => j.onlyForFrontline);
      } else {
        return this.jobFamilies.filter((j) => !j.onlyForFrontline);
      }
    }
  },
  methods: {
    saveJobFamily() {
      this.$store.commit('setJobFamily', this.jobFamily);
    },
    onJobFamilySelected() {
      this.saveJobFamily();
    },
    onAfterContinueClicked() {
      this.$gtag.event(`Job description answered` + `${this.getFlowName}`, {})
      if (this.getIfTrustPrimaryEmployer) {
        this.$router.push('/booking/booking-types-2').catch(() => {});
      } else {
        this.$router.push('/booking/booking-disclaimer').catch(() => {});
      }
    }
  }
});
</script>
